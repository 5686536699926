<template>
  <ManagerConfirmation
    v-if="visible"
    :privilege="privilege"
    @confirmed="handleConfirmed"
    @cancel="handleCancel"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ManagerConfirmation from '@/components/core/ManagerConfirmation.vue'
import { init } from './managerConfirmation'

const visible = ref(false)
const privilege = ref('')
const resolveFn = ref<((value: boolean) => void) | null>(null)

function show(privilegeValue: string): Promise<boolean> {
  privilege.value = privilegeValue
  visible.value = true
  return new Promise(resolve => {
    resolveFn.value = resolve
  })
}

function handleConfirmed() {
  if (resolveFn.value) resolveFn.value(true)
  visible.value = false
}

function handleCancel() {
  if (resolveFn.value) resolveFn.value(false)
  visible.value = false
}

init(show)
</script>
