<template>
  <div
    class="l-radio flex items-center text-n-600"
    :class="disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'"
  >
    <div
      class="rounded-full border border-v-300 flex-shrink-0 p-1"
      :class="big ? 'w-5.5 h-5.5' : 'w-4 h-4'"
      @click.stop="toggle"
    >
      <div v-if="model" class="rounded-full bg-v-300 h-full w-full"></div>
    </div>
    <div
      v-if="label"
      class="pl-2 dark:text-n-0"
      :class="big ? 'text-base' : 'text-sm'"
      @click="toggle"
    >
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  label?: string
  big?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  big: false,
  disabled: false
})

const toggle = () => {
  if (!props.disabled) {
    model.value = !model.value
  }
}

const model = defineModel<boolean>()
</script>
