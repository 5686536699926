<template>
  <div v-if="props.paymentMethods">
    <l-option-selector
      v-if="options.length < 4"
      v-model="paymentMethodSelector"
      :options="options"
      @update:model-value="$emit('change', paymentMethodSelector)"
    />
    <l-select
      v-else
      v-model="paymentMethodSelector"
      :options="options"
      :search="false"
      @update:model-value="$emit('change', paymentMethodSelector)"
    />
  </div>
</template>

<script setup lang="ts">
import { LSelect, LOptionSelector } from '@last/core-ui/paprika'
import { PaymentMethod } from '@/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  paymentMethods: PaymentMethod[]
  selectedMethod?: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const sortedPaymentMethods = computed(() =>
  [...props.paymentMethods].sort((a, b) => a.position - b.position)
)

const { t, te } = useI18n()
const paymentMethodSelector = ref<string>(
  props.selectedMethod ?? sortedPaymentMethods.value[0].type
)

if (!props.selectedMethod) {
  paymentMethodSelector.value = sortedPaymentMethods.value[0].type
  emit('change', paymentMethodSelector.value)
}

const options = computed(() => {
  if (!sortedPaymentMethods.value) return []
  return sortedPaymentMethods.value.reduce(
    (acc: Array<{ label: string; value: string }>, conf) => {
      const name = te(`bill.${conf.name}`) ? t(`bill.${conf.name}`) : conf.name
      acc.push({
        label: name,
        value: conf.type
      })

      return acc
    },
    []
  )
})
</script>
