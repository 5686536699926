import i18n from '@/i18n'
import {
  Section,
  Label,
  EmptySeparator,
  MovementsBreakdown
} from './components.js'

function generateShiftMovements(report, name, config) {
  return [
    new Label(
      `${i18n.global.t('movements-report.movements')} ${name}`.toUpperCase(),
      {
        style: 'bold 24px',
        lineHeight: 30
      }
    ),
    new Label(Date.now(), {
      style: '16px',
      lineHeight: 30,
      valueType: 'date'
    }),
    new EmptySeparator(),
    new Label(config.company.name, { style: 'bold 24px', lineHeight: 30 }),
    new Label(config.company.taxId, { style: '16px', lineHeight: 30 }),
    new Label(config.company.address, { style: '16px', lineHeight: 30 }),
    new Section(i18n.global.t('card-movements.movements')),
    new MovementsBreakdown(report.movements)
  ]
}

export default generateShiftMovements
