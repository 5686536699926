<template>
  <div class="h-full flex flex-col mt-4 md:mt-9 min-h-0">
    <div
      id="new-modifier"
      class="grid grid-cols-2 md:grid-cols-6 gap-x-4 gap-y-0 md:gap-4 mb-4"
    >
      <l-field :label="$t('ordering.name')" class="md:col-span-3">
        <l-input
          v-model="openModifier.name"
          size="small"
          :placeholder="$t('ordering.name')"
          :wrong-value="showError"
        />
      </l-field>
      <l-field :label="$t('ordering.price')" class="md:col-span-2">
        <l-input
          v-model.currency="openModifier.priceImpact"
          size="small"
          type="number"
          :min="0"
          :placeholder="$t('ordering.price')"
          :icon="`currencies/${currencyIcon}`"
        ></l-input>
      </l-field>
      <l-button
        size="small"
        :type="breakpoints.md.value ? 'primary' : 'secondary'"
        class="col-span-2 md:col-span-1 md:self-end md:mb-4"
        @click="createModifier()"
      >
        {{ $t('ordering.create') }}
      </l-button>
    </div>
    <div class="h-full scrolling-touch overflow-scroll min-h-0">
      <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
        <div
          v-for="modifier in openModifiers"
          :key="modifier.id"
          class="rounded-small border border-n-600 bg-n-600 flex items-center overflow-hidden gap-2 w-full md:h-16 h-12 px-2"
          @click="$emit('delete', modifier.id)"
        >
          <div class="flex items-center justify-between w-full">
            <div class="text-n-0 font-body text-base">
              {{ modifier.name }}
            </div>
            <div v-if="modifier.priceImpact > 0" class="text-sm text-v-300">
              {{ $filters.currency(modifier.priceImpact) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LField, LInput, LButton } from '@last/core-ui/paprika'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { defineProps, defineEmits, ref } from 'vue'
import { CatalogModifier } from '@/types'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { watch } from 'vue'
import { managerConfirmation } from '@/plugins/managerConfirmation'

const breakpoints = useBreakpoints(breakpointsTailwind)

const { openModifiers } = defineProps<{
  openModifiers: CatalogModifier[]
}>()

const emit = defineEmits(['delete', 'create'])

const openModifier = ref({
  name: null,
  priceImpact: null
})
const showError = ref(false)

const configStore = useConfigStore()
const { currencyIcon } = storeToRefs(configStore)

watch(
  () => openModifier.value.name,
  () => {
    if (showError.value && openModifier.value.name) {
      showError.value = false
    }
  }
)

async function createModifier() {
  showError.value = false
  if (await managerConfirmation('OPEN_PRODUCTS_CREATOR')) {
    const value = openModifier.value
    if (!value.name) {
      showError.value = true
      return
    }
    emit('create', value)
    openModifier.value = { name: null, priceImpact: null }
  }
}
</script>
