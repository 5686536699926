<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar-desktop v-if="!isMobile">
      <template #left>
        <div></div>
      </template>
      <template #center>
        <span class="font-heading font-bold text-xl">
          {{ title }}
        </span>
      </template>
      <template #right>
        <l-button
          size="medium"
          icon="close"
          type="text"
          color="white"
          @click="$emit('close')"
        />
      </template>
    </top-bar-desktop>
    <top-bar-mobile v-else show-close @close="$emit('close')">
      <template #left>
        <span class="font-heading font-bold text-xl">
          {{ title }}
        </span>
      </template>
    </top-bar-mobile>
    <div
      class="overflow-y-scroll scrolling-touch w-full h-full bg-n-800 relative"
    >
      <slot />
    </div>
    <div v-if="$slots.footer" class="p-4 pb-7 flex justify-center">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TopBarDesktop from './TopBar.vue'
import TopBarMobile from '@/mobile/components/TopBar.vue'
import { LButton } from '@last/core-ui/paprika'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

defineProps<{
  title: string
}>()

defineEmits(['close'])

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual('sm')
</script>
