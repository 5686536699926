<template>
  <div class="flex flex-1 overflow-hidden">
    <tab-list
      v-model:selected-tab-id="selectedTabId"
      v-model:view-mode="viewMode"
      :tabs="tabsInfoByType[tabsType]"
      :tabs-type="tabsType"
      @tab-created="tabId => (selectedTabId = tabId)"
      @close-tab="tabId => closeTab(tabId)"
    />
    <div
      v-if="viewMode === 'splitScreen'"
      class="flex-1 h-full px-6 pt-6 hidden sm:block"
    >
      <div
        v-if="selectedTabId && selectedTab"
        class="flex flex-col h-full overflow-hidden"
      >
        <div class="flex justify-between h-full overflow-hidden">
          <div class="flex flex-col w-3/5 h-full">
            <div class="flex-1 p-2 overflow-y-scroll scrolling-touch">
              <preview-tab
                class="preview-tab"
                :tab-id="selectedTabId"
                @edit="editTab"
              />
            </div>
          </div>
          <div class="w-2/5 p-2 h-full overflow-y-scroll scrolling-touch">
            <tab-info :tab="selectedTab" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import PreviewTab from '@/components/tabs/PreviewTab.vue'
import TabInfo from '@/components/tabs/TabInfo.vue'
import TabList from '@/components/tabs/TabList.vue'
import { useTabsStore } from '@/store/tabs'
import { useTabs } from '@/composables/useTabs'
import { type Tab } from '@/types'
import app from '@/app'

interface Props {
  tabsType?: string
  preSelectedTabId?: string
}

const props = withDefaults(defineProps<Props>(), {
  tabsType: 'all',
  preSelectedTabId: ''
})

const router = useRouter()
const route = useRoute()
const tabsStore = useTabsStore()

const { tabs } = storeToRefs(tabsStore)
const tabId = ref<string>()

const preSelectedQuery = computed(() => {
  if (route.query.preSelectedTabId) {
    return (
      Array.isArray(route.query.preSelectedTabId)
        ? route.query.preSelectedTabId[0]
        : route.query.preSelectedTabId
    ) as string
  }

  return ''
})
onMounted(() => {
  if (props.preSelectedTabId) {
    tabId.value = preSelectedQuery.value
  }
})

watch(
  () => route.query,
  () => {
    if (preSelectedQuery.value) {
      router.replace({ query: {} })

      tabId.value = preSelectedQuery.value
    }
  },
  { immediate: true }
)

const selectedTabId = computed({
  get: () => tabId.value,
  set: (selectedId: string) => {
    if (app.isMobile) {
      router.push({
        name: 'orderManagement',
        params: { tabId: selectedId }
      })
    } else {
      tabId.value = selectedId
    }
  }
})
const computedSelectedTabId = computed(() => selectedTabId.value)
const { tab: selectedTab, closeTab } = useTabs(computedSelectedTabId)

const viewMode = ref('splitScreen')

const tabsArray = computed(() => Object.values(tabs.value) as Tab[])

const tabsInfoByType = computed(() => {
  return tabsArray.value.reduce<Record<string, Tab[]>>(
    (res, tab) => {
      if (tab.pickupType === 'takeAway') {
        res.takeAway.push(tab)
      } else if (['delivery', 'ownDelivery'].includes(tab.pickupType)) {
        res.delivery.push(tab)
      }
      res.all.push(tab)
      return res
    },
    { delivery: [], takeAway: [], all: [] } as {
      delivery: Tab[]
      takeAway: Tab[]
      all: Tab[]
    }
  )
})

const editTab = () => {
  router.push({
    name: 'orderManagement',
    params: { tabId: selectedTabId.value }
  })
}
</script>
