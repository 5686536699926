<template>
  <swiper-container
    ref="swiper"
    slides-per-view="auto"
    resistance-ratio="0.7"
    :initial-slide="$slots.left ? 1 : 0"
    class="h-full"
    :allow-slide-prev="!isDisabled"
    :allow-slide-next="!isDisabled"
    :touch-start-prevent-default="false"
  >
    <swiper-slide
      v-if="$slots.left"
      class="w-auto min-w-14 h-auto relative before:absolute before:top-0 before:bottom-0 before:left-1 before:w-[999px] before:-translate-x-full before:bg-g-500"
    >
      <slot name="left"></slot>
    </swiper-slide>
    <swiper-slide class="w-[101%]"><slot name="default"></slot></swiper-slide>
    <swiper-slide
      v-if="$slots.right"
      class="w-auto min-w-14 h-auto relative before:absolute before:top-0 before:bottom-0 before:right-0 before:w-[999px] before:translate-x-full before:bg-r-500"
    >
      <slot name="right"></slot>
    </swiper-slide>
  </swiper-container>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper'
import { ref } from 'vue'

defineProps<{
  isDisabled?: boolean
}>()

const swiper = ref<HTMLElement & { swiper: Swiper }>()

function close() {
  swiper.value?.swiper.slideTo(1)
}

defineExpose({
  close
})
</script>
