<template>
  <CashMachineStatus v-if="visible" v-bind="pluginProps" />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { CashMachineInput, init } from './cashMachineStatus'
import CashMachineStatus from '@/components/CashMachineStatus.vue'

const visible = ref(false)
const pluginProps = ref()

function openCashMachine(props: CashMachineInput) {
  visible.value = true
  pluginProps.value = props

  return {
    close: () => {
      visible.value = false
    }
  }
}

init(openCashMachine)
</script>
