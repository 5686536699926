<template>
  <full-screen :title="$t('sidemenu.about')" @close="$router.go(-1)">
    <div
      class="flex flex-col-reverse sm:flex-col items-center justify-center h-full"
    >
      <div class="flex flex-col items-center">
        <img
          src="../assets/logo.png"
          class="mt-10 sm:mt-0 max-h-10 sm:max-h-20 w-full"
        />
        <div class="text-n-200 mt-3">
          {{ $t('sidemenu.version') }}: {{ appVersion }}
        </div>
      </div>
      <div class="flex flex-col gap-5 justify-center mt-16">
        <div class="flex flex-col items-center">
          <div class="text-n-200 mr-2">{{ $t('sidemenu.software-name') }}</div>
          <div class="text-n-0">LastPOS</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="text-n-200 mr-2">{{ $t('sidemenu.developer') }}</div>
          <div class="text-n-0">Last Systems S.L.</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="text-n-200 mr-2">{{ $t('sidemenu.tax-id') }}</div>
          <div class="text-n-0">B67417758</div>
        </div>
      </div>
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import FullScreen from './core/FullScreen.vue'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

const configStore = useConfigStore()
const { appVersion } = storeToRefs(configStore)
</script>
