<template>
  <div class="w-full flex flex-col gap-2">
    <template v-if="!isFastPOS">
      <div v-if="!tab.open" class="flex gap-2">
        <l-button class="w-full" @click="reopen">
          {{ $t('tabs.reopen') }}
        </l-button>
        <l-button color="yellow" class="w-full" @click="bill">
          {{ $t('tabs.bill') }}
        </l-button>
      </div>
      <l-button
        v-else-if="!tab.activationTime && !editing"
        class="w-full"
        @click="activate"
      >
        {{ $t('tabs.activate') }}
      </l-button>
      <template v-else-if="isDeliveryOrOwnDelivery && editing">
        <l-button v-if="!app.isMobile" class="w-full" @click="done">
          {{ $t('ctas.continue') }}
        </l-button>
      </template>
      <template v-else>
        <div v-if="canClose && !editing" class="flex flex-col gap-2">
          <l-button class="w-full" @click="closeTab">
            {{ closeText }}
          </l-button>
          <div class="flex flex-row gap-2">
            <l-button
              v-if="config.enableKitchenOrders"
              color="red"
              class="w-full"
              @click="order"
            >
              {{ $t('tabs.order') }}
              <div
                v-if="pendingOrders || ordersNotPrinted"
                class="absolute top-0 right-0 border-2 border-n-0 rounded-full notification-circle bg-y-500 w-3 h-3 m-2"
              />
            </l-button>
            <l-button color="yellow" class="w-full" @click="bill">
              {{ $t('tabs.bill') }}
            </l-button>
          </div>
        </div>
        <div
          v-else
          class="grid gap-2"
          :class="[config.enableKitchenOrders ? 'grid-rows-2' : 'grid-cols-2']"
        >
          <l-button
            tracking-id="tabactions:goToCheckout"
            class="w-full"
            :class="{
              invisible: isDeliveryOrOwnDelivery && isMobile
            }"
            @click="goToCheckout"
          >
            {{ checkoutText }}
          </l-button>
          <div v-if="tab.activationTime" class="flex gap-2">
            <l-button
              v-if="config.enableKitchenOrders"
              color="red"
              class="w-full"
              @click="order"
            >
              {{ $t('tabs.order') }}
              <div
                v-if="pendingOrders || ordersNotPrinted"
                class="absolute top-0 right-0 border-2 border-n-0 rounded-full notification-circle bg-y-500 w-3 h-3 m-2"
              />
            </l-button>
            <l-button color="yellow" class="w-full" @click="bill">
              {{ $t('tabs.bill') }}
            </l-button>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="flex flex-col w-full gap-1.5">
        <div class="flex flex-row gap-1.5">
          <l-button class="w-full" @click="useFastPOS().doFastPayment('card')">
            {{ $t('bill.card') }}
          </l-button>
          <l-button class="w-full" @click="useFastPOS().doFastPayment('cash')">
            {{ $t('bill.cash') }}
          </l-button>
        </div>
        <l-button class="w-full" @click="useFastPOS().doCheckout()">
          {{ $t('tabs.checkout') }}
        </l-button>
      </div>
    </template>
    <resend-kitchen-orders
      v-if="showResendKitchenOrders"
      :tab-id="tabId"
      @close="showResendKitchenOrders = false"
    />
    <print-bills
      v-if="showPrintBills"
      :tab-id="tabId"
      @close="showPrintBills = false"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, computed } from 'vue'
import ResendKitchenOrders from '@/components/ordering/ResendKitchenOrders.vue'
import PrintBills from '@/components/ordering/PrintBills.vue'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { LButton } from '@last/core-ui/paprika'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { toRef } from 'vue'
import { useFastPOS } from '@/store/fast-pos'
import app from '@/app'

interface Props {
  tabId: string
  editing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  editing: false
})

const tabsStore = useTabsStore()
const configStore = useConfigStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { config } = storeToRefs(configStore)

const isFastPOS = computed(() => {
  return config.value.featureToggles.fast_pos && route.name === 'fastPOS'
})

const tabId = toRef(props, 'tabId')

const { activateTab, reopenTab } = tabsStore
const {
  tab,
  allBills,
  isFullyPaid,
  hasNonBilledProducts,
  fastCheckoutAllowed,
  printBill,
  closeTab
} = useTabs(tabId)
const { pendingOrders, ordersNotPrinted, sendKitchenOrder } =
  useKitchenOrders(tabId)

const isMobile = computed(() => app.isMobile)

const showResendKitchenOrders = ref(false)
const showPrintBills = ref(false)

const isDeliveryOrOwnDelivery = computed(() => {
  return (
    tab.value.pickupType &&
    ['delivery', 'ownDelivery'].includes(tab.value.pickupType)
  )
})

function goToCheckout() {
  if (isDeliveryOrOwnDelivery.value) {
    router.push({
      name: 'deliveryCheckout',
      params: { tabId: props.tabId }
    })
  } else {
    if (fastCheckoutAllowed.value) {
      router.push({
        name: 'fastCheckout',
        params: { tabId: props.tabId },
        query: { referrer: route.name as string }
      })
    } else {
      router.push({
        name: 'checkout',
        params: { tabId: props.tabId },
        query: { referrer: route.name as string }
      })
    }
  }
}

function printKitchenOrder() {
  sendKitchenOrder('all')
}

function order() {
  if (pendingOrders.value) {
    printKitchenOrder()
    if (route.name !== 'pos') {
      router.push({
        name: 'pos'
      })
    }
  } else {
    showResendKitchenOrders.value = true
  }
}

function done() {
  router.push({
    name: 'deliveryCheckout',
    params: { tabId: props.tabId }
  })
  return
}

function activate() {
  activateTab({ tab: tab.value, sendEvent: true })
}

function reopen() {
  reopenTab({ tabId: props.tabId })
  router.push({
    name: 'orderManagement',
    params: { tabId: props.tabId }
  })
}

function bill() {
  if (allBills.value.length === 0) {
    printBill()
  } else {
    showPrintBills.value = true
  }
}

const closeText = computed(() => {
  let text = t('tabs.close')
  if (tab.value.deliveryOrder?.preferredPaymentMethod) {
    text = `${t('tabs.close-with')} ${tab.value.deliveryOrder.preferredPaymentMethod}`
  }
  return text
})

const checkoutText = computed(() => {
  return isDeliveryOrOwnDelivery.value
    ? t('tabs.delivery-checkout')
    : t('tabs.checkout')
})

const canClose = computed(() => {
  return (
    isFullyPaid.value ||
    (!hasNonBilledProducts.value &&
      tab.value.deliveryOrder?.preferredPaymentMethod)
  )
})
</script>
