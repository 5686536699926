import i18n from '@/i18n'
import {
  InfoItem,
  Section,
  Label,
  EmptySeparator,
  Total,
  TillBreakdown,
  TaxesBreakdown,
  If
} from './components.js'

function payments(report) {
  let sortedPayments = Object.keys(report.payments)
    .map(method => ({
      method,
      amount: report.payments[method]
    }))
    .sort((a, b) => b.amount - a.amount)
  let paymentsArray = []
  for (let i = 0; i < sortedPayments.length; ++i) {
    paymentsArray = [
      ...paymentsArray,
      new InfoItem(
        sortedPayments[i].method.charAt(0).toUpperCase() +
          sortedPayments[i].method.slice(1),
        sortedPayments[i].amount,
        {
          valueType: 'currency'
        }
      )
    ]
  }
  let totalTips = getTotalTips(report)
  let result = [
    new Section(i18n.global.t('report.payments')),
    ...paymentsArray,
    new EmptySeparator(),
    new InfoItem('*' + i18n.global.t('report.tips'), totalTips, {
      valueType: 'currency'
    })
  ]
  return result
}

function firstLabel(isPreview) {
  if (isPreview) {
    return [
      new Label(i18n.global.t('report.preview-report'), {
        style: 'bold 24px',
        lineHeight: 30
      })
    ]
  }
  return [
    new Label(i18n.global.t('report.x-report'), {
      style: 'bold 30px',
      lineHeight: 35
    })
  ]
}

function breakdown(reportBreakdown) {
  return new TaxesBreakdown(
    Object.values(reportBreakdown.taxRates),
    reportBreakdown.taxLabel
  )
}

function generateXReport(report, config, isPreview) {
  return [
    ...firstLabel(isPreview),
    new InfoItem(i18n.global.t('report.from'), report.startDate, {
      valueType: 'date',
      style: '16px'
    }),
    new InfoItem(i18n.global.t('report.to'), report.endDate, {
      valueType: 'date',
      style: '16px'
    }),
    new EmptySeparator(),
    new Label(config.company.name, { style: 'bold 24px', lineHeight: 30 }),
    new Label(config.company.taxId, { style: '16px', lineHeight: 30 }),
    new Label(config.company.address, { style: '16px', lineHeight: 30 }),
    new Section(i18n.global.t('report.general')),
    new InfoItem(i18n.global.t('report.total-tabs'), report.stats.totalTabs),
    new InfoItem(
      i18n.global.t('report.discounts'),
      -report.breakdown.discounts,
      {
        valueType: 'currency'
      }
    ),
    new Section(i18n.global.t('report.till-control')),
    new InfoItem(
      i18n.global.t('report.till-fund'),
      Object.values(report.shifts || []).reduce((total, shift) => {
        total += shift.startAmount
        return total
      }, 0),
      {
        valueType: 'currency'
      }
    ),
    new InfoItem(i18n.global.t('report.pay-in'), report.totalIns, {
      valueType: 'currency'
    }),
    new InfoItem(i18n.global.t('report.pay-out'), -report.totalOuts, {
      valueType: 'currency'
    }),
    new TillBreakdown(report.shifts),
    ...payments(report),
    new EmptySeparator(),
    new If(
      Object.keys(report.breakdown.taxRates)?.length > 0,
      breakdown(report.breakdown)
    ),
    new Total(
      report.breakdown.total,
      null,
      getTotalTips(report) > 0 ? 'with-tips' : null
    )
  ]
}

function getTotalTips(report) {
  return Object.values(report.tips).reduce(
    (total, amount) => (total += amount),
    0
  )
}

export default generateXReport
