<template>
  <div
    class="modal fixed top-0 left-0 h-screen w-screen z-40 sm:px-10 py-10 sm:py-20 overflow-y-scroll scrolling-touch"
    :class="{ 'transparent-background': blurBackground }"
    @click="!disableClickOutside && $emit('close')"
  >
    <div
      :class="{
        'modal--extra-small': size === 'extra-small',
        'modal--small': size === 'small',
        'modal--medium': size === 'medium',
        'modal--large': size === 'large'
      }"
      @click.stop
    >
      <div
        class="text-white uppercase sm:text-2xl text-center pb-3 font-bold relative"
      >
        {{ title }}
        <div class="absolute top-0 right-0 mt-1">
          <icon
            v-if="!hiddenClose"
            name="close"
            class="text-white cursor-pointer"
            @click="$emit('close')"
          />
        </div>
        <div class="absolute top-0 left-0 mt-1">
          <icon
            v-if="showBack"
            name="arrow-left"
            class="text-white cursor-pointer"
            @click="$emit('back')"
          />
        </div>
      </div>
      <section
        class="modal__body p-6 sm:px-10 sm:py-10"
        :class="{ 'overflow-y-scroll': scrolleable }"
      >
        <div v-if="loading" class="m-10 flex justify-center">
          <l-loading-spinner size="medium" theme="dark" />
        </div>
        <slot v-else name="body" />
      </section>
      <section class="mt-4">
        <slot name="footer" />
      </section>
      <footer class="p-12"></footer>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/v3/components/Icon.vue'
import LLoadingSpinner from '@last/core-ui/v3/components/LLoadingSpinner.vue'
export default {
  name: 'LModal',
  emits: ['close', 'back'],
  components: {
    Icon,
    LLoadingSpinner
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    hiddenClose: {
      type: Boolean,
      default: false
    },
    showBack: {
      type: Boolean,
      default: false
    },
    disableClickOutside: {
      type: Boolean,
      default: false
    },
    blurBackground: {
      type: Boolean,
      default: true
    },
    scrolleable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
  backdrop-filter: blur(0.625rem);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__body {
    position: relative;
    background-color: white;
    border-radius: 1.25rem;
  }

  &--extra-small {
    width: 35%;
    min-width: 18.75rem;
    max-width: 37.5rem;
    height: 100%;
  }

  &--small {
    width: 50%;
    min-width: 18.75rem;
    max-width: 37.5rem;
    height: 100%;
  }
  &--medium {
    width: 70%;
    min-width: 18.75rem;
    max-width: 56.25rem;
    height: 100%;
  }
  &--large {
    width: 90%;
    min-width: 18.75rem;
    height: 100%;
  }
}
</style>
