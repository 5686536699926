<template>
  <div
    class="py-1 px-2 rounded-2xl text-xs font-body font-normal"
    :class="{ 'bg-r-300': !isFullyPaid, 'bg-g-500': isFullyPaid }"
  >
    <div v-if="isFullyPaid">{{ $t('checkout.paid') }}</div>
    <div v-else>{{ $t('checkout.to-pay') }}</div>
  </div>
</template>

<script lang="ts" setup>
import { useTabs } from '@/composables/useTabs'
import { computed } from 'vue'

type Props = {
  tabId: string
}

const props = defineProps<Props>()
const tabId = computed(() => props.tabId)

const { isFullyPaid } = useTabs(tabId)
</script>
