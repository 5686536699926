export type TableStates = 'empty' | 'occupied' | 'toPay' | 'reserved'

export type MenuActions =
  | 'printTicket'
  | 'moveAccount'
  | 'mergeTabs'
  | 'closeTable'
  | 'reservations'
  | 'moveReservation'

export type MenuItem = {
  icon: string
  label: string
  action: MenuActions
}

export const menuItems: Record<MenuActions, MenuItem> = {
  ['printTicket']: {
    icon: 'printer',
    label: 'floorplan.print-ticket',
    action: 'printTicket'
  },
  ['moveAccount']: {
    icon: 'in-out',
    label: 'floorplan.move-account',
    action: 'moveAccount'
  },
  ['mergeTabs']: {
    icon: 'integration',
    label: 'floorplan.merge-tabs',
    action: 'mergeTabs'
  },
  ['closeTable']: {
    icon: 'close',
    label: 'floorplan.close-table',
    action: 'closeTable'
  },
  ['reservations']: {
    icon: 'calendar',
    label: 'floorplan.reservations',
    action: 'reservations'
  },
  ['moveReservation']: {
    icon: 'in-out',
    label: 'floorplan.move-reservations',
    action: 'moveReservation'
  }
}

export const mapTableStateToMenuItems: Record<TableStates, MenuItem[]> = {
  ['empty']: [menuItems['reservations'], menuItems['mergeTabs']],
  ['occupied']: [
    menuItems['printTicket'],
    menuItems['moveAccount'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ],
  ['reserved']: [
    menuItems['printTicket'],
    menuItems['moveReservation'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ],
  ['toPay']: [
    menuItems['printTicket'],
    menuItems['moveAccount'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ]
}
