<template>
  <l-select
    v-model="model"
    :options="mappedOptions"
    icon="time"
    icon-position="left"
    :append-to-parent="true"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from 'vue'
import { LSelect } from '@last/core-ui/paprika'

type Props = {
  times?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  times: () => []
})

const model = defineModel<string | null>({
  default: null
})

const optionTimes = computed(() => {
  if (!props.times.length) {
    let times = []
    for (let i = 0; i < 24; i++) {
      times.push(`${i}:00`)
      times.push(`${i}:15`)
      times.push(`${i}:30`)
      times.push(`${i}:45`)
    }
    return times
  }
  return props.times
})

const mappedOptions = computed(() => {
  return optionTimes.value.map(time => ({ label: time, value: time }))
})
</script>
