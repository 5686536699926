<template>
  <div
    v-for="virtualBrand in deliveryCompanies"
    :key="virtualBrand.id"
    class="pb-4"
  >
    <delivery-selector-virtual-brand
      :virtual-brand="virtualBrand"
      :current-time="currentTime"
      @close-delivery="
        (brandId, company) => $emit('closeDelivery', brandId, company)
      "
      @open-delivery="
        (brandId, company) => $emit('openDelivery', brandId, company)
      "
      @close-brand="$emit('closeBrand', $event)"
      @open-brand="$emit('openBrand', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { VirtualBrand } from '@/types/virtualBrands'
import { computed } from 'vue'
import DeliverySelectorVirtualBrand from '@/components/delivery/DeliverySelectorVirtualBrand.vue'

const props = defineProps<{
  virtualBrandDeliveries: VirtualBrand[]
  currentTime: Date
  showClosedUntil: boolean
}>()

defineEmits<{
  (e: 'openDelivery', brandId: string, company: string): void
  (e: 'closeDelivery', brandId: string, company: string): void
  (e: 'openBrand', brandId: string): void
  (e: 'closeBrand', brandId: string): void
}>()

const deliveryCompanies = computed(() => {
  return props.virtualBrandDeliveries.filter(hasDeliveryCompanies)
})

function countDeliveryCompanies(virtualBrand: VirtualBrand) {
  return Object.keys(virtualBrand.externalDeliveryCompaniesClosingTimes).length
}

function hasDeliveryCompanies(virtualBrand: VirtualBrand) {
  return countDeliveryCompanies(virtualBrand) > 0
}
</script>
