<template>
  <full-screen :title="$t('report.real-time-report')" @close="$router.go(-1)">
    <div class="w-full h-auto flex items-center justify-center">
      <div class="w-[33rem] h-full">
        <div class="py-4">
          <report-preview v-if="report" :report="report" @print="print()" />
        </div>
      </div>
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import FullScreen from '@/components/core/FullScreen.vue'
import ReportPreview from './ReportPreview.vue'
import moment from 'moment'
import api from '@/api'
import TicketPrinter from '@/ticketPrinter.js'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const report = ref(null)
const endOfDay = computed(() => config.value.workingTime.end)

const today = computed(() => {
  let endTimeParts = endOfDay.value.split(':')
  let hour = parseInt(endTimeParts[0])
  let minute = parseInt(endTimeParts[1])
  return moment()
    .subtract(hour, 'hours')
    .subtract(minute, 'minutes')
    .format('YYYY-MM-DD')
})

async function refreshReport() {
  let response = await api.get('/reports/z', {
    startDate: today.value,
    endDate: today.value
  })
  report.value = response.data
}

function print() {
  TicketPrinter.printRealTimeReport()
}

refreshReport()
</script>
