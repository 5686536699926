<template>
  <l-modal
    v-if="!previewBill"
    :title="$t('print-bills.title')"
    @close="$emit('close')"
  >
    <div
      v-if="hasPending"
      class="flex flex-col gap-2 bg-n-700 rounded-xl py-6 px-4 mb-4"
    >
      <div class="flex items-center gap-3">
        <div class="text-success">
          {{ $filters.time(pendingBillWithPayment.creationTime) }}
        </div>
        <div class="text-n-0 font-heading font-medium">
          {{ $t('print-bills.pending-bill') }}
        </div>
        <icon
          size="small"
          name="dots"
          class="ml-auto text-v-300 cursor-pointer"
          @click="openDropdown[0] = true"
        />
        <l-dropdown
          v-model:open="openDropdown[0]"
          :options="getOptions(pendingBillWithPayment)"
        />
      </div>
      <div class="text-n-200 font-body text-sm">
        {{ billDescription(pendingBillWithPayment) }}
      </div>
    </div>
    <div
      v-for="(bill, index) in allBills"
      :key="bill.id"
      class="flex flex-col gap-2 bg-n-700 rounded-xl py-6 px-4 mb-4"
    >
      <div class="flex items-center gap-3">
        <div class="text-success">{{ $filters.time(bill.creationTime) }}</div>
        <div class="text-n-0 font-heading font-medium">
          {{ $t('print-bills.bill') + ' ' + (index + 1) }}
        </div>
        <l-dropdown
          v-model:open="openDropdown[index + 1]"
          placement="bottom"
          class="ml-auto text-v-300 cursor-pointer"
          :options="getOptions(bill)"
        >
          <icon
            size="small"
            name="dots"
            @click="openDropdown[index + 1] = true"
          />
        </l-dropdown>
      </div>
      <div class="text-n-200 font-body text-sm">
        {{ billDescription(bill) }}
      </div>
    </div>
  </l-modal>

  <l-modal v-if="previewBill" @close="previewBill = undefined">
    <div class="flex justify-center pb-6">
      <div v-if="previewBill && canvasImage" class="bg-n-0 rounded-lg p-2">
        <img :src="canvasImage" />
      </div>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import TicketPrinter from '@/ticketPrinter.js'
import Bills from '@last/core/src/billsGenerator.js'
import TicketGenerator from '@/tickets/generator.js'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import type { BillWithPayments } from '@/types'
import { LModal, LDropdown, Icon } from '@last/core-ui/paprika'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

const configStore = useConfigStore()

interface Props {
  tabId: string
}

const props = defineProps<Props>()

const { allBills, pendingBill } = useTabs(props.tabId)
const { config } = storeToRefs(configStore)

const previewBill = ref<BillWithPayments>()
const canvasImage = ref<string>()
const openDropdown = ref<boolean[]>([])

const emit = defineEmits(['close'])

const resendBill = (bill: BillWithPayments) => {
  TicketPrinter.printBill(bill)
  emit('close')
}

const billDescription = (bill: BillWithPayments) => {
  let description = bill.products.map(product => product.name).join(', ')
  if (description.length > 40) {
    return description.slice(0, 40) + '...'
  }
  return description
}

const pendingBillWithPayment = computed(() => {
  let bill = pendingBill
  return bill ? Bills.addPaymentInfo(bill, []) : null
})

const hasPending = computed(() => {
  return (
    pendingBillWithPayment.value &&
    pendingBillWithPayment.value.total >
      pendingBillWithPayment.value.deliveryFee +
        pendingBillWithPayment.value.minimumBasketSurcharge
  )
})

function getOptions(bill: BillWithPayments) {
  return [
    {
      label: 'See ticket',
      value: 'barcode',
      onClick: () => {
        previewBill.value = bill
      }
    },
    {
      label: 'Print ticket',
      value: 'print',
      onClick: () => {
        resendBill(bill)
      }
    }
  ] as Option[]
}

watch(previewBill, async () => {
  const bill = previewBill.value
  if (bill) {
    const canvas = await TicketGenerator.bill(bill, {
      barcode: config.value.organizationConfig.barcodes,
      logoImageId: config.value.virtualBrands.find(
        v => v.id === bill.virtualBrandId
      )?.imageId
    })
    canvasImage.value = canvas.toDataURL('image/png')
  }
})
</script>
