<template>
  <div
    class="rounded-xl py-2 overflow-y-scroll min-w-[12rem] max-h-[19rem] max-w-[19rem] text-n-600 bg-n-0 border border-n-100 dark:bg-n-800 dark:text-n-0 dark:border-n-700"
  >
    <ul class="px-4 py-3 text-sm">
      <li
        v-for="item in currentMenuItems"
        :key="item.action"
        class="flex items-center gap-2 p-2 px-4 py-3 rounded-lg text-n-600 dark:text-n-0 hover:bg-n-50 dark:hover:bg-n-700 cursor-pointer"
        @click="executeAction(item.action)"
      >
        <span><icon :name="item.icon" size="small" /></span>
        <span class="whitespace-nowrap">{{ $t(item.label) }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@last/core-ui/paprika'
import {
  mapTableStateToMenuItems,
  type MenuActions,
  MenuItem,
  type TableStates
} from '@/components/tables/table.utils'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { Table } from '@/types'
import { useTabs } from '@/composables/useTabs'
import { useReservationsStore } from '@/store/reservations'

type Props = {
  tableState?: TableStates
  table: Table
}
const props = withDefaults(defineProps<Props>(), {
  tableState: 'empty'
})
const router = useRouter()

const emit = defineEmits(['close', 'mergeTabs', 'assignTable'])

const { getReservation } = useReservationsStore()

const currentMenuItems = computed((): MenuItem[] => {
  return mapTableStateToMenuItems[props.tableState]
})

function executeAction(action: MenuActions): void {
  const reservation = getReservation(props.table.id, new Date())
  const tabs = useTabs(props.table.tabIds[0])
  switch (action) {
    case 'printTicket':
      tabs.printBill()
      break
    case 'moveAccount':
      emit('assignTable', props.table.tabIds)
      break
    case 'mergeTabs':
      emit('mergeTabs', props.table.tabIds[0])
      break
    case 'closeTable':
      tabs.closeTab()
      break
    case 'reservations':
      router.push({
        name: 'reservations',
        query: { initialTableId: props.table.id }
      })
      break
    case 'moveReservation':
      if (!reservation) {
        break
      }
      router.push({
        name: 'reservations',
        query: { editReservation: reservation.id, showSelector: 1 }
      })
      break
  }
  emit('close')
}
</script>
