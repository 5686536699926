import { Preferences } from '@capacitor/preferences'
import app from './app'
import { logger } from '@/monitoring'

function getCapacitorStorage() {
  return {
    setItem: async (key: string, data: unknown): Promise<void> => {
      const value = JSON.stringify(data)
      await Preferences.set({ key, value })
    },
    getItem: async <T = any>(key: string): Promise<T | null> => {
      const { value } = await Preferences.get({ key })
      if (!value) return null
      try {
        return JSON.parse(value) as T
      } catch (e) {
        logger.error(`Failed to parse storage value. ${e}`)
        await Preferences.remove({ key })
        return null
      }
    },
    removeItem: async (key: string): Promise<void> => {
      await Preferences.remove({ key })
    },
    keys: async (): Promise<string[]> => {
      const { keys } = await Preferences.keys()
      return keys
    },
    clear: async (): Promise<void> => {
      await Preferences.clear()
      await Preferences.removeOld()
    }
  }
}

function getElectronStorage() {
  const { ipcRenderer } = window.require('electron')
  return {
    setItem: async (key: string, data: unknown): Promise<void> => {
      const value = JSON.stringify(data)
      await ipcRenderer.invoke('storage:set', key, value)
    },
    getItem: async <T = any>(key: string): Promise<T | null> => {
      const value = await ipcRenderer.invoke('storage:get', key)
      if (!value) return null
      try {
        return JSON.parse(value) as T
      } catch (e) {
        logger.error(`Failed to parse storage value. ${e}`)
        ipcRenderer.invoke('storage:remove', key)
        return null
      }
    },
    removeItem: async (key: string): Promise<void> => {
      await ipcRenderer.invoke('storage:remove', key)
    },
    keys: async (): Promise<string[]> => {
      const { keys } = await ipcRenderer.invoke('storage:keys')
      return keys as string[]
    },
    clear: async (): Promise<void> => {
      await ipcRenderer.invoke('storage:clear')
    }
  }
}

export default app.isElectron ? getElectronStorage() : getCapacitorStorage()
