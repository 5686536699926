<template>
  <div
    v-if="tab"
    id="open-tab"
    class="flex w-full sm:w-[19.688rem] flex-col h-full bg-n-800 sm:bg-n-700 shadow-md py-3 sm:py-4"
  >
    <div v-if="!isFastPOS" class="flex justify-between items-center px-3">
      <ordering-mode-selector :tab-id="tabId" />
      <tab-utils-button :tab-id="tabId" />
    </div>
    <div ref="scroller" class="flex-1 mt-4 overflow-y-scroll scrolling-touch">
      <div
        v-if="isFastPOS || tab.orderingMode === 'seats'"
        v-memo="[selectedSeat, seatProducts, sharedProducts]"
      >
        <l-section-side-menu
          v-memo="[selectedSeat, sharedProducts]"
          :section-name="$t('ordering.shared')"
          :section-selected="selectedSeat === null"
          :elements="sharedProducts"
          @draggable-start="startDraggable"
          @draggable-end="endDraggable"
          @selected-section="$emit('update:selectedSeat', null)"
          @on-add="event => onAdd(null, event)"
          @on-update="event => onUpdate(null, event)"
        >
          <template #default="{ item: product }">
            <tab-product
              :key="product.id"
              :product="product"
              :disable-swipe="disableSwipe"
              @selected="selectProduct(product, null, null)"
            />
          </template>
        </l-section-side-menu>
        <template v-for="(seat, index) in seatProducts" :key="index">
          <l-section-side-menu
            :section-name="`${$t('ordering.seat')} ${index + 1}`"
            :section-selected="selectedSeat === index"
            :elements="seat"
            :show-remove="true"
            @draggable-start="startDraggable"
            @draggable-end="endDraggable"
            @selected-section="$emit('update:selectedSeat', index)"
            @on-add="event => onAdd(index, event)"
            @on-update="event => onUpdate(index, event)"
            @on-remove="onRemove(index)"
          >
            <template #default="{ item: product }">
              <tab-product
                :key="product.id"
                :product="product"
                :disable-swipe="disableSwipe"
                @selected="selectProduct(product, null)"
              />
            </template>
          </l-section-side-menu>
        </template>
        <div
          v-if="!isFastPOS"
          class="flex gap-2 font-body text-xs leading-4 items-center p-3 text-center text-v add-seat cursor-pointer"
          @click="addSeat(tabId)"
        >
          <icon name="plus" />
          <div>{{ $t('ordering.add-seat') }}</div>
        </div>
      </div>
      <div v-else v-memo="[selectedCourse, tabCourses]">
        <template v-for="(course, index) in tabCourses" :key="index">
          <l-section-side-menu
            :section-name="course.name"
            :section-selected="
              isFastPOS ? false : selectedCourse === course.name
            "
            :elements="course.products"
            :show-select-radio="!isFastPOS"
            :hide-section-name="isFastPOS"
            @draggable-start="startDraggable"
            @selected-section="updateSelectedCourse(course.name)"
            @draggable-end="endDraggable"
            @on-add="event => onAddCourse(course.name, event)"
            @on-update="event => onUpdateCourse(course.name, event)"
          >
            <template #default="{ item: product }">
              <tab-product
                :key="getProductId(product)"
                :product="{
                  ...product,
                  comboProducts: product.shownCombo
                    ? [product.shownCombo]
                    : product.comboProducts
                }"
                :disable-swipe="disableSwipe"
                @selected="selectProduct(product, null, course.name)"
              />
            </template>
          </l-section-side-menu>
        </template>
        <div class="h-16" />
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <balance :tab-id="tabId" size="small" class="z-10" />
      <tab-actions :tab-id="tabId" editing small class="px-3" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import LSectionSideMenu from '@/components/core/LSectionSideMenu.vue'
import Balance from '@/components/core/Balance.vue'
import { Icon } from '@last/core-ui/paprika'
import OrderingModeSelector from './OrderingModeSelector.vue'
import TabUtilsButton from './TabUtilsButton.vue'
import TabActions from '@/components/tabs/TabActions.vue'
import TabProduct from '@/components/ordering/TabProduct.vue'
import { useTabsStore } from '@/store/tabs'
import { useTabs } from '@/composables/useTabs'
import type { Product } from '@/types'
import { defineProps, defineEmits, ref, computed, nextTick } from 'vue'
import { SortableEvent } from 'vue-draggable-plus'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useConfigStore } from '@/store/config'

const props = defineProps<{
  tabId: string
  selectedSeat: number | null
  selectedCourse: string
  selectedProductId?: string
}>()

const emit = defineEmits([
  'update:selectedSeat',
  'update:selectedCourse',
  'selectedProduct',
  'selectedComboProduct'
])
const { config } = storeToRefs(useConfigStore())

const tabsStore = useTabsStore()
const tabId = computed(() => props.tabId)

const { seatProducts, sharedProducts, courseProducts, tab } = useTabs(tabId)

const { moveProduct, removeSeat, updateCourse } = tabsStore
const { addSeat } = tabsStore

const route = useRoute()
const isFastPOS = computed(() => {
  return config.value.featureToggles.fast_pos && route.name === 'fastPOS'
})

const scroller = ref<HTMLElement>()
const scrollPosition = ref(0)
const disableSwipe = ref(false)

const tabCourses = computed(() => courseProducts.value)

function updateSelectedCourse(course: string) {
  emit('update:selectedCourse', course === props.selectedCourse ? null : course)
}

function onRemove(index: number) {
  removeSeat({ tabId: props.tabId, selectedSeatIndex: index })
  emit('update:selectedSeat', null)
}

function selectProduct(
  product: Product,
  seat: number | null,
  course: string | null
) {
  if (product.comboProducts) {
    if (course) product.shownCombo.course = course
    emit('selectedComboProduct', {
      product,
      comboProduct: product.shownCombo
    })
  } else {
    emit('selectedProduct', product)
  }
  if (seat) emit('update:selectedSeat', seat)
  if (course) emit('update:selectedCourse', course)
}

function onUpdate(seat: number | null, event: SortableEvent) {
  let productId = event.item.id
  let position = event.newIndex
  moveProduct({ tabId: props.tabId, seat, position, productId })
}

function onAdd(seat: number | null, event: SortableEvent) {
  let productId = event.item.id
  let position = event.newIndex
  moveProduct({ tabId: props.tabId, seat, position, productId })
}

function onUpdateCourse(course: string, event: SortableEvent) {
  const comboId = event.item.getAttribute('combo-id')
  const productId = comboId || event.item.id
  updateCourse({
    course,
    productId: productId,
    comboProductId: comboId ? event.item.id : null
  })
}

function onAddCourse(course: string, event: SortableEvent) {
  const comboId = event.item.getAttribute('combo-id')
  const productId = comboId || event.item.id
  updateCourse({
    course,
    productId: productId,
    comboProductId: comboId ? event.item.id : null
  })
}

function getProductId(product: Product) {
  return product.comboProducts ? product.shownCombo.id : product.id
}

function startDraggable() {
  disableSwipe.value = true
}

async function endDraggable() {
  disableSwipe.value = false
  scrollPosition.value = scroller.value?.scrollTop || 0
  await nextTick()
  scroller.value!.scrollTop = scrollPosition.value
}
</script>
