<template>
  <div v-if="hasDeliveryCompanies" class="bg-n-700 rounded-[0.625rem]">
    <div class="flex flex-row items-center px-6 py-5">
      <div class="flex-none pr-3">
        <template v-if="virtualBrandLogo">
          <img :src="virtualBrandLogo" />
        </template>

        <div
          v-else
          class="flex items-center justify-center w-10 h-10 rounded-full bg-n-10 font-normal text-base text-n-800"
        >
          {{ brandInitials }}
        </div>
      </div>
      <div class="flex-1 text-n-0">
        <div class="text-base">{{ virtualBrand.brandName }}</div>
        <div v-if="hasOneDeliveryCompany" class="text-xs">
          {{ firstCompanyName }}
        </div>
      </div>
      <div class="flex-none">
        <l-switch v-model="switchValue" :enable-on-partial-state="true" />
      </div>
    </div>
    <template v-if="!hasOneDeliveryCompany && hasDeliveryCompanies">
      <div class="border-t border-n-600 mx-4 pb-4" />
      <delivery-selector-companies
        :virtual-brand="virtualBrand"
        :current-time="currentTime"
        class="px-4 pb-4"
        @close-delivery="
          (brandId, company) => $emit('closeDelivery', brandId, company)
        "
        @open-delivery="
          (brandId, company) => $emit('openDelivery', brandId, company)
        "
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { LSwitch } from '@last/core-ui/paprika'
import { computed } from 'vue'
import { VirtualBrand } from '@/types/virtualBrands'
import moment from 'moment/moment'
import DeliverySelectorCompanies from '@/components/delivery/DeliverySelectorCompanies.vue'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

type Props = { virtualBrand: VirtualBrand; currentTime: Date }

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'openDelivery', brandId: string, company: string): void
  (e: 'closeDelivery', brandId: string, company: string): void
  (e: 'openBrand', brandId: string): void
  (e: 'closeBrand', brandId: string): void
}>()

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

type SwitchModelType = boolean | 0 | 1 | 2
const switchValue = computed<SwitchModelType>({
  get: (): SwitchModelType => {
    if (brandIsPartiallyEnabled.value) return 2

    return brandIsEnabled.value
  },
  set: (value: SwitchModelType) => {
    if (!value) {
      emit('closeBrand', props.virtualBrand.id)
    } else {
      emit('openBrand', props.virtualBrand.id)
    }
  }
})

const virtualBrandLogo = computed(() => {
  const foundBrand = config.value.virtualBrands.find(vb => {
    return vb.id === props.virtualBrand.id
  })
  if (!foundBrand || !foundBrand.coverImageId) return

  return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto,w_40,h_40/${foundBrand.coverImageId}`
})

const deliveryCompaniesCount = computed(() => {
  return Object.keys(props.virtualBrand.externalDeliveryCompaniesClosingTimes)
    .length
})

const brandIsPartiallyEnabled = computed(() => {
  const opened = (
    Object.values(
      props.virtualBrand.externalDeliveryCompaniesClosingTimes
    ).filter(company => company) || []
  ).length

  return opened > 0 && opened < deliveryCompaniesCount.value
})

const hasOneDeliveryCompany = computed(() => {
  return deliveryCompaniesCount.value === 1
})

const hasDeliveryCompanies = computed(() => {
  return deliveryCompaniesCount.value > 0
})

const firstCompanyName = computed(() => {
  return Object.keys(
    props.virtualBrand.externalDeliveryCompaniesClosingTimes
  )[0]
})

const brandInitials = computed(() => {
  return props.virtualBrand.brandName
    .split(' ')
    .slice(0, 3)
    .map(word => word[0])
    .join('')
    .toUpperCase()
})

const brandIsEnabled = computed((): boolean => {
  return Object.values(
    props.virtualBrand.externalDeliveryCompaniesClosingTimes
  ).some(time => !time || moment(time).isBefore(moment(props.currentTime)))
})
</script>
