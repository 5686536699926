<template>
  <div>
    <div
      v-for="bill in allBills"
      :key="bill.id"
      class="flex flex-col justify-between bg-n-700 rounded-[10px] py-6 px-4 my-4 cursor-pointer"
    >
      <div class="flex flex-row justify-between items-center flex-1">
        <div class="font-heading text-base font-medium">
          <span class="text-success font-body font-normal mr-2">{{
            $filters.time(bill.creationTime)
          }}</span>
          <span v-if="bill.customerCompany">{{
            $t('checkout.invoice') + ' '
          }}</span>
          <span v-else>{{ $t('checkout.bill') + ' ' }}</span>
          <span>{{ bill.number }}</span>
        </div>
        <div
          ref="dropdown"
          class="text-action pl-6 items-center flex"
          @click.stop="toggleDropdown(bill.id)"
        >
          <div class="pr-44 pt-2 relative">
            <l-dropdown
              :open="openDropdown === bill.id"
              :options="dropdownOptions[bill.id]"
              :model-value="[]"
              @update:model-value="option => selectOption(option, bill)"
            />
          </div>
          <icon name="dots" size="small" />
        </div>
      </div>
      <div class="flex flex-row items-center justify-between flex-1 mt-2">
        <span class="font-body font-normal text-n-200">{{
          productsLine[bill.id]
        }}</span>
        <span class="font-heading font-medium">{{
          $filters.currency(bill.total)
        }}</span>
      </div>
    </div>
    <div
      v-if="canvasImage"
      class="fixed top-0 left-0 h-screen w-screen z-20 blur-background flex justify-center items-center"
      @click="previewBill = null"
    >
      <div class="bg-n-0 rounded-big shadow p-6 relative" @click.stop>
        <img :src="canvasImage" style="width: 320px" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed, watch } from 'vue'
import { useTabs } from '@/composables/useTabs'
import { Icon, LDropdown } from '@last/core-ui/paprika'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import TicketGenerator from '@/tickets/generator.js'
import { useConfigStore } from '@/store/config'
import TicketPrinter from '@/ticketPrinter.js'
import { storeToRefs } from 'pinia'
import { Bill } from '@/types'

const { t } = useI18n()

const props = defineProps<{ tabId: string }>()

const emit = defineEmits(['selected'])

const { allBills } = useTabs(props.tabId)

const productsLine = computed(() => {
  return allBills.value.reduce(
    (acc, bill) => {
      let line = bill.products.map(({ name }) => name).join(', ')
      if (line.length > 40) {
        line = line.slice(0, 40) + '...'
      }
      acc[bill.id] = line
      return acc
    },
    {} as Record<string, string>
  )
})

const selectBill = (bill: Bill) => {
  emit('selected', bill.id)
}

const openDropdown = ref<string | null>(null)

const dropdownRef = ref<HTMLElement | null>(null)
onClickOutside(dropdownRef, () => {
  openDropdown.value = null
})

const dropdownOptions = computed(() => {
  const options = [
    { value: 'view', label: t('checkout.view'), icon: 'eye' },
    { value: 'print', label: t('checkout.print'), icon: 'printer' }
  ]
  return allBills.value.reduce(
    (acc, bill) => {
      acc[bill.id] = [...options]
      if (bill.customerCompany) {
        acc[bill.id].push({
          value: 'send',
          label: t('checkout.send-invoice'),
          icon: 'mail'
        })
      } else {
        acc[bill.id].push({
          value: 'invoice',
          label: t('checkout.generate-invoice'),
          icon: 'ticket'
        })
      }
      return acc
    },
    {} as Record<string, typeof options>
  )
})

function selectOption([option]: string[], bill: Bill) {
  if (option === 'invoice' || option === 'send') {
    selectBill(bill)
  } else if (option === 'view') {
    previewBill.value = bill
  } else if (option === 'print') {
    TicketPrinter.printBill(bill)
  }

  openDropdown.value = null
}

function toggleDropdown(billId: string) {
  if (openDropdown.value === billId) {
    openDropdown.value = null
  } else {
    openDropdown.value = billId
  }
}

const previewBill = ref<Bill | null>(null)
const canvasImage = ref<string | null>(null)

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

watch(previewBill, async () => {
  const bill = previewBill.value
  if (bill) {
    const canvas = await TicketGenerator.bill(bill, {
      barcode: config.value.organizationConfig.barcodes,
      logoImageId: config.value.virtualBrands.find(
        v => v.id === bill.virtualBrandId
      )?.imageId
    })
    canvasImage.value = canvas.toDataURL('image/png')
  }
})
</script>
