import type { ObjectDirective } from 'vue'

export const horizontalScrollDirective: ObjectDirective<HTMLDivElement, never> =
  {
    mounted(el) {
      el.addEventListener(
        'wheel',
        e => {
          if (e.deltaY > 0) el.scrollLeft += 80
          else el.scrollLeft -= 80
        },
        { passive: true }
      )
    }
  }
