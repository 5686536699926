<template>
  <div class="options-container">
    <div
      v-for="option in options"
      :key="value(option)"
      class="option"
      :class="{ selected: isSelected(option) }"
      @click="select(option)"
    >
      {{ label(option) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionSelector',
  props: ['selected', 'options'],
  methods: {
    isSelected(option) {
      if (Array.isArray(this.selected)) {
        return this.selected.includes(this.value(option))
      }
      return this.selected === this.value(option)
    },
    select(option) {
      if (Array.isArray(this.selected)) {
        if (this.selected.includes(this.value(option))) {
          this.$emit(
            'update:selected',
            this.selected.filter(o => o != this.value(option))
          )
        } else {
          this.$emit('update:selected', [...this.selected, this.value(option)])
        }
      } else {
        this.$emit('update:selected', this.value(option))
      }
    },
    label(option) {
      return option.label || option
    },
    value(option) {
      return option.value || option
    }
  }
}
</script>

<style scoped>
.options-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  justify-content: center;
}

.option {
  border-radius: 8px;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  width: 79px;
  line-height: 53px;
  text-align: center;
  margin: 4.5px;
}

.option.selected {
  background: #00b7ff;
  color: white;
}
</style>
