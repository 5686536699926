import { notification } from '@last/core-ui/paprika/plugins/notifications/notification'
import { computed } from 'vue'
import i18n from '@/i18n'
import { router } from '@/router'
import { NotificationConstructor } from '@last/core-ui/paprika/plugins/notifications/types.ts'

export type POSNotification = NotificationConstructor

export const useNotifications = () => {
  const { t } = i18n.global

  const notify = notification.create

  const notificationsAmount = computed(
    () => notification.fixedNotifications.length
  )

  function openNotificationsSideBar() {
    notification.openFixedComponent()
  }

  function closeNotificationsSideBar(): void {
    notification.closeFixedComponent()
  }

  function notifySuccess(constructor: Omit<POSNotification, 'type'>) {
    notify({
      ...constructor,
      type: 'success'
    } as POSNotification)
  }

  function notifyError(constructor: Omit<POSNotification, 'type'>) {
    notify({
      ...constructor,
      type: 'error'
    } as POSNotification)
  }

  function notifyInfo(constructor: Omit<POSNotification, 'type'>) {
    notify({
      ...constructor,
      type: 'info'
    } as POSNotification)
  }

  function notifyTabClosed(tabId: string) {
    notifyInfo({
      title: t('notifications.tab-closed'),
      onAction: () => {
        router.push({
          name: 'pos',
          query: { preSelectedTabId: tabId }
        })
      }
    })
  }

  function notifyPaymentFailed(message?: string) {
    const errorMessage = message
      ? `${t('notifications.payment-failed')}: ${message}`
      : t('notifications.payment-failed')

    notifyError({
      title: errorMessage
    })
  }

  return {
    notificationsAmount,
    openNotificationsSideBar,
    closeNotificationsSideBar,
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyTabClosed,
    notifyPaymentFailed
  }
}
