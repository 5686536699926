<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive include="Home">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div id="appRoot" class="z-50 relative" />
    <l-dialog-wrapper />
    <l-notification-wrapper />
    <manager-confirmation-wrapper />
    <cash-machine-wrapper />
    <update-modal-wrapper />
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { LDialogWrapper, LNotificationWrapper } from '@last/core-ui/paprika'
import ManagerConfirmationWrapper from '@/plugins/ManagerConfirmationWrapper.vue'
import CashMachineWrapper from '@/plugins/CashMachineWrapper.vue'
import UpdateModalWrapper from '@/plugins/UpdateVersionWrapper.vue'
import { useStatusStore } from '@/store/status'

const router = useRouter()
const authStore = useAuthStore()
const statusStore = useStatusStore()
const { trialExpired, gracePeriodExpired } = storeToRefs(authStore)
const { hasTimeError } = storeToRefs(statusStore)

watch(trialExpired, () => {
  if (trialExpired) {
    router.push({ name: 'freeTrialExpired' })
  } else {
    router.push({ name: 'employees' })
  }
})

watch(gracePeriodExpired, () => {
  if (gracePeriodExpired) {
    router.push({ name: 'gracePeriodExpired' })
  } else {
    router.push({ name: 'employees' })
  }
})

watch(hasTimeError, () => {
  if (hasTimeError.value) {
    router.push({ name: 'wrongTime' })
  } else {
    router.push({ name: 'employees' })
  }
})
</script>
