import posthog from 'posthog-js'
import type { UserData, Tracker } from './tracker'

export class PosthogTracker implements Tracker {
  constructor(apiKey: string) {
    posthog.init(apiKey, {
      api_host: 'https://us.i.posthog.com',
      autocapture: false,
      capture_pageview: false,
      disable_session_recording: true
    })
  }

  track(eventName: string, eventProperties: Record<string, any>) {
    posthog.capture(eventName, eventProperties)
  }

  identify(userData: UserData) {
    posthog.identify(userData.id, { name: userData.name })
  }
}
