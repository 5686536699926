<template>
  <div v-if="!!tab">
    <l-select
      v-if="tab.orderingMode === 'seats'"
      :placeholder="$t('tabs.seats')"
      :options="allSeats"
      :model-value="selectedSeat"
      :search="false"
      @update:model-value="emitSelectedSeat"
    />
    <l-select
      v-else
      :placeholder="$t('tabs.courses')"
      :options="allCourses"
      :model-value="selectedCourse"
      :search="false"
      @update:model-value="value => emit('update:selectedCourse', value)"
    />
  </div>
</template>
<script setup lang="ts">
import { LSelect } from '@last/core-ui/paprika'
import { useTabs } from '@/composables/useTabs'
import { useCatalogStore } from '@/store/catalog'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { computed, defineModel } from 'vue'
import type { Option } from '@last/core-ui/paprika/components/dropdown/types'

type Props = {
  tabId: string
}

const emit = defineEmits(['update:selectedSeat', 'update:selectedCourse'])

const { t } = useI18n()

const selectedSeat = defineModel<number | null>('selectedSeat')
const selectedCourse = defineModel<string>('selectedCourse')

const props = defineProps<Props>()
const catalogStore = useCatalogStore()

const { allCourses } = storeToRefs(catalogStore)
const { tab, seatProducts } = useTabs(props.tabId)

const allSeats = computed<Option[]>(() => {
  return [
    {
      label: t('ordering.shared'),
      value: ''
    },
    ...seatProducts.value.map((_, index) => {
      return {
        label: `${t('ordering.seat')} ${index + 1}`,
        value: index.toString()
      }
    })
  ]
})

function emitSelectedSeat(value: string) {
  if (value === '') {
    emit('update:selectedSeat', null)
    return
  }
  emit('update:selectedSeat', value)
}
</script>
