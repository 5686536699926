import i18n from '@/i18n'
import {
  InfoItem,
  Section,
  EmptySeparator,
  TillBreakdown
} from './components.js'

function formatAmounts(amounts) {
  return amounts
    .map(amount => {
      return {
        method: amount.method,
        expectedEndAmount: amount.expectedEnd,
        endAmount: amount.end || 0
      }
    })
    .reduce((res, amount) => {
      res[amount.method] = amount
      return res
    }, {})
}

function generateTillReport(report, tillName) {
  return [
    new Section(
      `${i18n.global.t('till-report.title')} ${
        tillName || report.tillName
      }`.toUpperCase()
    ),
    new InfoItem(i18n.global.t('till-report.start-date'), report.start, {
      valueType: 'date',
      style: '16px'
    }),
    new InfoItem(i18n.global.t('till-report.end-date'), report.end, {
      valueType: 'date',
      style: '16px'
    }),
    new EmptySeparator(),
    new Section(i18n.global.t('report.till-control')),
    new InfoItem(i18n.global.t('report.till-fund'), report.startAmount || 0, {
      valueType: 'currency'
    }),
    new InfoItem(i18n.global.t('report.pay-in'), report.payIns, {
      valueType: 'currency'
    }),
    new InfoItem(i18n.global.t('report.pay-out'), -report.payOuts, {
      valueType: 'currency'
    }),
    new TillBreakdown(formatAmounts(report.amounts))
  ]
}

export default generateTillReport
