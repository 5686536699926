export const CalculatorKeypadMode = {
  FULL: 'full',
  SIMPLE: 'simple',
  NORMAL: 'normal',
  DOUBLE_ZERO: 'doubleZero',
  QUANTITY: 'quantity'
} as const

export type CalculatorKeypadModeType =
  (typeof CalculatorKeypadMode)[keyof typeof CalculatorKeypadMode]

export const CalculatorKeypadActions = {
  CLEAR: 'clear',
  BACKSPACE: 'backspace',
  DIVIDE: 'divide',
  DIVIDE2: 'divide2',
  DIVIDE3: 'divide3',
  MULTIPLY: 'multiply',
  SUBTRACT: 'subtract',
  ADD: 'add',
  EQUALS: 'equals',
  DOUBLE_ZERO: 'doubleZero'
} as const

export type CalculatorKeypadActionsType =
  (typeof CalculatorKeypadActions)[keyof typeof CalculatorKeypadActions]

export const CalculatorDisplayMode = {
  PENDING: 'pending',
  CHANGE: 'change',
  TO_PAY: 'toPay',
  QUANTITY: 'quantity'
}

export type CalculatorDisplayModeType =
  (typeof CalculatorDisplayMode)[keyof typeof CalculatorDisplayMode]

export type CalculatorPayload = {
  pending: number
  change: number
  toPay: number
  tip: number
}
