import { inject, type Plugin } from 'vue'

type ConfirmationPlugin = (privilege: string) => Promise<boolean>

let showConfirmation: (privilege: string) => Promise<boolean>

export function init(showFunction: (privilege: string) => Promise<boolean>) {
  showConfirmation = showFunction
}

export function managerConfirmation(privilege: string): Promise<boolean> {
  if (!showConfirmation) {
    // eslint-disable-next-line no-console
    console.warn('ManagerConfirmationWrapper not added in the app')
    return Promise.resolve(false)
  }
  return showConfirmation(privilege)
}

export function useConfirm() {
  return inject<ConfirmationPlugin>('confirm', managerConfirmation)
}

export const ManagerConfirmationPlugin: Plugin = {
  install(app) {
    app.config.globalProperties.$confirm = managerConfirmation
    app.provide('confirm', managerConfirmation)
  }
}
