<template>
  <div
    class="bg-n-700 h-16 rounded-xl border border-n-600 py-2 text-n-0"
    :class="[
      [CalculatorDisplayMode.TO_PAY, CalculatorDisplayMode.QUANTITY].includes(
        mode
      )
        ? 'px-6'
        : 'px-2'
    ]"
  >
    <div class="flex flex-row h-full text-center items-center">
      <div
        v-if="mode === CalculatorDisplayMode.PENDING"
        class="flex-1 border-r border-n-600 text-r-300 cursor-pointer"
        @click="$emit('setToPay')"
      >
        <div class="text-sm">{{ $t('checkout.pending') }}</div>
        <div class="font-bold text-xl">
          {{ pending }}
        </div>
      </div>
      <div
        class="flex-1 border-r border-n-600 flex items-center"
        :class="[
          [
            CalculatorDisplayMode.TO_PAY,
            CalculatorDisplayMode.QUANTITY
          ].includes(mode)
            ? 'flex-row'
            : 'flex-col'
        ]"
      >
        <div
          class="flex-1 text-sm"
          :class="{
            'text-left self-center': [
              CalculatorDisplayMode.TO_PAY,
              CalculatorDisplayMode.QUANTITY
            ].includes(mode)
          }"
        >
          <template v-if="mode === CalculatorDisplayMode.QUANTITY">
            {{ label }}
          </template>
          <template v-else>
            {{ $t('checkout.to-pay') }}
          </template>
        </div>
        <div
          class="flex-none font-bold"
          :class="[
            {
              'text-right': [
                CalculatorDisplayMode.TO_PAY,
                CalculatorDisplayMode.QUANTITY
              ].includes(mode)
            },
            [toPaySize]
          ]"
        >
          {{ toPay }}
        </div>
      </div>
      <div
        v-if="
          [
            CalculatorDisplayMode.PENDING,
            CalculatorDisplayMode.CHANGE
          ].includes(mode)
        "
        class="flex-1 text-b-500"
      >
        <div class="text-sm">
          {{ tipMode ? $t('checkout.tip') : $t('checkout.change') }}
        </div>
        <div class="font-bold text-xl">
          {{ change }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CalculatorDisplayMode,
  CalculatorDisplayModeType
} from '@/components/Calculator/CalculatorUtils'
import { computed } from 'vue'

type Props = {
  mode?: CalculatorDisplayModeType
  pending: string
  change: string
  toPay: string
  tipMode?: boolean
  label?: string
}

defineEmits(['setToPay'])

const props = withDefaults(defineProps<Props>(), {
  mode: CalculatorDisplayMode.PENDING,
  tipMode: false,
  label: ''
})

const toPaySize = computed(() => {
  return props.toPay.length > 9 ? 'text-md leading-[1.2rem]' : 'text-xl'
})
</script>
