import Bills from '@last/core/src/billsGenerator.js'
import { defineStore } from 'pinia'
import { useConfigStore } from './config'
import { useTabs } from '@/composables/useTabs'
import { computed } from 'vue'
import { TabProduct } from '@/types'

export const useBillingStore = defineStore('billing', () => {
  const config = useConfigStore()
  const getCurrentBill = computed(
    () =>
      ({
        tabId,
        currentDiscount,
        selectedProductIds = [],
        includeAlreadyBilled = false
      }: {
        tabId: string
        currentDiscount: any
        selectedProductIds: { id: string; uniqueId: string }[]
        includeAlreadyBilled?: boolean
      }) => {
        const { tab, allProducts } = useTabs(tabId)
        let selectedProducts = []
        if (selectedProductIds.length > 0) {
          const tabProducts = allProducts.value
          selectedProducts = Object.values(
            selectedProductIds
              .map(selection => {
                const product = tabProducts.find(
                  tabProduct => tabProduct.id === selection.id
                )!
                if (product.discountType === '2x1') {
                  product.discountType = undefined
                  product.discountAmount = undefined
                }
                return product
              })
              .reduce(
                (products, product) => {
                  products[product.id] = products[product.id] || {
                    ...product,
                    quantity: 0
                  }
                  products[product.id].quantity += 1
                  return products
                },
                {} as Record<string, TabProduct>
              )
          )
        } else {
          selectedProducts = allProducts.value.map(product => {
            return {
              ...product,
              quantity: includeAlreadyBilled
                ? product.quantity
                : product.notBilledQuantity ?? 0
            }
          })
        }
        return Bills.generateProductsBill({
          products: selectedProducts,
          company: config.config.company,
          tab: tab.value,
          discount: currentDiscount,
          taxRate: config.config.taxRate,
          ticketInfo: config.config.ticketInfo
        })
      }
  )

  return {
    getCurrentBill
  }
})
