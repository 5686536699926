import { inject, type Plugin } from 'vue'

export type CashMachineInput = {
  inputAmount: number
  amount: number
  onCancel: void
}

export type CashMachineFunction = (props: CashMachineInput) =>
  | {
      close: () => void
    }
  | undefined

let showCashMachine: CashMachineFunction

export function init(showFunction: CashMachineFunction) {
  showCashMachine = showFunction
}

export function cashMachineStatus(props: CashMachineInput) {
  if (!showCashMachine) {
    // eslint-disable-next-line no-console
    console.warn('CashMachineWrapper not added in the app')
    return
  }
  return showCashMachine(props)
}

export function useCashMachineStatus() {
  return inject<CashMachineFunction>('cashmachinestatus', cashMachineStatus)
}

export const CashMachineStatusPlugin: Plugin = {
  install(app) {
    app.config.globalProperties.$cashmachinestatus = cashMachineStatus
    app.provide('cashmachinestatus', cashMachineStatus)
  }
}
