<template>
  <full-screen :title="$t('hardware-selector.title')" @close="$router.go(-1)">
    <div class="flex flex-col w-full sm:w-1/2 px-4 sm:px-0 m-auto py-4">
      <l-option-selector
        v-if="options.length > 1"
        v-model="selectedOption"
        :options="options"
        class="mb-4"
      />
      <div v-else class="text-3xl text-n-0 font-bold text-center mb-4">
        {{ t('hardware-selector.till') }}
      </div>

      <card-option-selector
        v-model="selectedSubOption[selectedOption]"
        :options="subOptions[selectedOption]"
      />
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import { LOptionSelector } from '@last/core-ui/paprika'
import { useConfigStore } from '@/store/config'
import { useTillStore } from '@/store/till'
import { storeToRefs } from 'pinia'
import { computed, ref, watch, reactive } from 'vue'
import { useRouter } from 'vue-router'
import FullScreen from '@/components/core/FullScreen.vue'
import CardOptionSelector from '@/components/HardwareSelector/CardOptionSelector.vue'
import { useI18n } from 'vue-i18n'

const configStore = useConfigStore()
const tillStore = useTillStore()
const router = useRouter()

const { t } = useI18n()

const { updateHardwarePreferences } = configStore
const { tills, device, config } = storeToRefs(configStore)
const { setTill } = tillStore
const { selectedCashTillIsStarted, selectedCashTill, shiftsEnabled } =
  storeToRefs(tillStore)

type HardwareOptions = 'till' | 'cashMachine' | 'dataphone'
const selectedOption = ref<HardwareOptions>('till')

type SelectedSubOption = Record<HardwareOptions, string>

const selectedSubOption = reactive<SelectedSubOption>({
  till: selectedCashTill.value?.id || '',
  cashMachine: device.value.preferredCashMachine || '',
  dataphone: device.value.preferredDataphone || ''
})

watch(selectedSubOption, newValue => {
  updatePreferences(selectedOption.value, newValue[selectedOption.value])
})

const options = computed(() => {
  const opts = [{ label: t('hardware-selector.till'), value: 'till' }]

  if (subOptions.value.cashMachine.length > 0) {
    opts.push({
      label: t('hardware-selector.cash-machine'),
      value: 'cashMachine'
    })
  }

  if (subOptions.value.dataphone.length > 0) {
    opts.push({ label: t('hardware-selector.dataphone'), value: 'dataphone' })
  }

  return opts
})

const subOptions = computed((): Record<HardwareOptions, any> => {
  const tillsOptions = tills.value.cash.map(cashTill => ({
    value: cashTill.id,
    label: cashTill.name,
    type: 'till'
  }))

  const cashMachines = config.value.paymentMethodsConfig.filter(
    paymentMethod => {
      return paymentMethod.configs
    }
  )

  const cashMachinesOptions = cashMachines.reduce(
    (acc, cashMachine) => {
      for (const config of cashMachine.configs) {
        acc.push({
          value: config.id,
          label: `${cashMachine.name} - ${config.name}`,
          type: 'cashMachine'
        })
      }
      return acc
    },
    [] as { value: string; label: string; type: HardwareOptions }[]
  )

  const dataphonesOptions = config.value.dataphoneConfigs.map(dataphone => ({
    value: dataphone.id,
    label: dataphone.name,
    type: 'dataphone'
  }))

  return {
    till: tillsOptions,
    cashMachine: cashMachinesOptions,
    dataphone: dataphonesOptions
  }
})

function updatePreferences(type: HardwareOptions, newValue: string) {
  if (type === 'till') {
    const selectedTill = tills.value.cash.find(till => till.id === newValue)
    setTill(selectedTill!)
    if (shiftsEnabled.value && !selectedCashTillIsStarted.value) {
      router.push({ name: 'startShift' })
    }
    updateHardwarePreferences({
      preferredTill: newValue
    })
  } else if (type === 'cashMachine') {
    updateHardwarePreferences({
      preferredCashMachine: newValue
    })
  } else if (type === 'dataphone') {
    updateHardwarePreferences({
      preferredDataphone: newValue
    })
  }
}
</script>
