<template>
  <product-component
    :product="product"
    :disabled="disabled"
    :disable-swipe="disableSwipe"
    :show-quantity-selector="!disabledQuantitySelector"
    show-comments
    allow-remove
    show-sent-to-kitchen
    show-billing-status
    @selected="emit('selected')"
  ></product-component>
</template>

<script setup lang="ts">
import ProductComponent from '@/components/core/Product.vue'
import useProducts from '@/composables/useProducts'
import { computed } from 'vue'
import type { TabProduct } from '@/types'
import { toRef } from 'vue'

const props = defineProps<{
  product: TabProduct
  disableSwipe?: boolean
  disabledQuantitySelector?: boolean
}>()

const emit = defineEmits(['selected'])

const { productIsBilled } = useProducts(toRef(props, 'product'))

const disabled = computed(() => {
  return productIsBilled.value
})
</script>
