import PrinterQueue from '@/integrations/printer/printerQueue.js'
import { useTillStore } from '@/store/till'
import { useConfigStore } from '@/store/config'

let queues = {}

function loadPrinters(printers) {
  let ipQueues = printers.reduce((res, printer) => {
    try {
      res[printer.ip] = new PrinterQueue(printer)
    } catch (e) {
      // Unsupported printer
    }
    return res
  }, {})
  queues = printers.reduce((res, printer) => {
    try {
      if (printer.ip) res[printer.id] = ipQueues[printer.ip]
      else res[printer.id] = new PrinterQueue(printer)
    } catch (e) {
      // Unsupported printer
    }
    return res
  }, {})
  const { currentAdyenDataphone } = useConfigStore()
  if (currentAdyenDataphone) {
    queues['self'] = new PrinterQueue({
      name: 'Adyen Dataphone',
      type: 'AdyenPrinter',
      id: 'self',
      metadata: {
        poiid: currentAdyenDataphone.poiid
      }
    })
  }
}

function printImage(printerId, imageData, copies = 1, printedEvent) {
  for (let i = 0; i < copies; ++i) {
    let queue = queues[printerId]
    if (queue) {
      queue.addJob({
        type: 'printImage',
        image: imageData,
        printedEvent,
        time: new Date()
      })
    }
  }
}

function openCashDrawer() {
  let till = useTillStore()
  let config = useConfigStore()
  let printerId =
    (till.selectedCashTill && till.selectedCashTill.printerId) ||
    config.config.defaultBillPrinter
  let queue = queues[printerId]
  if (queue) {
    queue.addJob({
      type: 'openCashDrawer'
    })
  }
}

export default { printImage, openCashDrawer, loadPrinters }
