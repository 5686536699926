<template>
  <l-modal
    v-if="!previewOrderId"
    size="small"
    :title="$t('resend-kitchen-orders.title')"
    :description="$t('resend-kitchen-orders.description')"
    :button-enabled="hasFailedOrders && !previewOrderId"
    :button-text="$t('resend-kitchen-orders.resend-failed')"
    @close="$emit('close')"
    @action="resendFailed"
  >
    <div
      v-for="order in orders"
      :key="order.id"
      class="flex items-center gap-3 bg-n-700 rounded-xl py-6 px-4 mb-4"
      @click="previewOrderId = order.id"
    >
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="text-success pr-2">
            {{ $filters.time(order.creationTime) }}
          </div>
          <div class="text-n-0 font-heading font-medium">
            {{ order.description }}
          </div>
        </div>
        <div class="text-n-200 text-sm mt-2 ml-6">
          {{ getOrderDetails(order) }}
        </div>
      </div>
      <l-status
        v-if="order.printable && !order.printed"
        class="ml-auto"
        status="error"
      >
        {{ $t('resend-kitchen-orders.error') }}
      </l-status>
    </div>
  </l-modal>
  <teleport v-if="previewOrderId" to="#appRoot">
    <div
      class="fixed inset-0 bg-n-900/80 backdrop-blur-sm overflow-hidden"
      @click="previewOrderId = null"
    >
      <div class="w-full h-full py-8 pb-28 sm:pb-8 overflow-y-auto">
        <div>
          <div class="flex flex-col items-center">
            <div
              v-if="previewOrderId && canvasImage"
              class="bg-n-0 rounded-lg p-2"
              @click.stop="() => undefined"
            >
              <img :src="canvasImage" class="h-full" />
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-8 right-8">
        <l-button @click="resendOrder(previewOrderId)">{{
          $t('resend-kitchen-orders.resend')
        }}</l-button>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import TicketPrinter from '@/ticketPrinter.js'
import KitchenOrderFormatter from '@/kitchenOrderFormatter.js'
import TicketGenerator from '@/tickets/generator.js'
import { LModal, LStatus, LButton } from '@last/core-ui/paprika'
import { useTabsStore } from '@/store/tabs'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'
import { useTabs } from '@/composables/useTabs'

const props = defineProps<{
  tabId: string
}>()

const emit = defineEmits(['close'])

const tabsStore = useTabsStore()
const catalogStore = useCatalogStore()
const configStore = useConfigStore()

const { kitchenOrders } = storeToRefs(tabsStore)
const { getCatalogIdByTabVirtualBrandId, catalogs } = storeToRefs(catalogStore)
const { config } = storeToRefs(configStore)
const { getKitchenOrder } = useKitchenOrders(props.tabId)
const { tab } = useTabs(props.tabId)

const previewOrderId = ref<string | null>(null)
const canvasImage = ref<string | null>(null)

const hasFailedOrders = computed(() => {
  return (
    orders.value.filter(order => !order.printed && order.printable).length > 0
  )
})

type PartialOrder = {
  id: string
  creationTime: number
  description: string
  copies: number
  printed: boolean
  printable: boolean
}

const orders = computed<PartialOrder[]>((): PartialOrder[] => {
  let orders = tab.value.kitchenOrders.map(id => kitchenOrders.value[id])
  return Object.values(orders)
    .map(order => {
      return {
        id: order.id,
        creationTime: order.creationTime,
        description: order.printerName,
        copies: order.copies,
        printed: !!order.printedTime,
        printable: order.copies > 0
      }
    })
    .sort((a, b) => b.creationTime - a.creationTime)
})

const previewPrinter = computed(() => {
  return kitchenOrders.value[previewOrderId.value]
    ? config.value.printers[kitchenOrders.value[previewOrderId.value].printerId]
    : null
})

const previewOrder = computed(() => {
  if (!previewOrderId.value) return null
  let catalogId = getCatalogIdByTabVirtualBrandId.value(
    tab.value.virtualBrandId,
    tab.value.pickupType,
    tab.value.source
  )
  let sortedCourses = catalogs.value[catalogId]?.courses || []
  return KitchenOrderFormatter.format(
    kitchenOrders.value[previewOrderId.value],
    sortedCourses
  )
})

watch(previewOrder, async () => {
  if (previewOrder.value) {
    let canvas = await TicketGenerator.kitchenOrder(
      previewOrder.value,
      previewPrinter.value
    )
    canvasImage.value = canvas.toDataURL('image/png')
  }
})

function resendOrder(orderId: string) {
  TicketPrinter.printKitchenOrder(getKitchenOrder(orderId))
  previewOrderId.value = null
}

function resendFailed() {
  orders.value
    .filter(order => !order.printed && order.printable)
    .forEach(order =>
      TicketPrinter.printKitchenOrder(getKitchenOrder(order.id))
    )
  emit('close')
}

function getOrderDetails(order: PartialOrder): string {
  const lastVersion =
    kitchenOrders.value[order.id].versions[
      kitchenOrders.value[order.id].versions.length - 1
    ]

  const calculations = lastVersion.products.reduce(
    (acc: Record<string, number>, item) => {
      if (!acc[item.course]) {
        acc[item.course] = 0
      }

      acc[item.course] = acc[item.course] + item.quantity

      return acc
    },
    {}
  )

  const result: string[] = []
  Object.entries(calculations).forEach(([key, value]) => {
    result.push(`${value} ${key}`)
  })

  return result.join(', ')
}
</script>
