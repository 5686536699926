<template>
  <TabDetails
    v-if="!isClientTabs"
    v-model:tab="newTab"
    v-model:customer="customer"
    v-model:phone-number="customer.phoneNumber"
    :title="$t('new-tab.new-tab-take-away')"
    type="takeAway"
    @next-step="nextStep"
    @close="$emit('close')"
  />
  <ClientDetails
    v-else
    :is-details-visible="false"
    :customer="customer"
    :last-customer-tabs="lastTabs"
    :new-tab="newTab"
    @close="$emit('close')"
    @tab-created="tabId => $emit('tabCreated', tabId)"
    @back="backStep"
  />
</template>

<script setup lang="ts">
import ClientDetails from '@/components/tabs/ClientDetails.vue'
import TabDetails from '@/components/tabs/TabDetails.vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTabsStore } from '@/store/tabs'

const emit = defineEmits(['close', 'tabCreated'])

const router = useRouter()

const tabsStore = useTabsStore()
const { openTab } = tabsStore

const newTab = ref({
  source: null,
  name: null,
  pickupType: 'takeAway'
})
const customer = ref<any>({})
const isClientTabs = ref(false)
const lastTabs = ref([])

function nextStep(value: any) {
  let parsedPhoneNumber = parsePhoneNumberFromString(
    value.customer.phoneNumber || ''
  )
  if (parsedPhoneNumber?.isValid()) {
    isClientTabs.value = true
    customer.value = { ...customer.value, ...value.customer }
    lastTabs.value = value.tabs
  } else {
    createTakeAwayTab()
  }
}
function backStep({
  customer: inputCustomer,
  tab
}: {
  customer: any
  tab: any
}) {
  isClientTabs.value = false
  customer.value = inputCustomer
  newTab.value = tab
}

async function createTakeAwayTab() {
  const tab = {
    ...newTab.value,
    deliveryOrder: {
      source: newTab.value.source,
      status: 'CREATED',
      deliveryOrderStatuses: [
        {
          status: 'CREATED',
          creationTime: new Date()
        }
      ]
    }
  }
  let tabId = await openTab({ tableId: null, tab })
  emit('tabCreated', tabId)
  emit('close')
  router.push({
    name: 'orderManagement',
    params: { tabId }
  })
}
</script>
