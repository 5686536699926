<template>
  <teleport to="body">
    <l-modal
      :title="$t('product-discount.title')"
      :button-text="$t('product-discount.save')"
      :button-enabled="discountIsValid"
      @action="save"
      @close="emit('close')"
    >
      <l-option-selector
        v-model="editingDiscount.type"
        :options="discountTypes"
      />

      <l-field :label="$t('product-discount.discount-to-apply')" class="mt-4">
        <l-input
          v-if="editingDiscount.type === 'currency'"
          v-model.currency="editingDiscount.amount"
          type="number"
          :min="0"
          :icon="discountIcon"
        />
        <l-input
          v-else
          v-model.number="editingDiscount.amount"
          type="number"
          :min="0"
          :icon="discountIcon"
        />
      </l-field>

      <l-field :label="$t('bill-discount.concept')">
        <l-input v-model="editingDiscount.concept" type="textarea" />
      </l-field>
    </l-modal>
  </teleport>
</template>

<script setup lang="ts">
import { useConfigStore } from '@/store/config'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import { TabProduct } from '@/types'
import { ref } from 'vue'
import { LModal, LField, LInput, LOptionSelector } from '@last/core-ui/paprika'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTabsStore } from '@/store/tabs'
import { onMounted } from 'vue'
import { useConfirm } from '@/plugins/managerConfirmation'

const { t } = useI18n()
const configStore = useConfigStore()
const tabsStore = useTabsStore()

const confirm = useConfirm()

const props = defineProps<{
  product: TabProduct
}>()

const emit = defineEmits(['close'])

const { currencyIcon } = storeToRefs(configStore)

const editingDiscount = ref<{
  type: string
  amount: number | null
  concept: string | null
}>({
  type: 'percentage',
  amount: null,
  concept: null
})

onMounted(() => {
  if (props.product.discountType) {
    editingDiscount.value.type = props.product.discountType
    editingDiscount.value.amount =
      props.product.discountType === 'cash'
        ? props.product.discountAmount! / 100
        : props.product.discountAmount!
    editingDiscount.value.concept = props.product.discountConcept ?? null
  }
})

const discountIcon = computed(() => {
  if (editingDiscount.value.type === 'percentage') {
    return 'percentage'
  }
  return `currencies/${currencyIcon.value}`
})

const discountTypes = computed(() => [
  {
    icon: 'money',
    value: 'cash',
    label: t('product-discount.cash')
  },
  {
    icon: 'percentage',
    value: 'percentage',
    label: t('product-discount.percentage')
  }
])

const discountIsValid = computed(() => {
  return (
    editingDiscount.value !== null &&
    editingDiscount.value.amount !== null &&
    !isNaN(editingDiscount.value.amount) &&
    ((editingDiscount.value.type === 'cash' &&
      editingDiscount.value.amount * 100 <= props.product.fullPrice) ||
      (editingDiscount.value.type === 'percentage' &&
        editingDiscount.value.amount <= 100)) &&
    editingDiscount.value.type !== null
  )
})

async function save() {
  if (!discountIsValid.value) return
  const hasPrivilege = await confirm('DISCOUNT_MANAGER')
  if (!hasPrivilege) return

  let sendingAmount =
    editingDiscount.value.type === 'cash'
      ? editingDiscount.value.amount! * 100
      : editingDiscount.value.amount
  updateDiscount({ ...editingDiscount.value, amount: sendingAmount })
  emit('close')
}

async function updateDiscount(discount: any) {
  let productPricing = ProductPriceCalculator.calculateProductPricing({
    ...props.product,
    discountType: discount.type,
    discountAmount: discount.amount
  })
  if (+discount.amount === 0) {
    removeProductDiscount()
    return
  }
  if (props.product.quantity > 1) {
    let splittedProductId = tabsStore.splitProduct({
      productId: props.product.id,
      seat: props.product.seat
    })
    tabsStore.updateProductDiscount({
      productId: splittedProductId,
      discount: {
        discountType: discount.type,
        discountAmount: discount.amount,
        discountConcept: discount.concept,
        promotionId: null
      },
      productPricing: {
        ...productPricing
      }
    })
  } else {
    tabsStore.updateProductDiscount({
      productId: props.product.id,
      discount: {
        discountType: discount.type,
        discountAmount: discount.amount,
        discountConcept: discount.concept,
        promotionId: null
      },
      productPricing: {
        ...productPricing
      }
    })
  }
}

function removeProductDiscount() {
  let productPricing = ProductPriceCalculator.calculateProductPricing({
    ...props.product,
    discountType: null,
    discountAmount: null
  })
  tabsStore.updateProductDiscount({
    productId: props.product.id,
    discount: {
      discountType: null,
      discountAmount: null,
      discountConcept: null,
      promotionId: null
    },
    productPricing: {
      ...productPricing
    }
  })
}
</script>
