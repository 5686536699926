<template>
  <l-modal
    :active-screen="currentStepIndex"
    :screens="screens"
    :action-disabled="actionDisabled"
    :full-height="false"
    @close="$emit('close')"
    @update:active-screen="handleUpdateScreen"
    @action="performAction"
    @secondary-action="performSecondaryAction"
  >
    <template #0>
      <bills-list :tab-id="tabId" @selected="selectBill" />
    </template>
    <template #1>
      <companies-list
        @modify="company => editCompany(company)"
        @select="company => selectCompany(company)"
      />
    </template>
    <template #2>
      <company-editor v-model="selectedCompany" />
    </template>
    <template #3>
      <div class="mb-4">{{ $t('generate-invoice.send-invoice-info') }}</div>
      <l-field :label="$t('invoice-modal.email-label')" mandatory>
        <l-input
          v-model="email"
          :placeholder="$t('invoice-modal.email-placeholder')"
        />
      </l-field>
    </template>
  </l-modal>
</template>

<script setup lang="ts">
import { LModal, LField, LInput } from '@last/core-ui/paprika'
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import CompaniesList from './CompaniesList.vue'
import BillsList from './BillsList.vue'
import CompanyEditor from './CompanyEditor.vue'
import { type Company } from './types'
import lastUtils from '@last/core/src/lastUtils.js'
import { useTabsStore } from '@/store/tabs'
import { useTabs } from '@/composables/useTabs'
import sync from '@/sync/service'

const { regenerateBill } = useTabsStore()
const { t, locale } = useI18n()

const emit = defineEmits(['close', 'newBillId'])

const props = defineProps<{
  tabId: string
  billId?: string
}>()

type Step = {
  title: string
  section?: string
  action?: () => void
  secondaryAction?: () => void
  button?: string
}

type Screen = {
  name: string
  buttonText?: string
  secondaryButtonText?: string
  secondaryButton?: boolean
  sectionTitle?: string
  title?: string
  description?: string
}

const { allBills } = useTabs(props.tabId)

const steps: Step[] = [
  {
    title: t('generate-invoice.bills')
  },
  {
    title: t('generate-invoice.title'),
    section: t('generate-invoice.bills')
  },
  {
    title: t('generate-invoice.new-company'),
    section: t('generate-invoice.title'),
    button: t('generate-invoice.new-company'),
    action: () => {
      handleUpdateScreen(currentStepIndex.value + 1)
    }
  },
  {
    title: t('generate-invoice.send-invoice'),
    button: t('generate-invoice.send-invoice'),
    secondaryButton: t('checkout.cancel'),
    action: () => {
      let billId
      if (newBillId !== '') {
        billId = newBillId
      } else if (selectedBill.value?.customerCompany) {
        billId = selectedBill.value.id
      }
      if (billId) {
        sync.record('sendInvoiceEmailRequested', {
          billId,
          email: email.value,
          locale: locale.value
        })
      }
      emit('close')
    },
    secondaryAction: () => {
      emit('close')
    }
  }
] as const

const screens: Screen[] = steps.map((step, index) => {
  const screen = {
    name: index.toString(),
    buttonText: step.button,
    sectionTitle: step.section,
    title: step.title
  }
  if (step.secondaryButton) {
    screen.secondaryButtonText = step.secondaryButton
  }
  return screen
})

const currentStepIndex = ref(0)
const selectedBillId = ref<string | undefined>(props.billId)
const selectedBill = computed(() => {
  return allBills.value.find(bill => bill.id === selectedBillId.value)
})

onMounted(() => {
  if (props.billId) {
    selectBill(props.billId)
  }
})

function selectBill(billId: string) {
  selectedBillId.value = billId
  currentStepIndex.value = 1
}

const currentStep = computed(() => steps[currentStepIndex.value])

const selectedCompany = ref<Company>()
const selectedCompanyIsValid = computed(() => {
  return !!(
    selectedCompany.value &&
    selectedCompany.value.taxId &&
    selectedCompany.value.name &&
    selectedCompany.value.address &&
    lastUtils.taxIdIsValid(selectedCompany.value.taxId)
  )
})

const email = ref('')
const emailIsValid = computed(() => {
  return !!email.value && lastUtils.isEmail(email.value)
})

const actionDisabled = computed<boolean>(() => {
  if (currentStepIndex.value === 2) return !selectedCompanyIsValid.value
  if (currentStepIndex.value === 3) return !emailIsValid.value

  return false
})

function selectCompany(company: Company) {
  selectedCompany.value = company
  currentStepIndex.value = 3
}

function editCompany(company: Company) {
  selectedCompany.value = company
  currentStepIndex.value = 2
}

function performAction() {
  if (!currentStep.value || !currentStep.value.action) return
  currentStep.value.action()
}

function performSecondaryAction() {
  if (!currentStep.value || !currentStep.value.secondaryAction) return
  currentStep.value.secondaryAction()
}

function handleUpdateScreen(index: number) {
  if (currentStepIndex.value === 3 && index < 3) index--
  currentStepIndex.value = index

  if (currentStepIndex.value === 3 && !selectedBill.value?.customerCompany) {
    generateInvoice()
  }

  if (currentStepIndex.value === 1 && selectedBill.value?.customerCompany) {
    handleUpdateScreen(3)
  }
}

let newBillId: string = ''
async function generateInvoice() {
  const billId = await regenerateBill({
    tabId: props.tabId,
    billId: props.billId,
    customerCompany: selectedCompany.value
  })
  newBillId = billId
  emit('newBillId', billId)
}
</script>
