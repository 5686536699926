<template>
  <div
    class="w-[18.25rem] rounded-2xl py-4 px-3 border bg-n-800/80 border-n-700 backdrop-blur-sm box-border flex align-text-bottom font-body text-sm leading-5"
    :class="{ 'cursor-pointer': onAction }"
    @click="executeAction"
  >
    <div class="flex grow flex-col gap-1">
      <div class="flex gap-2 items-center">
        <div :class="typeClasses" class="rounded-full p-1.5">
          <icon :name="selectedIcon" :class="'text-white'" size="small"></icon>
        </div>
        <div class="flex-1 gap-2 text-n-0">
          {{ title }}
        </div>
        <icon
          v-if="!progress || progress >= 100"
          :class="{
            'cursor-pointer': close,
            'pointer-events-none': !close,
            invisible: !close
          }"
          name="close"
          class="text-v"
          @click.stop="emit('close')"
        ></icon>
        <div
          v-else
          class="text-v-300 font-body text-sm leading-5 cursor-pointer"
          @click="emit('cancel')"
        >
          {{ $t('notification.cancel') }}
        </div>
      </div>
      <div v-if="description" class="flex gap-2">
        <div class="w-8"></div>
        <div class="flex-1 text-n-200">{{ description }}</div>
        <div class="w-5"></div>
      </div>
      <div v-if="hasProgress" class="flex">
        <div class="w-12"></div>
        <div class="w-full h-3 bg-v-100 rounded-full overflow-hidden">
          <div
            :class="typeClasses"
            class="flex items-center justify-center h-3 bg-violet rounded-full transition-all duration-200"
            :style="progressStyles"
          >
            <div
              v-if="progress && progress >= 20"
              class="text-center text-4 text-white text-xs"
            >
              {{ Math.min(progress, 100) }}%
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="timeAgo && !hasProgress"
        class="flex justify-end font-body text-xs leading-4 text-n-200"
      >
        {{ timeAgo }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from '../Icon.vue'
import { computed, watchEffect, ref } from 'vue'
import { type Notification } from './types'
import moment from 'moment'

const emit = defineEmits(['close', 'cancel'])

const props = withDefaults(defineProps<Notification>(), {
  icon: 'default-notification',
  title: '',
  description: '',
  progress: undefined,
  close: true,
  type: 'info',
  important: false,
  onAction: undefined,
  onCancel: undefined
})

const typeClasses = computed<string[]>(() => {
  switch (props.type) {
    case 'info':
      return ['bg-v-300']
    case 'success':
      return ['bg-g-500']
    case 'error':
      return ['bg-r-500']
    default:
      return ['bg-v-300']
  }
})

const progressStyles = computed(() => {
  return {
    width: `${props.progress}%`
  }
})

const selectedIcon = computed(() => {
  switch (props.type) {
    case 'info':
      return props.icon
    case 'success':
      return 'done'
    case 'error':
      return 'error'
    default:
      return props.icon
  }
})

const hasProgress = computed(
  () => props.progress !== undefined && props.progress >= 0
)

const timeAgo = ref<string | null>(null)

watchEffect(() => {
  if (props.important) return
  const initialDiff = moment().diff(moment(props.createdAt), 'seconds')
  if (initialDiff > 60) {
    timeAgo.value = moment(props.createdAt).fromNow()
  }
  const intervalId = setInterval(() => {
    timeAgo.value = moment(props.createdAt).fromNow()
  }, 60 * 1000)
  return () => {
    clearInterval(intervalId)
  }
})

function executeAction() {
  if (props.onAction) {
    props.onAction()
  }
}
</script>
