import localDb from '@/localDb'
import api from '@/api'
import GlovoScrapper from '@last/core/src/glovoScrapper'
import { defineStore } from 'pinia'
import { useConfigStore } from './config'

export const useScrapperStore = defineStore('scrapper', {
  persist: true,
  state: () => ({
    orders: {},
    refreshToken: null,
    accessToken: null,
    glovoStores: [],
    scrapper: null
  }),
  getters: {
    storesHaveChanged: state => stores => {
      if (stores.length === 0) return false
      if (stores.length !== state.glovoStores.length) return true
      return !stores.every(store => {
        state.glovoStores.includes(store)
      })
    }
  },
  actions: {
    async initScrapper() {
      this.initData()
      let { user, password } = this.glovoStores[0].metadata
      if (!this.scrapper) this.scrapper = new GlovoScrapper(user, password)
      let initialData = {
        orders: this.orders,
        refreshToken: this.refreshToken,
        accessToken: this.accessToken,
        storeIds: this.glovoStores.map(store => store.externalId)
      }
      this.scrapper.startScrapping(
        initialData,
        token => this.updateToken(token),
        order => this.removeOrder(order),
        order => this.sendNewOrder(order)
      )
    },
    async initData() {
      let token = await localDb.loadTables(['scrapper:token'])
      let orders = await localDb.loadTables(['scrapper:orders'])

      if (token && token['scrapper:token']) {
        let tokenData = token['scrapper:token']
        this.updateToken(tokenData)
      }

      if (orders && orders['scrapper:orders']) {
        let ordersData = orders['scrapper:orders']
        Object.values(ordersData).forEach(order => {
          this.addOrder(order)
        })
      }
    },
    updateStores(stores) {
      let config = useConfigStore()
      const isMaster = config.device.mode === 'master'
      if (isMaster && this.storesHaveChanged(stores)) {
        this.glovoStores = stores
        this.initScrapper()
      }
    },
    updateToken(token) {
      if (!token) return
      localDb.setItem('scrapper:token', 'refreshToken', token.refreshToken)
      localDb.setItem('scrapper:token', 'accessToken', token.accessToken)
      this.refreshToken = token.refreshToken
      this.accessToken = token.accessToken
    },
    sendNewOrder(order) {
      try {
        api.post('/scrapper/orders', { order })
        this.addOrder(order)
      } catch (e) {
        // Ignore exception, but skip commit
      }
    },
    removeOrder(order) {
      if (!order) return
      localDb.removeItem('scrapper:orders', order.id)
      delete this.orders[order.id]
    },
    addOrder(order) {
      if (!order) return
      localDb.setItem('scrapper:orders', order.id, order)
      this.orders[order.id] = order
    }
  }
})
