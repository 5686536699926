<template>
  <l-modal
    :title="$t('ordering.new-open-product')"
    :button-text="$t('ordering.create')"
    @action="save"
    @close="$emit('close')"
  >
    <div class="flex justify-between items-center py-2 mb-4">
      <div class="font-body text-sm">{{ $t('ordering.quantity') }}</div>
      <quantity-selector v-model:quantity="openProduct.quantity" />
    </div>
    <div class="flex gap-4">
      <l-field :label="$t('ordering.name')" class="w-3/4">
        <l-input
          v-model="openProduct.name"
          :placeholder="$t('ordering.name')"
          :wrong-value="nameError"
          :error-message="$t('ordering.invalid-name')"
        />
      </l-field>
      <l-field :label="$t('ordering.price')" class="w-1/4">
        <l-input
          v-model.currency="openProduct.price"
          :icon="currencyIcon"
          type="number"
          :min="0"
          :placeholder="$t('ordering.price')"
          :wrong-value="priceError"
          :error-message="$t('ordering.invalid-price')"
        />
      </l-field>
    </div>

    <div class="flex gap-4">
      <l-field :label="$t('ordering.category')" class="flex-1 min-w-0">
        <l-select v-model="openProduct.categoryId" :options="categoryList" />
      </l-field>
      <l-field :label="$t('ordering.course-type')" class="flex-1 min-w-0">
        <l-select
          v-model="openProduct.courseId"
          :options="allCourses"
          :placeholder="$t('ordering.enter-course')"
        />
      </l-field>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { useConfigStore } from '@/store/config'
import { LInput, LField, LModal, LSelect } from '@last/core-ui/paprika'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import { useCatalogStore } from '@/store/catalog'
import { onMounted } from 'vue'

const props = defineProps<{
  defaultName: string | null
  categoryId: string | null
  catalogId: string | null
  courseId: string | null
}>()
const emit = defineEmits(['close', 'save'])

const configStore = useConfigStore()
const { currencyIcon } = storeToRefs(configStore)
const catalogStore = useCatalogStore()
const { catalogs, categories } = storeToRefs(catalogStore)

const openProduct = ref<{
  name: string | null
  price: number | null
  quantity: number
  categoryId: string | null
  courseId: string | null
}>({
  name: props.defaultName,
  price: null,
  quantity: 1,
  categoryId: null,
  courseId: null
})
const priceError = ref(false)
const nameError = ref(false)

onMounted(() => {
  openProduct.value.categoryId = props.categoryId
  openProduct.value.courseId = props.courseId
})

const categoryIds = computed(() => {
  if (!props.catalogId) return
  return catalogs.value[props.catalogId]?.categories || []
})

const categoryList = computed(() => {
  if (!categoryIds.value) return []
  return categoryIds.value.map((value: string) => ({
    value,
    label: getCategories.value[value]?.name || ''
  }))
})

const getCategories = computed(() => {
  if (!props.catalogId) return {}
  const catalog = catalogs.value[props.catalogId]
  if (!catalog) return {}
  return catalog.categories.reduce(
    (res, categoryId) => {
      res[categoryId] = categories.value[categoryId]
      return res
    },
    {} as Record<string, any>
  )
})

const allCourses = computed(() => {
  return (
    Object.values(catalogs.value)[0]?.courses?.map((course: string) => ({
      label: course,
      value: course
    })) || []
  )
})

function save() {
  const { price, name, quantity, courseId, categoryId } = openProduct.value
  priceError.value = false
  nameError.value = false
  if (!price || price < 0) {
    priceError.value = true
  }
  if (!name || name.length < 1) {
    nameError.value = true
  }
  if (!priceError.value && !nameError.value) {
    emit('save', {
      price,
      name,
      quantity,
      course: courseId,
      category: categoryId
    })
  }
}
</script>
