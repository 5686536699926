<template>
  <div
    class="z-10 flex flex-col shadow-md bg-n-800 min-w-72"
    :class="{
      'w-full sm:w-1/5 sm:border-r border-n-700 box-content': !fullScreen,
      'w-full': fullScreen
    }"
  >
    <div class="flex flex-row justify-between items-center">
      <div
        class="flex flex-row items-center min-w-72 p-4 gap-4"
        :class="{
          'w-1/5': fullScreen,
          'w-full': !fullScreen
        }"
      >
        <l-option-selector
          v-model="viewMode"
          :options="viewModes"
          class="w-24 shrink-0 hidden sm:flex"
        />
        <l-select
          v-model="tabsFilter"
          :options="tabViewOptions"
          size="small"
          class="w-full"
        />
      </div>
      <l-button
        v-if="
          tabsType === 'delivery' &&
          fullScreen &&
          config.manualShipment &&
          !choosingForShipment
        "
        type="secondary"
        size="small"
        class="m-4"
        @click="choosingForShipment = !choosingForShipment"
      >
        {{ $t('tabs.request-shipments') }}
      </l-button>
    </div>
    <div v-if="fullScreen && choosingForShipment" class="flex flex-row px-4">
      <l-checkbox
        :model-value="
          filteredNowTabsPickableForShipment.length ===
          pickedTabsForShipment.length
        "
        :half="selectAllIsHalfed"
        @update:model-value="toggleSelectAll"
      />
      <div class="text-n-0 ml-3">
        {{ $t('tabs.select-all') }}
      </div>
    </div>
    <div v-if="fullScreen" class="flex flex-row items-center p-4">
      <div
        v-for="header in headers"
        :key="header"
        class="flex-1 text-sm text-n-200 font-body"
        :class="{
          'w-1/4 xl:w-1/5 flex-none': header === $t('tabs.address'),
          'w-1/4 flex-none xl:w-1/6': header === $t('tabs.tab'),
          'w-1/4 flex-none': header === $t('tabs.time')
        }"
      >
        {{ header }}
      </div>
    </div>
    <div class="flex-1 overflow-hidden">
      <tab-rows
        v-model:view-mode="viewMode"
        :tabs="filteredTabs"
        :picked-tabs-for-shipment="pickedTabsForShipment"
        :choosing-for-shipment="choosingForShipment"
        :full-screen="fullScreen"
        :selected-tab-id="selectedTabId"
        :row-size="rowSize"
        :current-time="currentTime"
        @select-tab="selectTab"
        @shipment-pick-toggled="shipmentPickToggled"
      />
    </div>
    <div
      v-if="fullScreen && choosingForShipment"
      class="flex flex-row justify-between absolute bottom-0 right-0 w-full p-3 bg-n-700"
    >
      <l-button
        type="text"
        size="small"
        icon="close"
        @click="choosingForShipment = false"
      >
        {{ $t('tabs.cancel') }}
      </l-button>
      <l-button
        :loading="requestShipmentLoading"
        size="small"
        class="w-32"
        @click="requestShipment"
      >
        {{ $t('tabs.request') }}
      </l-button>
    </div>
    <template v-if="!fullScreen && hasCatalog">
      <l-button
        v-if="showCreateTabButton"
        size="small"
        type="secondary"
        class="hidden sm:block mx-4 mb-6"
        @click="createNewTab"
      >
        {{ tabsType === 'delivery' ? $t('tabs.new-delivery') : $t('tabs.new') }}
      </l-button>
      <l-button
        v-if="showCreateTabButton"
        size="medium"
        type="primary"
        class="block sm:hidden mx-4 mb-6"
        @click="createNewTab"
      >
        {{ tabsType === 'delivery' ? $t('tabs.new-delivery') : $t('tabs.new') }}
      </l-button>
      <new-tab
        v-if="configuringTab.onSite && tabsType !== 'takeAway'"
        :is-active="configuringTab.onSite && tabsType !== 'takeAway'"
        @close="configuringTab.onSite = false"
        @tab-created="tabId => $emit('tabCreated', tabId)"
      />
      <new-take-away-tab
        v-if="configuringTab.onSite && tabsType === 'takeAway'"
        @close="configuringTab.onSite = false"
        @tab-created="tabId => $emit('tabCreated', tabId)"
      />
      <new-delivery-tab
        v-if="configuringTab.delivery && tabsType === 'delivery'"
        @close="configuringTab.delivery = false"
        @tab-created="tabId => $emit('tabCreated', tabId)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  defineProps,
  defineEmits,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  defineModel,
  toRef
} from 'vue'
import NewTab from '@/components/tabs/NewTab.vue'
import NewDeliveryTab from '@/components/tabs/NewDeliveryTab.vue'
import NewTakeAwayTab from '@/components/tabs/NewTakeAwayTab.vue'
import {
  LOptionSelector,
  LButton,
  LSelect,
  LCheckbox,
  useDialog
} from '@last/core-ui/paprika'
import { EventBus } from '@/eventBus'
import barcodeScanner from '@/barcodeScanner.js'
import api from '@/api'
import TabRows from '@/components/tabs/TabRows.vue'
import moment from 'moment'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { useCouriersStore } from '@/store/couriers'
import { useCatalogStore } from '@/store/catalog'
import { useNotifications } from '@/composables/useNotifications'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { Tab, Courier } from '@/types'
import app from '@/app'
import { useWindowSize } from '@vueuse/core'

const { t } = useI18n()
const dialog = useDialog()
const { notifyPaymentFailed, notifyError, notifySuccess } = useNotifications()
const router = useRouter()

interface Props {
  selectedTabId?: string
  tabs: Tab[]
  tabsType: string
}

const props = withDefaults(defineProps<Props>(), {
  selectedTabId: undefined,
  tabs: () => [],
  tabsType: 'all'
})

const emit = defineEmits([
  'tabCreated',
  'update:selectedTabId',
  'closeTab',
  'cancel',
  'fullScreen'
])

const viewMode = defineModel<string>('viewMode')

const tabsStore = useTabsStore()
const configStore = useConfigStore()
const couriersStore = useCouriersStore()
const catalogStore = useCatalogStore()

const tabs = toRef(props, 'tabs')

const { openTab, closeTab, updateDeliveryOrderStatus, addPayment } = tabsStore
const { getTab, getBills } = storeToRefs(tabsStore)
const { config } = storeToRefs(configStore)
const { couriers } = storeToRefs(couriersStore)
const { catalogs } = storeToRefs(catalogStore)

const tabViewOptions = computed(() => [
  { label: t('tabs.in-progress'), value: 'not-closed' },
  { label: t('tabs.closed'), value: 'closed' }
])

const viewModes = [
  { icon: 'square-menu', value: 'splitScreen' },
  { icon: 'line-menu', value: 'fullScreen' }
]

const { width: windowWidth } = useWindowSize()

const configuringTab = ref({
  delivery: false,
  onSite: false
})
const tabsFilter = ref(tabViewOptions.value[0].value)
const currentTime = ref(new Date())
const recentlyScanned = ref<Record<string, Date>>({})
const recentlyAssigned = ref<Record<string, Date>>({})
const choosingForShipment = ref(false)
const requestShipmentLoading = ref(false)
const pickedTabsForShipment = ref<string[]>([])

const selectAllIsHalfed = computed(() => {
  return (
    pickedTabsForShipment.value.length > 0 &&
    pickedTabsForShipment.value.length <
      filteredNowTabsPickableForShipment.value.length
  )
})

const codeToTabs = computed(() => {
  return openTabs.value.reduce<Record<string, Tab>>((res, tab) => {
    res[tab.code] = tab
    return res
  }, {})
})

const openTabs = computed(() => {
  return tabs.value.filter(tab => tab.open)
})

const filteredNowTabsPickableForShipment = computed(() => {
  return filteredTabs.value.filter(rowIsPickableForShipment)
})

const filteredTabs = computed(() => {
  if (tabsFilter.value === 'not-closed') {
    return filteredNotClosed.value
  }

  return filteredClosed.value
})

const filteredNotClosed = computed(() => {
  return tabs.value
    .filter(tab => {
      return tab.open
    })
    .sort((a, b) => {
      return (
        new Date(
          a.activationTime ? a.activationTime : a.schedulingTime
        ).getTime() -
        new Date(
          b.activationTime ? b.activationTime : b.schedulingTime
        ).getTime()
      )
    })
})

const filteredClosed = computed(() => {
  return tabs.value
    .filter(tab => {
      return !tab.open
    })
    .sort(
      (a, b) =>
        new Date(a.activationTime).getTime() -
        new Date(b.activationTime).getTime()
    )
})

const headers = computed(() => {
  let result = []
  result.push(t('tabs.tab'))
  result.push(t('tabs.time'))
  if (rowSize.value !== 'small') {
    result.push(t('tabs.ordering-time'))
  }
  result.push(t('tabs.delivery-time'))
  result.push(t('tabs.address'))
  result.push(t('tabs.courier'))
  if (rowSize.value === 'large') {
    result.push(t('tabs.phone-number'))
  }
  return result
})

const rowSize = computed(() => {
  let result = 'small'
  if ((windowWidth.value ?? 0) > 1224) {
    result = 'medium'
  }
  if ((windowWidth.value ?? 0) > 1366) {
    result = 'large'
  }
  return result
})

const showCreateTabButton = computed(() => {
  return config?.value?.lastProductPosEnabled
})

const hasCatalog = computed(() => {
  return Object.keys(catalogs.value).length > 0
})

watch(
  () => filteredTabs.value,
  tabs => {
    let tabIds = tabs.map(tab => tab.id)
    if (!props.selectedTabId || !tabIds.includes(props.selectedTabId)) {
      selectFirst()
    }
  }
)

const previousTabId = ref<string>('')
watch(
  () => props.selectedTabId,
  tabId => {
    if (!previousTabId.value) previousTabId.value = tabId
    if (previousTabId.value !== tabId) {
      switchTabViewByTabId(tabId)
      previousTabId.value = tabId
    }
  },
  { immediate: true }
)

onMounted(() => {
  selectFirst()
  currentTime.value = new Date()
  setInterval(() => (currentTime.value = new Date()), 1000)
  barcodeScanner.initialize()
  EventBus.$on('barcodeScanned', (inputString: string) => {
    updateStatus(inputString)
  })
})

onBeforeUnmount(() => {
  barcodeScanner.close()
})

function switchTabViewByTabId(tabId: string): void {
  if (filteredNotClosed.value.map(tab => tab.id).includes(tabId)) {
    tabsFilter.value = 'not-closed'
  } else if (filteredClosed.value.map(tab => tab.id).includes(tabId)) {
    tabsFilter.value = 'closed'
  }
}

function rowIsPickableForShipment(tab: Tab) {
  return (
    tab.pickupType === 'ownDelivery' &&
    tab.deliveryOrder &&
    !tab.deliveryOrder.shipmentId
  )
}

async function requestShipment() {
  if (pickedTabsForShipment.value.length === 0) return
  requestShipmentLoading.value = true

  try {
    await api.post('/tabs/requestShipment', {
      tabIds: pickedTabsForShipment.value
    })
    pickedTabsForShipment.value = []
    choosingForShipment.value = false
    notifySuccess({
      title: t('tabs.shipment-request-done')
    })
  } catch (error) {
    notifyError({
      title: `${t('tabs.shipment-request-failed')}: ${error}`
    })
  }
  requestShipmentLoading.value = false
}

function toggleSelectAll(value: boolean) {
  if (value && !selectAllIsHalfed.value) {
    pickedTabsForShipment.value = filteredNowTabsPickableForShipment.value.map(
      tab => tab.id
    )
  } else if (selectAllIsHalfed.value) {
    pickedTabsForShipment.value = []
  } else {
    pickedTabsForShipment.value = []
  }
}

function shipmentPickToggled(tabId: string, value: boolean) {
  if (value) {
    pickedTabsForShipment.value.push(tabId)
  } else {
    let index = pickedTabsForShipment.value.indexOf(tabId)
    pickedTabsForShipment.value.splice(index, 1)
  }
}

function tabCodeIsNotRecentlyAssigned(tabCode: string) {
  if (!recentlyAssigned.value[tabCode]) {
    return true
  } else if (
    moment().subtract(60, 'second') > moment(recentlyAssigned.value[tabCode])
  ) {
    delete recentlyAssigned.value[tabCode]
    return true
  }
  return false
}

function courierHasOrdersOnDelivery(courier: Courier) {
  return openTabs.value.some(
    tab =>
      tab.deliveryOrder &&
      tab.deliveryOrder.status === 'ON_DELIVERY' &&
      tab.deliveryOrder.courierId === courier.id &&
      tabCodeIsNotRecentlyAssigned(tab.code)
  )
}

function tabIsPaid(tab: Tab) {
  let bills = getBills(tab.id)
  let pending = bills.reduce<number>((sum, bill) => sum + bill.pending, 0)
  return pending === 0
}

async function addPaymentIfNeeded(tab) {
  if (!tabIsPaid(tab)) {
    let amount = getBills(tab.id)
      .flatMap(bill => bill.pending)
      .reduce((sum, billTotal) => sum + billTotal, 0)
    if (
      CashMachine.methods.includes(tab.deliveryOrder.preferredPaymentMethod)
    ) {
      let charged = await CashMachine.charge(amount)
      if (charged === 0) {
        notifyPaymentFailed()
        emit('cancel')
        return
      }
    }
    let paymentData = {
      billId: tab.bills[0],
      amount,
      type: tab.deliveryOrder.preferredPaymentMethod
    }
    addPayment({ tabId: tab.id, ...paymentData })
  }
}

function handleAssignedOrders(courier: Courier) {
  openTabs.value
    .filter(tab => {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.status === 'ON_DELIVERY' &&
        tab.deliveryOrder.courierId === courier.id &&
        tabCodeIsNotRecentlyAssigned(tab.code)
      )
    })
    .forEach(tab => {
      finishOrder(tab)
    })
}

function handleRecentlyScannedForCourier(targetCourier: Courier) {
  Object.keys(recentlyScanned.value).forEach(tabCode => {
    if (
      moment().subtract(10, 'second') < moment(recentlyScanned.value[tabCode])
    ) {
      updateDeliveryOrderStatus({
        tabId: codeToTabs.value[tabCode].id,
        newStatus: 'ON_DELIVERY',
        courier: targetCourier
      })
      recentlyAssigned.value[tabCode] = new Date()
    }
  })
  recentlyScanned.value = {}
}

async function finishOrder(tab: Tab) {
  let isPaid = tabIsPaid(tab)
  if (tab.deliveryOrder.preferredPaymentMethod && !isPaid) {
    await addPaymentIfNeeded(tab)
  } else if (!isPaid) {
    dialog({
      title: t('tabs.payment-method-error'),
      content: `${t('tabs.payment-method-error-text-1')} ${t('tabs.payment-method-error-text-2')}`
    })
  }
  if (isPaid) {
    updateDeliveryOrderStatus({
      tabId: tab.id,
      newStatus: 'DELIVERED'
    })
    closeTab({ tabId: tab.id })
  }
  emit('update:selectedTabId', null)
}

function handleRecentlyScannedForCloseDelivery() {
  Object.keys(recentlyScanned.value).forEach(tabCode => {
    if (
      moment().subtract(10, 'second') < moment(recentlyScanned.value[tabCode])
    ) {
      let tabId = codeToTabs.value[tabCode].id
      let tab = getTab(tabId)
      finishOrder(tab)
    }
  })
  recentlyScanned.value = {}
}

function selectTab(tabId: string | null) {
  previousTabId.value = tabId
  emit('update:selectedTabId', tabId)
}

function setToReadyToPickupIfKitchen(tab) {
  if (tab.deliveryOrder && tab.deliveryOrder.status === 'KITCHEN') {
    updateDeliveryOrderStatus({
      tabId: tab.id,
      newStatus: 'READY_TO_PICKUP'
    })
  }
}

function updateStatus(inputString: string) {
  inputString = inputString.trim()
  let targetCourier = Object.values(couriers.value).find(
    courier => courier.code === inputString
  )
  let targetTab = openTabs.value.find(
    tab => tab.id.slice(-10) === inputString || tab.code === inputString
  )
  if (targetTab) {
    recentlyScanned.value[targetTab.code] = new Date()
    setToReadyToPickupIfKitchen(targetTab)
  } else if (targetCourier) {
    if (
      courierHasOrdersOnDelivery(targetCourier) &&
      Object.keys(recentlyScanned.value).length > 0
    ) {
      dialog({
        title: t('tabs.courier-error-title'),
        content: `${t('tabs.courier-error-text-1')} ${t('tabs.courier-error-text-2')}`
      })
      recentlyScanned.value = {}
      return
    }
    handleAssignedOrders(targetCourier)
    handleRecentlyScannedForCourier(targetCourier)
  } else if (inputString === 'Z0000') {
    handleRecentlyScannedForCloseDelivery()
  }
}

function selectFirst() {
  if (app.isMobile) return
  if (filteredTabs.value.length > 0) {
    selectTab(filteredTabs.value[0].id)
  } else {
    selectTab(null)
  }
}

async function createNewTab() {
  if (props.tabsType === 'takeAway' && !config.value.showTakeAwayTabPopup) {
    let newTab = {
      seats: 0,
      lang: null,
      name: null,
      pickupType: 'takeAway'
    }
    const tabId = await openTab({
      tableId: null,
      tab: newTab
    })
    emit('tabCreated', tabId)
    router.push({ name: 'orderManagement', params: { tabId } })
  } else if (props.tabsType === 'delivery') {
    configuringTab.value.delivery = true
  } else {
    configuringTab.value.onSite = true
  }
}

const fullScreen = computed(() => viewMode.value === 'fullScreen')

watch(
  () => props.tabsType,
  value => {
    if (value != 'delivery') choosingForShipment.value = false
  }
)
</script>
