import { useNotifications } from '@/composables/useNotifications'
import { toRaw } from 'vue'
import i18n from '@/i18n'
import app from '@/app'

class RedsysDataphone {
  constructor(config, platform) {
    this.initialized = false
    this.initializing = false
    this.config = config
    this.platform = platform
  }
  async init() {
    if (this.platform === 'web') {
      this.initialized = true
      return
    }
    if (!app.isElectron) return
    const { ipcRenderer } = window.require('electron')
    this.ipcRenderer = ipcRenderer
    if (!this.initializing) {
      this.initializing = true
      let initResult = await this.ipcRenderer.invoke(
        'dataphone-init',
        toRaw(this.config)
      )
      if (initResult === 0) {
        this.initialized = true
      } else {
        const { notifyError } = useNotifications()
        notifyError({
          title: i18n.global.t('dataphone.bad-config-title'),
          description: i18n.global.t('dataphone.bad-config-subtitle')
        })
      }
    }
    this.initializing = false
  }

  async charge({ amount, paymentId }) {
    if (this.platform === 'web') return { chargeAuthorised: true }
    if (this.initialized) {
      let res = await this.ipcRenderer.invoke(
        'dataphone-charge',
        amount,
        paymentId
      )
      if (!res?.chargeAuthorised) {
        throw new Error('Dataphone charging failed')
      }
      return res?.metadata
    }
  }

  async cancel() {
    return
  }

  async refund(payment) {
    if (this.platform === 'web') return {}
    let res = await this.ipcRenderer.invoke(
      'dataphone-refund',
      JSON.parse(JSON.stringify(payment))
    )
    return res
  }
}

export default RedsysDataphone
