/* eslint-disable no-console */
import type { UserData, Tracker } from './tracker'

export class ConsoleTracker implements Tracker {
  track(eventName: string, eventProperties: Record<string, any>) {
    console.log(`Track: ${eventName}`, eventProperties)
  }

  identify(userData: UserData) {
    console.log('Identify:', userData)
  }
}
