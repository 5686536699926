import { useTabsStore } from '@/store/tabs'

function deleteOldTabs() {
  const tabs = useTabsStore()
  tabs.deleteOldTabs()
}

function updateTabsActivationTime() {
  const tabs = useTabsStore()
  tabs.updateTabsActivationTime()
}

function start() {
  setInterval(
    () => {
      deleteOldTabs()
    },
    60 * 60 * 1000
  )
  setInterval(() => {
    updateTabsActivationTime()
  }, 60 * 1000)
}

export default {
  start
}
