<template>
  <div>
    <div
      class="items-center flex text-n-0 font-heading gap-2"
      :class="{ 'text-xl': size === 'big' }"
    >
      <tab-status :tab-id="tabId" />
      <div v-if="tab.code" class="whitespace-no-wrap">
        <span class="font-bold">{{ tab.code }}</span>
        <span v-if="tab.tableName"> - {{ tab.tableName }}</span>
        <span v-else-if="tab.name"> - {{ tab.name }}</span>
      </div>
      <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
      <div v-else>{{ tab.name }}</div>
    </div>
    <div
      class="tab-source flex text-n-200"
      :class="{
        'text-xs pl-5': size === 'small',
        'text-lg pl-6': size === 'big'
      }"
    >
      {{ tab.source }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTabs } from '@/composables/useTabs'
import TabStatus from './TabStatus.vue'
import { computed } from 'vue'

interface Props {
  tabId: string
  size?: 'small' | 'big'
}
const props = withDefaults(defineProps<Props>(), {
  size: 'small'
})

const tabId = computed(() => props.tabId)

const { tab } = useTabs(tabId)
</script>
