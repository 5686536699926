<template>
  <div
    class="fixed bottom-0 right-0 text-n-0 mr-4 mb-4 pointer-events-auto flex sm:flex-col"
  >
    <tippy
      :interactive="false"
      trigger="click"
      placement="auto"
      :append-to="() => appendTarget"
      :on-shown="e => autoCloseTippy(e)"
    >
      <div
        class="p-3 rounded-l-full sm:rounded-t-full sm:rounded-b-none transition-colors"
        :class="{
          'bg-action pointer-events-none': modelValue === 'time',
          'bg-n-700': modelValue !== 'time'
        }"
        @click="emitModeChange('time')"
      >
        <icon name="time" size="medium" />
      </div>
      <template #content>
        <div class="bg-n-600 relative rounded-md p-2 text-n-400">
          {{ $t('reservations.time') }}
        </div>
      </template>
    </tippy>
    <tippy
      :interactive="false"
      trigger="click"
      placement="auto"
      :append-to="() => appendTarget"
      :on-shown="e => autoCloseTippy(e)"
    >
      <div
        class="p-3 transition-colors cursor-pointer"
        :class="{
          'bg-action pointer-events-none': modelValue === 'diners',
          'bg-n-700': modelValue !== 'diners'
        }"
        @click="emitModeChange('diners')"
      >
        <icon name="diners" size="medium" />
      </div>
      <template #content>
        <div class="bg-n-600 relative rounded-md p-2 text-n-400">
          {{ $t('reservations.diners') }}
        </div>
      </template>
    </tippy>
    <tippy
      :interactive="false"
      trigger="click"
      placement="auto"
      :append-to="() => appendTarget"
      :on-shown="e => autoCloseTippy(e)"
    >
      <div
        class="p-3 rounded-r-full sm:rounded-t-none sm:rounded-b-full transition-colors cursor-pointer"
        :class="{
          'bg-action pointer-events-none': props.modelValue === 'amount',
          'bg-n-700': modelValue !== 'amount'
        }"
        @click="emitModeChange('amount')"
      >
        <icon name="euro" size="medium" />
      </div>
      <template #content>
        <div class="bg-n-600 relative rounded-md p-2 text-n-400">
          {{ $t('end-shift.amount') }}
        </div>
      </template>
    </tippy>

    <tippy
      :interactive="false"
      :arrow="false"
      trigger="click"
      placement="auto-end"
      :append-to="() => appendTarget"
    >
      <div
        class="p-3 rounded-full ml-4 sm:mt-4 sm:ml-0 bg-n-700 cursor-pointer"
      >
        <icon name="status" size="medium" />
      </div>
      <template #content>
        <div class="flex flex-col bg-n-600 rounded-xl px-6 py-6">
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 bg-n-0 rounded-full" />
            {{ $t('floorplan.free') }}
          </div>
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 rounded-full bg-b-500" />
            {{ $t('floorplan.occupied') }}
          </div>
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 rounded-full bg-r-300" />
            {{ $t('floorplan.to-pay') }}
          </div>
          <div class="flex items-center text-n-200">
            <div class="w-4 h-4 mr-2 rounded-full bg-y-500" />
            {{ $t('floorplan.reserved') }}
          </div>
          <template v-if="config.enableKitchenOrders">
            <div class="flex items-center text-n-200 mt-4">
              <div class="w-4 h-4 p-1 mr-2 bg-n-0 rounded-full">
                <div class="w-full h-full mr-2 rounded-full bg-y-500" />
              </div>
              {{ $t('floorplan.not-sent-to-kitchen') }}
            </div>
          </template>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@last/core-ui/paprika'
import { defineProps, defineEmits, withDefaults, ref } from 'vue'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { Tippy } from 'vue-tippy'
import { useTracker } from '@last/core-ui/paprika'

const tracker = useTracker()

const props = withDefaults(
  defineProps<{
    modelValue: 'time' | 'diners' | 'amount'
  }>(),
  { modelValue: 'time' }
)

const emit = defineEmits(['update:modelValue'])

const { config } = storeToRefs(useConfigStore())

function emitModeChange(mode: string) {
  tracker.track(`floorplanMode:${mode}`)
  emit('update:modelValue', mode)
}

function autoCloseTippy(e: any) {
  setTimeout(() => {
    e.hide()
  }, 1000)
}

const appendTarget = ref(document.body)
</script>
