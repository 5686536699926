<template>
  <UpdateModal v-if="visible" @confirm="handleConfirm" />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { init } from './updateVersion'
import UpdateModal from '@/components/UpdateModal.vue'
import { UpdateVersionProps } from './updateVersion'

const visible = ref(false)
const props = ref<UpdateVersionProps | null>(null)

function show(inputProps: UpdateVersionProps) {
  props.value = inputProps
  visible.value = true
}

function handleConfirm() {
  props?.value?.onConfirm()
  visible.value = false
}

init(show)
</script>
