<template>
  <div
    v-for="(payment, index) in payments"
    :key="payment.id"
    class="flex py-2 items-center text-sm text-n-0 font-body"
  >
    <div class="flex-1 capitalize">
      <div>
        {{ payment.type }}
      </div>
      <div v-if="(payment.tip || 0) > 0" class="text-xs">
        {{ $t('checkout.tip') }}
      </div>
    </div>
    <div class="text-right font-bold">
      <div>
        {{ $filters.currency(payment.amount - (payment.tip || 0)) }}
      </div>
      <div v-if="(payment.tip || 0) > 0">
        {{ $filters.currency(payment.tip) }}
      </div>
    </div>
    <l-dropdown
      v-model:open="openOptions[index]"
      :options="getOptionsDataphone(payment.id)"
    >
    </l-dropdown>
    <icon
      v-if="isDataphonePayment(payment.id)"
      name="dots"
      size="medium"
      class="text-v-300 p-1 ml-2 cursor-pointer"
      @click.stop="openOptions[index] = true"
    />
    <icon
      v-else
      name="close"
      size="medium"
      class="text-v-300 ml-3 p-1 cursor-pointer"
      @click="deletePaymentWithConfirmation(payment.id)"
    />
  </div>
  <div v-if="payments.length === 0" class="text-n-0 text-sm py-2">
    {{ $t('checkout.no-payments') }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { Icon, useDialog, LDropdown } from '@last/core-ui/paprika'
import TicketPrinter from '@/ticketPrinter.js'
import { ref } from 'vue'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

const props = defineProps({
  billId: {
    type: String,
    default: null
  },
  paid: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const tabsStore = useTabsStore()
const dialog = useDialog()
const { refundablePaymentMethods, getBillPayments, getPayment } =
  storeToRefs(tabsStore)

const openOptions = ref<boolean[]>([])

function getOptionsDataphone(paymentId: string): Option[] {
  return [
    {
      label: t('checkout.generate-receipt'),
      value: 'generate-receipt',
      onClick: () => generateBankReceipt(paymentId)
    },
    {
      label: t('checkout.refund-payment'),
      value: 'refund-payment',
      onClick: () => deletePaymentWithConfirmation(paymentId)
    }
  ]
}

function getPaymentType(paymentId: string) {
  let currentPayment = getPayment.value(paymentId)
  return currentPayment.type
}

function isDataphonePayment(paymentId: string) {
  let currentPayment = getPayment.value(paymentId)
  if (!currentPayment?.metadata?.receipt) return false
  return currentPayment.type === 'dataphone'
}

function generateBankReceipt(paymentId: string) {
  let payment = getPayment.value(paymentId)
  TicketPrinter.printBankReceipt(payment)
}

function deletePaymentWithConfirmation(paymentId: string) {
  let paymentType = getPaymentType(paymentId)
  dialog({
    title: getDeletePaymentTitleByType(paymentType),
    content: getDeletePaymentContentByType(paymentType),
    onConfirm: () =>
      tabsStore.deletePayment({ billId: props.billId, paymentId })
  })
}

function getDeletePaymentTitleByType(paymentType: string) {
  return refundablePaymentMethods.value.includes(paymentType)
    ? t('checkout.refund-payment')
    : t('checkout.delete-payment')
}

function getDeletePaymentContentByType(paymentType: string) {
  return refundablePaymentMethods.value.includes(paymentType)
    ? t('checkout.refund-payment-content')
    : t('checkout.delete-payment-content')
}

const payments = computed(() => {
  if (!props.billId) return []
  return getBillPayments.value(props.billId) as any[]
})
</script>
