import SocketFactory from '@/socket.js'
import lastUtils from '@last/core/src/lastUtils.js'
import { useNotifications } from '@/composables/useNotifications'
import i18n from '@/i18n'
import { Buffer } from 'buffer'

class Cashlogy {
  init({ ip, port, inputAmountListener, totalAmountListener }) {
    this.config = { ip, port }
    this.enabled = ip && port
    this.inputAmountListener = inputAmountListener
    this.totalAmountListener = totalAmountListener
  }

  sendCommand(command) {
    if (!this.config) throw new Error('Cashlogy without config')
    return new Promise((resolve, reject) => {
      var socket = SocketFactory.getSocket()
      socket.open(
        this.config.ip,
        this.config.port,
        () => {
          socket.onData(response => {
            socket.shutdownWrite(() => {
              resolve(Buffer.from(response).toString())
            }, reject)
          })
          socket.write(Buffer.from(command), () => {}, reject)
        },
        reject
      )
    })
  }

  async sendCommandWithAutoInit(command) {
    let response = await this.sendCommand(command)
    if (response.includes('#ER:ILLEGAL#')) {
      await this.initialize()
      response = await this.sendCommand(command)
    }
    return response
  }

  async initialize() {
    await this.sendCommand('#I#')
  }

  async charge(amount) {
    await this.initialiseAdmission()
    let inputAmount = 0
    while (inputAmount < amount && !this.cancelled) {
      inputAmount = await this.consultCurrentAdmissionAmount()
      this.inputAmountListener(inputAmount)
      await lastUtils.sleep(500)
    }
    let finalAmount = await this.finalizeAdmission()
    if (this.cancelled) {
      this.inputAmountListener(0)
      await this.dispense(finalAmount)
      this.cancelled = false
      return 0
    } else {
      if (finalAmount > amount) {
        try {
          await this.dispense(finalAmount - amount, finalAmount)
        } catch (error) {
          this.inputAmountListener(0)
          return 0
        }
      }
      this.inputAmountListener(0)
      return amount
    }
  }

  async dispense(amount, fallbackAmount = 0) {
    const { notifyError } = useNotifications()
    let command = `#P#${amount}#0#0#0#`
    let response = await this.sendCommandWithAutoInit(command)
    if (response.includes('#ER:GENERIC#')) {
      let changeNotification = {
        title: i18n.global.t('cash-machine-status.insufficient-change'),
        icon: 'close',
        iconColor: 'red'
      }
      if (fallbackAmount) {
        notifyError({
          ...changeNotification,
          description: i18n.global.t('cash-machine-status.refunding-amount')
        })
        await this.dispense(fallbackAmount)
      } else {
        notifyError(changeNotification)
      }
      throw new Error('Insufficient change')
    }
    let parts = response.split('#')
    return parseInt(parts[2])
  }

  async initialiseAdmission() {
    let command = `#B#0#0#0#`
    await this.sendCommandWithAutoInit(command)
  }

  async finalizeAdmission() {
    let command = `#J#`
    let response = await this.sendCommandWithAutoInit(command)
    let parts = response.split('#')
    return parseInt(parts[2])
  }

  async consultCurrentAdmissionAmount() {
    let command = `#Q#`
    let response = await this.sendCommandWithAutoInit(command)
    let parts = response.split('#')
    return parseInt(parts[2])
  }

  async payIn(amount) {
    let command = `#A#2#`
    await this.sendCommandWithAutoInit(command)
    let inputAmount = 0
    while (inputAmount < amount && !this.cancelled) {
      inputAmount = await this.consultCurrentAdmissionAmount()
      this.inputAmountListener(inputAmount)
      await lastUtils.sleep(500)
    }
    this.inputAmountListener(0)
    return await this.finalizeAdmission()
  }

  async payOut(amount) {
    return await this.dispense(amount)
  }

  async close() {
    let command = `#E#`
    let response = await this.sendCommandWithAutoInit(command)
    let parts = response.split('#')
    return parseInt(parts[1])
  }

  async getCurrentAmount() {
    let command = `#T#0#`
    let response = await this.sendCommandWithAutoInit(command)
    let parts = response.split('#')
    try {
      return parseInt(parts[2]) + parseInt(parts[3])
    } catch {
      return 0
    }
  }

  async refreshTotalAmount() {
    this.totalAmountListener(await this.getCurrentAmount())
  }

  async cancel() {
    this.cancelled = true
  }

  async openBackoffice() {
    const { notifyError, notifySuccess } = useNotifications()
    let command = '#G#1#1#1#1#1#1#1#1#1#1#1#1#1#1#1#'
    let response = await this.sendCommandWithAutoInit(command)
    if (response.includes('#ER:')) {
      notifyError({
        title: i18n.global.t('cash-machine-status.backoffice-error'),
        icon: 'close',
        iconColor: 'red'
      })
      throw new Error('Unable to open Cashlogy backoffice')
    }
    notifySuccess({
      title: i18n.global.t('cash-machine-status.backoffice-message')
    })
  }
}

export default new Cashlogy()
