<template>
  <teleport v-if="isActive" to="#appRoot">
    <div v-if="isActive" class="fixed inset-0 bg-n-800">
      <full-screen :title="title" @close="isActive = false">
        <floor-plan-with-navigation
          class="h-full"
          :wiggle-enabled="true"
          :selected-tables="internalSelectedTables"
          :blocked-tables="blockedTables"
          @table-selected="table => addOrDeleteTable(table.id)"
        />

        <template #footer>
          <l-button :disabled="!isButtonActive" @click="save">{{
            buttonText || title
          }}</l-button>
        </template>
      </full-screen>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import FullScreen from '../core/FullScreen.vue'
import { computed, defineModel, onMounted, ref } from 'vue'
import FloorPlanWithNavigation from '@/components/tables/FloorPlanWithNavigation.vue'
import { LButton } from '@last/core-ui/paprika'

type Props = {
  title?: string
  buttonText?: string
  blockedTables?: string[]
  selectedTables?: string[]
  multipleSelect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  buttonText: '',
  blockedTables: () => [],
  selectedTables: () => [],
  multipleSelect: false
})

const emit = defineEmits<{
  tablesSelected: [tables: string[]]
  tableSelected: [tables: string[]]
  close: []
}>()

const isActive = defineModel('isActive')

const internalSelectedTables = ref<string[]>(props.selectedTables)

const isButtonActive = computed(() => {
  return internalSelectedTables.value.length > 0
})

onMounted(() => {
  internalSelectedTables.value = [...props.selectedTables]
})

function addOrDeleteTable(table: string): void {
  const index = internalSelectedTables.value.indexOf(table)
  if (index != -1) {
    internalSelectedTables.value.splice(index, 1)
  } else {
    if (props.multipleSelect) {
      internalSelectedTables.value = [...internalSelectedTables.value, table]
    } else {
      internalSelectedTables.value = [table]
    }
  }
}

function save(): void {
  if (!props.multipleSelect) {
    emit('tableSelected', internalSelectedTables.value[0])
  } else {
    emit('tablesSelected', internalSelectedTables.value)
  }

  isActive.value = false
}
</script>
