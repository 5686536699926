<template>
  <ul>
    <li
      v-for="option in options"
      :key="option.value"
      class="bg-n-600 rounded-xl px-6 py-4 not-last:mb-4 flex flex-row text-n-0 cursor-pointer"
      @click="() => onSelect(option.value)"
    >
      <div class="flex-none mr-3">
        <div
          class="w-[1.375rem] h-[1.375rem] border rounded-full flex items-center pl-[1px]"
          :class="[
            option.value === model ? 'border-g-500 border-2' : 'border-v-300'
          ]"
        >
          <icon v-if="option.value === model" name="check" class="text-g-500" />
        </div>
      </div>
      <div class="flex-1">{{ option.label }}</div>
      <div class="flex-none flex items-center">
        <icon
          :name="typeToIconMap[option.type] || 'printer'"
          class="text-n-0"
        />
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { defineModel } from 'vue'
import { Icon } from '@last/core-ui/paprika'

type Props = {
  options: { value: string; label: string; type: string }[]
}

defineProps<Props>()

const model = defineModel<string>()

const emit = defineEmits(['change'])

const typeToIconMap: { till: string; cashMachine: string; dataphone: string } =
  {
    till: 'printer',
    cashMachine: 'till',
    dataphone: 'device'
  }

function onSelect(value: string): void {
  model.value = value
  emit('change', value)
}
</script>
