<template>
  <zoompinch
    ref="zoompinchRef"
    :min-scale="1"
    :max-scale="6"
    :rotation="false"
    :bounds="true"
    :mouse="false"
    :touch="true"
    :wheel="false"
    :gesture="true"
    :width="580"
    :height="400"
  >
    <template #canvas>
      <div class="relative w-full h-full">
        <table-tab
          v-for="(table, index) of tables"
          :key="table.id"
          :table="table"
          :mode="mode"
          :floorplan-id="floorplanId"
          :grouped-table-names="groupedTableNames[index]"
          :wiggle-enabled="wiggleEnabled"
          :is-selected="selectedTables.includes(table.id)"
          :is-blocked="blockedTables.includes(table.id)"
          @selected-table="selectTable(table)"
          @assign-table="$emit('assignTable', $event)"
          @merge-tabs="$emit('mergeTabs', $event)"
        />
      </div>
    </template>
  </zoompinch>
</template>

<script setup lang="ts">
import TableTab from './Table.vue'
import { useTablesStore } from '@/store/tables'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { Tab, Table } from '@/types'
import zoompinch from '../ZoomPinch/Zoompinch.vue'
import { watch } from 'vue'

type Props = {
  floorplanId: string
  mode?: string
  wiggleEnabled?: boolean
  selectedTables?: string[]
  blockedTables?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'time',
  wiggleEnabled: false,
  selectedTables: () => [],
  blockedTables: () => []
})

const emit = defineEmits(['tableSelected', 'mergeTabs', 'assignTable'])

const tabsStore = useTabsStore()
const tablesStore = useTablesStore()
const configStore = useConfigStore()

const { allTables } = storeToRefs(tablesStore)

const { tabs } = storeToRefs(tabsStore)
const { config } = storeToRefs(configStore)

const zoompinchRef = ref<InstanceType<typeof zoompinch>>()

watch(
  () => zoompinchRef.value?.wrapperBounds,
  () => {
    requestAnimationFrame(() => {
      zoompinchRef.value?.applyTransform(1, [0.5, 0.5], [1, 1])
    })
  }
)

const tables = computed((): Table[] => {
  if (!props.floorplanId) return []
  return allTables.value.filter(
    table => table.floorplanId === props.floorplanId
  )
})

const groupedTableNames = computed<string[][]>(() => {
  return tables.value.map(table => {
    let tab = (tabs.value as Record<string, Tab>)[table.tabIds[0]]
    if (tab?.tables?.length > 1) {
      return allTables.value
        .filter(item => {
          return tab.tables.includes(item.id) && item.id !== table.id
        })
        .map(item => item.name)
    }
    return []
  })
})

function selectTable(table: Table): void {
  if (!config.value.lastProductPosEnabled) return
  if (table.type !== 'wall') {
    emit('tableSelected', table)
  }
}
</script>
