<template>
  <full-screen :title="$t('close-delivery.title')" @close="$router.go(-1)">
    <div class="max-w-[33rem] px-4 m-auto mt-4">
      <div v-if="hasEnabledServices">
        <delivery-selector
          :virtual-brand-deliveries="virtualBrandsClosingTimes"
          :current-time="currentTime"
          show-closed-until
          @close-delivery="
            (brandId, company) => setDeliveryToClose(brandId, company)
          "
          @open-delivery="(brandId, company) => openDelivery(brandId, company)"
          @close-brand="setBrandToClose"
          @open-brand="openBrand"
        />
        <div class="border-t border-n-700">
          <div class="flex flex-row items-center">
            <div class="flex-1">
              <l-button
                type="text"
                class="pl-0 !text-n-0 !hover:text-v-600"
                @click="
                  () =>
                    mainState == 1
                      ? setAllDeliveriesToClose()
                      : openAllDeliveries()
                "
              >
                {{
                  mainState == 1
                    ? $t('close-delivery.close-all')
                    : $t('close-delivery.open-all')
                }}
              </l-button>
            </div>
            <div class="flex-none">
              <l-switch v-model="mainState" :enable-on-partial-state="true" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-12 mb-4 text-n-200 text-center">
        <icon name="bike" class="mx-auto w-10 h-10" />
        <span class="inline-block font-bold font-text my-2 uppercase text-xl">
          {{ $t('close-delivery.no-services-message') }}
        </span>
      </div>
    </div>
    <l-modal
      v-if="closingBrandsSelected"
      :title="$t('close-delivery.subtitle')"
      :button-text="$t('close-delivery.accept')"
      :full-height="false"
      @close="closingBrandsSelected = false"
      @action="startClosing"
    >
      <div class="grid grid-cols-3 gap-2">
        <div
          v-for="(time, index) in Object.values(closingTimes)"
          :key="time.label"
          class="h-14 rounded-lg bg-n-700 text-n-0 flex items-center cursor-pointer justify-center font-body text-sm"
          :class="{
            'bg-v-300 ': index === selectedClosingIndex
          }"
          @click="selectedClosingIndex = index"
        >
          {{ closingTimes[index].label }}
        </div>
      </div>
      <div
        v-if="selectedClosingIndex === 4"
        class="flex items-center justify-between py-3 mt-4"
      >
        <div>{{ $t('close-delivery.custom') }}</div>
        <quantity-selector
          :min="15"
          :quantity="customQuantity"
          :step="15"
          :is-maxed="customQuantity >= 360"
          @update:quantity="
            quantity => {
              if (quantity <= 360) customQuantity = quantity
            }
          "
        >
          <template #default="{ quantity }">
            <div class="w-auto text-center mx-2">
              {{ quantity }} {{ $t('close-delivery.min') }}
            </div>
          </template>
        </quantity-selector>
      </div>
    </l-modal>
  </full-screen>
</template>

<script setup lang="ts">
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import DeliverySelector from '@/components/delivery/DeliverySelector.vue'
import moment from 'moment'
import sync from '@/sync/service'
import { useConfigStore } from '@/store/config'
import { useDeliveryCompaniesStore } from '@/store/deliveryCompanies'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import FullScreen from '../core/FullScreen.vue'
import { LModal, LButton, LSwitch, Icon } from '@last/core-ui/paprika'
import { useNotifications } from '@/composables/useNotifications'
import { useRouter } from 'vue-router'

const { notifySuccess, closeNotificationsSideBar } = useNotifications()
const configStore = useConfigStore()
const deliveryCompaniesStore = useDeliveryCompaniesStore()
const { t } = useI18n()

const { config } = storeToRefs(configStore)
const { virtualBrandsClosingTimes } = storeToRefs(deliveryCompaniesStore)
const router = useRouter()

const selectedBrandAndCompanies = ref<Record<string, string[]>>({})
const closingBrandsSelected = ref(false)
const currentTime = ref(new Date())
const selectedClosingIndex = ref<number | null>(null)
const customQuantity = ref(15)
const interval = ref<number>()

const closingTimes = computed(() => [
  {
    minutes: 30,
    label: t('close-delivery.30-minutes')
  },
  {
    minutes: 60,
    label: t('close-delivery.1-hour')
  },
  {
    minutes: 'untilClosure',
    label: t('close-delivery.until-closure')
  },
  {
    minutes: 'indefinitely',
    label: t('close-delivery.indefinitely')
  },
  {
    label: t('close-delivery.custom')
  }
])

const totalEnabledServices = computed(() => {
  return Object.values(virtualBrandsClosingTimes.value)
    .map(vb => Object.keys(vb.externalDeliveryCompaniesClosingTimes).length)
    .reduce((total, count) => total + count, 0)
})
const hasEnabledServices = computed(() => {
  return totalEnabledServices.value > 0
})

onMounted(() => {
  currentTime.value = new Date()
  interval.value = window.setInterval(
    () => (currentTime.value = new Date()),
    1000
  )
})

onBeforeUnmount(() => {
  window.clearInterval(interval.value)
})

type SwitchModelType = boolean | 0 | 1 | 2
const mainState = computed({
  get: (): SwitchModelType => {
    let generalState: SwitchModelType | undefined
    Object.values(virtualBrandsClosingTimes.value).forEach(brand => {
      const length = Object.values(
        brand.externalDeliveryCompaniesClosingTimes
      ).length
      const times = Object.values(
        brand.externalDeliveryCompaniesClosingTimes
      ).filter(time => {
        return time
      })

      const brandValue =
        times.length === 0 ? 1 : times.length === length ? 0 : 2
      if (typeof generalState === 'undefined') {
        generalState = brandValue
      } else if (generalState !== brandValue) {
        generalState = 2
      }
    })

    return generalState || 0
  },
  set: (val: boolean) => {
    if (val) {
      openAllDeliveries()
    } else {
      setAllDeliveriesToClose()
    }
  }
})

function setAllDeliveriesToClose() {
  virtualBrandsClosingTimes.value.forEach(brand => {
    selectedBrandAndCompanies.value[brand.id] = Object.keys(
      brand.externalDeliveryCompaniesClosingTimes
    )
  })
  closingBrandsSelected.value = true
}

function setBrandToClose(brandId: string) {
  selectedBrandAndCompanies.value[brandId] = Object.keys(
    virtualBrandsClosingTimes.value.find(brand => brand.id === brandId)!
      .externalDeliveryCompaniesClosingTimes
  )
  closingBrandsSelected.value = true
}

function openAllDeliveries(): void {
  let deliveriesToOpen: Record<string, string[]> = {}
  Object.values(virtualBrandsClosingTimes.value).forEach(brand => {
    deliveriesToOpen[brand.id] = Object.keys(
      virtualBrandsClosingTimes.value.find(b => b.id === brand.id)!
        .externalDeliveryCompaniesClosingTimes
    )
  })

  sync.record('openDeliveries', {
    deliveriesToOpen
  })

  notifySuccess({
    title: t('open-delivery.open-all'),
    onAction: () => {
      router.push({ name: 'closeDelivery' })
      closeNotificationsSideBar()
    }
  })
}

function openBrand(brandId: string) {
  let deliveriesToOpen: Record<string, string[]> = {}
  deliveriesToOpen[brandId] = Object.keys(
    virtualBrandsClosingTimes.value.find(brand => brand.id === brandId)!
      .externalDeliveryCompaniesClosingTimes
  )
  sync.record('openDeliveries', {
    deliveriesToOpen
  })
  const brand = virtualBrandsClosingTimes.value.find(
    brand => brand.id === brandId
  )
  const brandName = brand ? brand.brandName : ''

  notifySuccess({
    title: `${t('open-delivery.open-brand')} ${brandName}`,
    onAction: () => {
      router.push({ name: 'closeDelivery' })
      closeNotificationsSideBar()
    }
  })
}

function setDeliveryToClose(brandId: string, company: string) {
  selectedBrandAndCompanies.value[brandId] = [company]
  closingBrandsSelected.value = true
}

function openDelivery(brandId: string, company: string) {
  let deliveriesToOpen: Record<string, string[]> = {}
  deliveriesToOpen[brandId] = [company]
  sync.record('openDeliveries', {
    deliveriesToOpen
  })
  const brand = virtualBrandsClosingTimes.value.find(
    brand => brand.id === brandId
  )
  const brandName = brand ? brand.brandName : ''

  notifySuccess({
    title: `${t('open-delivery.open-delivery')} ${company} ${brandName ? `(${brandName})` : ''}`,
    onAction: () => {
      router.push({ name: 'closeDelivery' })
      closeNotificationsSideBar()
    }
  })
}

function startClosing() {
  if (selectedClosingIndex.value === null) {
    return
  }
  let closingTime =
    closingTimes.value[selectedClosingIndex.value].minutes ||
    customQuantity.value
  let closedUntil
  if (!isNaN(closingTime as number)) {
    closedUntil = moment(new Date()).add(closingTime, 'minutes')
  } else if (closingTime === 'indefinitely') {
    closedUntil = moment(new Date()).add(10, 'year')
  } else {
    let endTime = config.value.workingTime.end.split(':')
    let endHour = parseInt(endTime[0])
    let endMinute = parseInt(endTime[1])
    closedUntil = moment(new Date()).hour(endHour).minute(endMinute)
    if (
      moment(new Date()).hour(endHour).minute(endMinute) < moment(new Date())
    ) {
      closedUntil = closedUntil.add(1, 'day')
    }
  }
  closedUntil = closedUntil.toISOString().split('.')[0] + 'Z'

  showClosingNotifications(new Date(closedUntil))

  sync.record('deliveriesClosed', {
    deliveriesToClose: selectedBrandAndCompanies.value,
    closedUntil
  })

  selectedBrandAndCompanies.value = {}
  closingBrandsSelected.value = false
}

function showClosingNotifications(closedUntil: Date): void {
  const values = Object.values(selectedBrandAndCompanies.value)
  if (values.length > 1) {
    notifySuccess({
      title: t('close-delivery.delivery-closed'),
      description: timeFrom(closedUntil),
      onAction: () => {
        router.push({ name: 'closeDelivery' })
        closeNotificationsSideBar()
      }
    })
  } else if (values.length === 1 && values[0].length > 1) {
    const brandId = Object.keys(selectedBrandAndCompanies.value)[0]
    const brand = virtualBrandsClosingTimes.value.find(
      brand => brand.id === brandId
    )
    const brandName = brand ? brand.brandName : ''

    notifySuccess({
      title: `${t('close-delivery.close-brand')} ${brandName}`,
      description: timeFrom(new Date(closedUntil)),
      onAction: () => {
        router.push({ name: 'closeDelivery' })
        closeNotificationsSideBar()
      }
    })
  } else {
    const brandId = Object.keys(selectedBrandAndCompanies.value)[0]
    const brand = virtualBrandsClosingTimes.value.find(
      brand => brand.id === brandId
    )
    const brandName = brand ? brand.brandName : ''

    notifySuccess({
      title: `${t('close-delivery.close-delivery')}  ${values[0]} (${brandName})`,
      description: timeFrom(new Date(closedUntil)),
      onAction: () => {
        router.push({ name: 'closeDelivery' })
        closeNotificationsSideBar()
      }
    })
  }
}

function timeFrom(date: Date) {
  const momentDate = moment(date)
  const now = moment(currentTime.value)
  if (momentDate.diff(now, 'days') > 365) {
    return t('close-delivery.indefinitely')
  } else {
    return moment(date).from(now)
  }
}
</script>
