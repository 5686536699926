<template>
  <div class="pb-6">
    <h2 class="text-n-0 text-[1.75rem] font-heading font-bold title">
      {{ $t('login.forgot-title') }}
    </h2>
    <div class="text-n-0 w-full text-left text-base">
      <template v-if="componentState === ComponentStates.EMAIL">
        {{ $t('login.forgot-description') }}
      </template>

      <template v-else-if="componentState === ComponentStates.CODE">
        {{ $t('login.forgot-recover-description', { email }) }}
      </template>
    </div>
  </div>

  <template v-if="componentState === ComponentStates.EMAIL">
    <l-field :label="$t('inputs.user-label')">
      <div class="flex-col w-full">
        <l-input
          v-model="email"
          class="border-r-500"
          type="text"
          :wrong-value="error"
          :error-message="$t('login.error-email')"
          :placeholder="$t('inputs.user-placeholder')"
        />
      </div>
    </l-field>
    <div class="flex justify-center flex-col">
      <l-button
        class="w-full"
        size="small"
        :disabled="!email || email === '' || error"
        @click="sendVerificationCode"
      >
        {{ $t('login.forgot-send-code') }}
      </l-button>
      <l-button
        class="w-full pt-4"
        size="small"
        type="text"
        @click="$emit('back')"
      >
        {{ $t('ctas.back') }}
      </l-button>
    </div>
  </template>
  <template v-else-if="componentState === ComponentStates.CODE">
    <l-field :label="$t('login.forgot-verification-code')">
      <l-pin-input
        v-model="verificationCode"
        :length="5"
        :force-uppercase="true"
        :has-error="error"
        :size="pinInputSize"
      />
    </l-field>
    <l-button
      class="w-full"
      size="small"
      :disabled="verificationCode.split('').length !== 5"
      @click="validateVerificationCode"
    >
      {{ $t('login.forgot-verify-code') }}
    </l-button>
    <l-button class="w-full pt-4" size="small" type="text" @click="resendCode">
      {{ $t('login.forgot-resend-code') }}
    </l-button>
  </template>
</template>

<script setup lang="ts">
import { LButton, LField, LInput, LPinInput } from '@last/core-ui/paprika'
import { computed, ref, watch } from 'vue'
import { useAuthStore } from '@/store/auth.js'
import validator from 'validator'
import app from '@/app'

const ComponentStates = {
  EMAIL: 'email',
  CODE: 'code'
} as const

const componentState = ref<
  (typeof ComponentStates)[keyof typeof ComponentStates]
>(ComponentStates.EMAIL)

const authStore = useAuthStore()

const email = ref<string>()
const error = ref<boolean>(false)
const changePasswordToken = ref<string>()
const verificationCode = ref<string>('')

const emit = defineEmits(['back', 'codeVerified'])

watch(email, () => {
  if (email.value && validator.isEmail(email.value)) {
    error.value = false
  }
})

const pinInputSize = computed(() => (app.isMobile ? 'small' : 'medium'))

async function sendVerificationCode(): Promise<void> {
  if (!email.value) {
    return
  }

  if (!validator.isEmail(email.value)) {
    error.value = true
    return
  }

  changePasswordToken.value = await authStore.sendVerificationCode(email.value)

  componentState.value = ComponentStates.CODE
}

function resendCode(): void {
  email.value = ''
  error.value = false
  componentState.value = ComponentStates.EMAIL
}

async function validateVerificationCode(): Promise<void> {
  if (!verificationCode.value || !changePasswordToken.value) {
    error.value = true
    return
  }

  const result = await authStore.validateVerificationCode(
    changePasswordToken.value,
    verificationCode.value
  )

  if (result) {
    emit('codeVerified', changePasswordToken.value)
  } else {
    error.value = true
  }
}
</script>
