<template>
  <teleport v-if="isActive" to="#appRoot">
    <div v-if="isActive" class="fixed inset-0 bg-n-800">
      <full-screen
        :title="$t('floorplan.move-account')"
        @close="isActive = false"
      >
        <floor-plan-with-navigation
          class="h-full"
          :wiggle-enabled="true"
          :selected-tables="selectedTables"
          @table-selected="tableSelected"
        />
        <template #footer>
          <l-button :disabled="!isButtonActive" @click="save">{{
            $t('ctas.save')
          }}</l-button>
        </template>
      </full-screen>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { ref, defineModel, watch, computed } from 'vue'
import { useTabsStore } from '@/store/tabs'
import FloorPlanWithNavigation from '@/components/tables/FloorPlanWithNavigation.vue'
import { Table } from '@/types'
import { LButton } from '@last/core-ui/paprika'
import FullScreen from '@/components/core/FullScreen.vue'

const props = defineProps<{
  tabIds: string[]
}>()

const isActive = defineModel('isActive')

const tabsStore = useTabsStore()
const { assignTables, getTab } = tabsStore

const selectedTables = ref<string[]>([])

const isButtonActive = computed(() => {
  return selectedTables.value.length > 0
})

const currentTabsTables = computed(() =>
  props.tabIds.map(tabId => getTab(tabId).tables).flat()
)

watch(isActive, value => {
  if (value) {
    selectedTables.value = currentTabsTables.value
  }
})

function save() {
  if (props.tabIds.length === 0 || selectedTables.value.length === 0) return
  props.tabIds.forEach(tabId => {
    assignTables({ tabId, tables: selectedTables.value })
  })
  isActive.value = false
}

function tableSelected(table: Table) {
  if (selectedTables.value.includes(table.id)) {
    selectedTables.value = selectedTables.value.filter(id => id !== table.id)
  } else {
    selectedTables.value = [...selectedTables.value, table.id]
  }
}
</script>
