<template>
  <div id="map" class="h-full w-full"></div>
</template>

<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader'
import { onMounted } from 'vue'
import courierImg from '@/assets/map-courier.png'
import pickupImg from '@/assets/map-pickup.png'
import deliveryImg from '@/assets/map-delivery.png'

const props = defineProps<{
  pickups: any[]
  deliveries?: any[]
  courier?: any
}>()

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_KEY
})

const courierIcon = createIcon(courierImg)
const pickupIcon = createIcon(pickupImg)
const deliveryIcon = createIcon(deliveryImg)

onMounted(async () => {
  const { Map } = await loader.importLibrary('maps')
  const { AdvancedMarkerElement } = await loader.importLibrary('marker')

  const map = new Map(document.getElementById('map')!, {
    mapId: '7be4943916e30f6c',
    center: {
      lat: props.pickups[0].latitude,
      lng: props.pickups[0].longitude
    },
    zoom: 16,
    disableDefaultUI: true,
    clickableIcons: false
  })

  const markers = []

  markers.push(
    new AdvancedMarkerElement({
      position: {
        lat: props.pickups[0].latitude,
        lng: props.pickups[0].longitude
      },
      content: courierIcon,
      map
    })
  )

  if (props.courier?.latitude) {
    markers.push(
      new AdvancedMarkerElement({
        position: {
          lat: props.courier.latitude,
          lng: props.courier.longitude
        },
        content: courierIcon,
        map
      })
    )
  }

  props.deliveries?.forEach((item: any) => {
    if (!item.latitude) return
    markers.push(
      new AdvancedMarkerElement({
        position: {
          lat: item.latitude,
          lng: item.longitude
        },
        content: deliveryIcon,
        map
      })
    )
  })

  props.pickups.forEach((item: any) => {
    if (!item.latitude) return
    markers.push(
      new AdvancedMarkerElement({
        position: {
          lat: item.latitude,
          lng: item.longitude
        },
        content: pickupIcon,
        map
      })
    )
  })

  let bounds = new google.maps.LatLngBounds()
  markers.forEach(marker => {
    if (marker.position) {
      bounds.extend(marker.position)
    }
  })
  map.fitBounds(bounds, 0)
})

function createIcon(url: string) {
  const icon = document.createElement('img')
  icon.width = 40
  icon.src = url
  return icon
}
</script>

<style>
#map svg {
  height: auto;
}
</style>
