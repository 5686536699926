import { VirtualBrand } from '@/types/virtualBrands'
import moment from 'moment'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDeliveryCompaniesStore = defineStore(
  'deliveryCompanies',
  () => {
    const virtualBrandsClosingTimes = ref<VirtualBrand[]>([])

    const sortedClosedUntil = computed(() => {
      return virtualBrandsClosingTimes.value
        .flatMap(brand =>
          Object.values(brand.externalDeliveryCompaniesClosingTimes)
        )
        .filter(time => time !== null)
        .sort((a, b) => moment(a).unix() - moment(b).unix())
    })

    const virtualBrandsWithClosedDeliveries = computed(() => {
      return virtualBrandsClosingTimes.value
        .map(brand => {
          return {
            ...brand,
            externalDeliveryCompaniesClosingTimes: Object.entries(
              brand.externalDeliveryCompaniesClosingTimes
            ).reduce(
              (res, [key, value]) => {
                if (value && moment(value).isAfter(moment())) {
                  res[key] = value
                }
                return res
              },
              {} as Record<string, Date>
            )
          }
        })
        .filter(
          brand =>
            Object.values(brand.externalDeliveryCompaniesClosingTimes).length >
            0
        )
    })

    const hasDeliveries = computed(() => {
      return (
        virtualBrandsClosingTimes.value.filter(
          brand =>
            Object.values(brand.externalDeliveryCompaniesClosingTimes).length >
            0
        ).length > 0
      )
    })

    function refreshClosingTimes(newVirtualBrandsClosingTimes: VirtualBrand[]) {
      virtualBrandsClosingTimes.value = newVirtualBrandsClosingTimes
    }

    return {
      virtualBrandsClosingTimes,
      sortedClosedUntil,
      virtualBrandsWithClosedDeliveries,
      refreshClosingTimes,
      hasDeliveries
    }
  }
)
