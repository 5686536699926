import mqtt from '@/sync/mqtt'
import { Network } from '@capacitor/network'
import api from '@/api'
import { useConfigStore } from './config'
import { defineStore } from 'pinia'
import { useNotifications } from '@/composables/useNotifications'
import { computed, ref } from 'vue'

export const useStatusStore = defineStore('status', () => {
  const config = useConfigStore()
  const { notifyError } = useNotifications()

  const networkConnected = ref(false)
  const mqttConnected = ref(false)
  const errorMessages = ref<any[]>([])
  const hasTimeError = ref(false)

  const isConnected = computed(() => {
    return (mqttConnected.value && networkConnected.value) || config.demoMode
  })

  async function updateCurrentStatus() {
    const status = await Network.getStatus()
    networkConnected.value = status.connected
    mqttConnected.value = mqtt.isConnected
    if (networkConnected.value) {
      await checkDeviceTime()
    }
  }

  async function checkDeviceTime() {
    try {
      const {
        data: { time: serverTime }
      } = await api.get('/time')
      const timeDifference = Math.abs(
        new Date(serverTime).getTime() - new Date().getTime()
      )
      hasTimeError.value = timeDifference > 5 * 60 * 1000
    } catch {
      hasTimeError.value = false
    }
  }

  function updateNetworkStatus(connected: boolean) {
    networkConnected.value = connected
  }

  function updateMqttStatus(connected: boolean) {
    mqttConnected.value = connected
  }

  async function refreshErrorMessages(inputErrorMessages?: any[]) {
    errorMessages.value = inputErrorMessages
      ? inputErrorMessages
      : await api.get<any[]>('/error-messages').then(response => response.data)
    for (const errorMessage of errorMessages.value) {
      notifyError({
        title: errorMessage.title,
        description: errorMessage.description,
        important: true
      })
    }
  }

  return {
    networkConnected,
    mqttConnected,
    errorMessages,
    hasTimeError,
    isConnected,
    updateCurrentStatus,
    updateNetworkStatus,
    updateMqttStatus,
    refreshErrorMessages
  }
})
