<template>
  <full-screen :title="$t('reservations.title')" @close="$router.go(-1)">
    <div
      class="w-full mx-auto overflow-y-scroll scrolling-touch h-full sm:flex justify-center"
    >
      <div
        class="py-5 relative flex-1 flex flex-col px-4 w-full max-w-[52.25rem]"
      >
        <div class="w-full flex items-center gap-4">
          <l-search-bar
            v-model="searchQuery"
            size="large"
            :throttle-time="300"
          />
          <div v-if="!tabId" class="hidden sm:flex">
            <l-button
              icon="plus"
              size="medium"
              @click="editingReservation = {}"
            />
          </div>
        </div>
        <transition name="switch" mode="out-in">
          <div v-if="filteredReservations.length" class="w-full">
            <transition-group
              name="reservation-list"
              class="grid grid-cols1 sm:grid-cols-2 gap-4 mt-4 relative"
              tag="div"
              @before-leave="beforeLeave"
            >
              <div
                v-for="reservation in filteredReservations"
                :key="reservation.id"
              >
                <booking-card
                  :reservation="reservation"
                  :link-mode="!!tabId"
                  @link="linkReservation"
                  @edit="editingReservation = reservation"
                  @delete="checkCancelReservation(reservation.id)"
                />
              </div>
            </transition-group>
          </div>
          <empty-case
            v-else
            class="flex-1 align-center justify-center"
            type="empty"
            item-translation-key="reservations.title"
            description="reservations.no-reservations-description"
          />
        </transition>
      </div>
    </div>
    <reservation-editor
      v-if="editingReservation"
      :reservation="editingReservation"
      @close="editingReservation = null"
    />
  </full-screen>
</template>

<script setup lang="ts">
import { LSearchBar, EmptyCase, LButton } from '@last/core-ui/paprika'
import ReservationEditor from '@/components/ReservationEditor.vue'
import BookingCard from './BookingCard.vue'
import lastUtils from '@last/core/src/lastUtils'
import { useReservationsStore } from '@/store/reservations.js'
import { ref, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import type { Reservation } from '@/types'
import { useDialog } from '@last/core-ui/paprika'
import FullScreen from './core/FullScreen.vue'
import { computed } from 'vue'
import { useNotifications } from '@/composables/useNotifications'
import { storeToRefs } from 'pinia'

const reservationsStore = useReservationsStore()

const { cancelReservation, editReservation } = reservationsStore
const { getNearReservations, nearReservations, sortedReservations } =
  storeToRefs(reservationsStore)

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const dialog = useDialog()
const { notifyInfo } = useNotifications()

const editingReservation = ref()
const searchQuery = ref('')
const filteredReservations = ref<Reservation[]>([])
const debouncedSearch = ref(() => {})

const tabId = computed(() => route.query.tabId as string | null)

watch(searchQuery, () => {
  debouncedSearch.value()
})

watch(sortedReservations, () => {
  search()
})

onMounted(() => {
  if (route.query.initialTableId) {
    editingReservation.value = {
      tables: [route.query.initialTableId]
    }
    router.replace({ name: 'reservations' })
  }
  if (route.query.editReservation) {
    editingReservation.value =
      reservationsStore.reservations[route.query.editReservation as string]
    router.replace({ name: 'reservations' })
  }
  debouncedSearch.value = lastUtils.debounce(search, 200)
  search()
})

function linkReservation(reservationId: string) {
  let tabIdAlreadyLinked = Object.values(sortedReservations.value).find(
    reservation => reservation.tabId === tabId.value
  )
  if (tabIdAlreadyLinked) {
    let oldReservation = {
      ...tabIdAlreadyLinked,
      tabId: null
    }
    editReservation(oldReservation)
  }
  let reservation = {
    ...getNearReservations.value[reservationId],
    tabId: tabId.value
  }
  editReservation(reservation)
  notifyInfo({
    title: t('link-reservation.reservation-linked')
  })
  router.go(-1)
}

function checkCancelReservation(reservationId: string) {
  dialog({
    title: t('reservations.delete-reservation'),
    content: t('reservations.delete-reservation-content'),
    icon: 'trash',
    onConfirm: () => {
      cancelReservation(reservationId)
    }
  })
}

function search() {
  const availableReservations = tabId.value
    ? nearReservations.value
    : sortedReservations.value

  if (!searchQuery.value) {
    filteredReservations.value = availableReservations
    return
  }
  const search = searchQuery.value.toLowerCase()
  filteredReservations.value = availableReservations.filter(
    r =>
      lastUtils.isFuzzyMatch(`${r.name} ${r.surname}`.toLowerCase(), search) ||
      lastUtils.isFuzzyMatch(r.phoneNumber, search)
  )
}

function beforeLeave(element: Element) {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.reservation-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.reservation-list-enter-active {
  transition: all 0.4s ease;
}
.reservation-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.reservation-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}
.reservation-list-move {
  transition: all 0.3s ease;
}

.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.switch-enter-active {
  transition: all 0.3s ease;
}
.switch-leave-active {
  transition: all 0.3s ease;
}
</style>
