<template>
  <div class="flex items-center relative">
    <div
      class="rounded-full w-3 h-3"
      :class="{
        'bg-r-300': status === 'billing-started',
        'bg-n-200': status === 'closed',
        'bg-b-500': status === 'open',
        'bg-v-400': status === 'scheduled'
      }"
    />
    <div
      class="rounded-full right-0 -mr-1 w-1.5 h-1.5 absolute"
      :class="{
        'bg-y-500': hasPendingKitchenOrders
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { useTabs } from '@/composables/useTabs'
import { computed } from 'vue'

interface Props {
  tabId: string
}

const props = defineProps<Props>()

const tabId = computed(() => props.tabId)
const { status, hasPendingKitchenOrders } = useTabs(tabId)
</script>
