<template>
  <div
    class="switch-bg rounded-full flex items-center !w-9 !h-[1.125rem]"
    :class="{
      'bg-n-300': modelValue != 1,
      'bg-g-500': modelValue == 1,
      'opacity-50': disabled,
      'cursor-pointer': !disabled
    }"
    @click.stop="emitInput"
  >
    <div
      class="flex justify-center items-center rounded-full bg-white shadow switch-button"
      :class="{
        'active-button': modelValue == 1,
        'half-button': modelValue == 2
      }"
    >
      <div class="flex items-center justify-center !w-4 !h-4">
        <Icon
          :name="iconName"
          size="small"
          class="!w-3 !h-3"
          :class="{
            'text-n-300': modelValue != 1,
            'text-g-500': modelValue == 1
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@last/core-ui/paprika'
import { computed } from 'vue'
type NumberAsValue = 0 | 1 | 2

type Props = {
  modelValue: boolean | NumberAsValue
  disabled?: boolean
  enableOnPartialState?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  enableOnPartialState: false
})

const emit = defineEmits(['update:modelValue'])

const emitInput = () => {
  if (!props.disabled) {
    if (props.enableOnPartialState && props.modelValue == 2) {
      emit('update:modelValue', true)
    } else {
      emit('update:modelValue', !props.modelValue)
    }
  }
}

const iconName = computed(() => {
  if (props.modelValue === 2) {
    return 'minus'
  } else if (props.modelValue == 1) {
    return 'check'
  }

  return 'close'
})
</script>

<style>
.switch-bg {
  transition: all 0.5s;
}

.switch-button {
  transition: all 0.5s;
  margin: 2px;
}

.active-button {
  margin-left: 18px;
}

.half-button {
  margin-left: 10px;
}
</style>
