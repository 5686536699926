import type { Notification, NotificationConstructor } from './types'
import uuid4 from 'uuid/v4'

export const mapNotification = (
  notification: NotificationConstructor
): Notification => {
  return {
    id: uuid4(),
    createdAt: new Date(),
    title: notification.title,
    description: notification.description,
    type: notification.type,
    icon: notification.icon,
    important: notification.important,
    close: notification.close,
    progress: notification.progress,
    onAction: notification.onAction,
    onCancel: notification.onCancel
  }
}
