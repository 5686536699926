<template>
  <div class="h-full flex flex-col">
    <nav-bar
      v-if="floorplanList.length > 1"
      v-model="selectedFloorplan"
      :floorplans="floorplanList"
      :tab-count="tabCount"
      class="flex-none"
    />
    <swiper
      class="flex-1 w-full h-full"
      :index="activeFloorplanIndex"
      :allow-touch-move="!isMobile"
      :touch-start-prevent-default="false"
      @slide-change="selectedFloorplan = floorplanIds[$event]"
    >
      <swiper-slide
        v-for="id in floorplanIds"
        :key="id"
        class="!flex sm:px-20 sm:pb-4"
      >
        <floor-plan
          :floorplan-id="id"
          :mode="mode"
          :wiggle-enabled="wiggleEnabled"
          :selected-tables="selectedTables"
          :blocked-tables="blockedTables"
          @table-selected="$emit('tableSelected', $event)"
          @merge-tabs="$emit('mergeTabs', $event)"
          @assign-table="$emit('assignTable', $event)"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import Swiper from '@/components/tables/Swiper.vue'
import NavBar from '@/components/core/NavBar.vue'
import FloorPlan from '@/components/tables/FloorPlan.vue'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useTablesStore } from '@/store/tables'
import { Tab } from '@/types'
import { useTabsStore } from '@/store/tabs'
import app from '@/app'

type Props = {
  mode?: 'time' | 'diners' | 'amount'
  wiggleEnabled?: boolean
  selectedTables?: string[]
  blockedTables?: string[]
}

withDefaults(defineProps<Props>(), {
  mode: 'time',
  wiggleEnabled: false,
  selectedTables: () => [],
  blockedTables: () => []
})

const tablesStore = useTablesStore()
const tabsStore = useTabsStore()

const { floorplanIds, getTables } = storeToRefs(tablesStore)
const { tabs } = storeToRefs(tabsStore)

defineEmits(['tableSelected', 'mergeTabs', 'assignTable'])

const selectedFloorplan = ref<string>(floorplanIds.value[0])

const floorplanList = computed(() => {
  return (
    floorplanIds.value.map((id: string) => tablesStore.floorplans[id]) ?? []
  )
})

const tabCount = computed(() => {
  const floorplanTabs = floorplanIds.value.map((id: string) => {
    const tabIds: string[] = getTables
      .value(id)
      .flatMap((table: any) => table.tabIds)
      .filter((tabId: string) => tabId)
    return {
      count: new Set(tabIds).size,
      floorplanId: id
    }
  })

  const count = floorplanTabs.reduce(
    (res, value) => {
      res[value.floorplanId] = value.count
      return res
    },
    {} as { [key: string]: number }
  )
  count.all = Object.values(tabs.value as Tab[]).filter(tab => tab.open).length
  return count
})

const isMobile = app.isMobile

const activeFloorplanIndex = computed(() => {
  const floorplanIndex = floorplanIds.value.indexOf(selectedFloorplan.value)
  return Math.max(floorplanIndex, 0)
})
</script>
