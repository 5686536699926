<template>
  <div class="flex py-4 px-4 w-full overflow-x-auto min-w-0 flex-nowrap">
    <l-tabs v-model="selectedFloorplan" class="m-auto" :tabs="tabs" />
  </div>
</template>

<script setup lang="ts">
import { LTabs } from '@last/core-ui/paprika'
import { computed, defineModel } from 'vue'

const props = withDefaults(
  defineProps<{
    floorplans: {
      id: string
      name: string
    }[]
    tabCount: {
      [key: string]: number
    }
  }>(),
  {
    floorplans: () => [],
    tabCount: () => ({})
  }
)

defineEmits(['close'])

const selectedFloorplan = defineModel<string>()

const tabs = computed(() => {
  return props.floorplans.map(floorplan => ({
    id: floorplan.id,
    name: floorplan.name,
    badge:
      props.tabCount[floorplan.id] > 0
        ? props.tabCount[floorplan.id]
        : undefined
  }))
})
</script>
