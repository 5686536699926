<template>
  <div
    v-if="svg"
    class="flex items-center justify-center flex-shrink-0"
    :class="{
      'opacity-50 pointer-events-none': disabled,
      'current-color-fill': !originalColor && svgType === 'fill',
      'current-color-stroke': !originalColor && svgType === 'stroke',
      'w-6 h-6': !small && !big && !giant,
      'w-4 h-4': small,
      'w-10 h-10': big,
      'w-28 h-28': giant
    }"
    v-html="svg"
  />
</template>

<script lang="ts">
const modules = import.meta.glob('../../components/assets/svg/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  big: {
    type: Boolean,
    default: false
  },
  giant: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  originalColor: {
    type: Boolean,
    default: false
  },
  svgType: {
    type: String,
    default: 'fill',
    validator: function (value: string) {
      // The value must match one of these strings
      return ['fill', 'stroke'].indexOf(value) !== -1
    }
  }
})

const svg = computed(() => {
  return modules['../../components/assets/svg/' + props.name + '.svg']
})
</script>

<style>
.current-color-stroke path {
  stroke: currentColor;
}
.current-color-fill path {
  fill: currentColor;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
