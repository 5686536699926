<template>
  <l-field :label="$t('inputs.pin-label')">
    <l-pin-input
      v-model="pin"
      :length="4"
      :size="pinInputSize"
      :has-error="error"
    />
  </l-field>
  <section class="flex">
    <p v-show="error" class="text-error">{{ $t('login.pin-error') }}</p>
  </section>
  <CalculatorKeypad
    :mode="CalculatorKeypadMode.SIMPLE"
    @number="onNumberClicked"
    @action="onActionClicked"
  />
  <l-button
    class="w-full pt-4"
    size="small"
    type="text"
    @click="$emit('disablePIN')"
  >
    {{ $t('login.login-email') }}
  </l-button>
</template>

<script setup lang="ts">
import { LButton, LField, LPinInput } from '@last/core-ui/paprika'
import { computed, ref, watch } from 'vue'
import { useAuthStore } from '@/store/auth'
import CalculatorKeypad from '@/components/Calculator/CalculatorKeypad.vue'
import {
  CalculatorKeypadActions,
  CalculatorKeypadActionsType,
  CalculatorKeypadMode
} from '@/components/Calculator/CalculatorUtils.js'
import app from '@/app'

const authStore = useAuthStore()

const pin = ref<string>('')
const loggingIn = ref<boolean>(false)
const error = ref<boolean>(false)

defineEmits(['disablePIN'])

const isComplete = computed(() => pin.value.length === 4)

watch(pin, () => {
  if (isComplete.value) {
    startLogin()
  }
})

const pinInputSize = computed(() => (app.isMobile ? 'small' : 'medium'))

async function startLogin() {
  if (!isComplete.value) return
  loggingIn.value = true
  try {
    await authStore.login({ pin: pin.value })
  } catch (e) {
    error.value = true
  }
  loggingIn.value = false
}

function onNumberClicked(num: number): void {
  pin.value += num
}

function onActionClicked(action: CalculatorKeypadActionsType): void {
  if (action === CalculatorKeypadActions.CLEAR) {
    pin.value = ''
    return
  }

  if (action === CalculatorKeypadActions.BACKSPACE) {
    pin.value = pin.value.slice(0, -1)
    return
  }
}
</script>

<style scoped></style>
