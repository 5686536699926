<template>
  <div
    v-use-longpress.disableMouse
    class="flex-col rounded-lg overflow-hidden items-center justify-center text-center cursor-pointer border border-n-600"
    :class="{
      'border-v-300': selectedQuantity > 0,
      'opacity-60 pointer-events-none': disabled
    }"
    @longpress="showContextMenu"
    @contextmenu.prevent="showContextMenu"
    @click="$emit('selected', product, 1)"
  >
    <div
      class="font-heading text-xl sm:text-2xl font-bold w-full h-16 bg-n-600 flex items-center justify-center relative"
      :style="{
        background: background,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderColor: borderColor
      }"
    >
      <div
        v-if="!hasBackgroundImage"
        :style="{
          color: getTextColor(product.color)
        }"
        class="font-bold font-text uppercase"
      >
        {{ shortName }}
      </div>

      <l-badge
        v-if="selectedQuantity > 0"
        class="absolute top-2 right-2"
        size="large"
        mode="primary"
        :value="selectedQuantity"
      />
    </div>
    <div
      class="bg-n-700 py-2 px-3 h-10 sm:h-16 flex items-center justify-center w-full text-center text-n-0 font-body text-xs sm:text-sm"
    >
      <div class="line-clamp-2">
        {{ product.name }}
      </div>
    </div>
    <quantity-selector-modal
      :visible="showQuantitySelector"
      :label="product.name"
      @close="hideContextMenu"
      @execute="
        quantity => {
          $emit('selected', product, quantity)
          showQuantitySelector = false
        }
      "
    />
  </div>
</template>

<script lang="ts" setup>
import images from '@/images'
import { LBadge } from '@last/core-ui/paprika'
import utils from '@last/core/src/lastUtils'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { withDefaults, defineProps, defineEmits, computed, ref } from 'vue'
import { CatalogProduct } from '@/types'
import QuantitySelectorModal from '@/components/ordering/QuantitySelectorModal.vue'

const props = withDefaults(
  defineProps<{
    product: CatalogProduct
    componentStyle: string
    selectedQuantity: number
    disabled?: boolean
  }>(),
  {
    componentStyle: 'square',
    selectedQuantity: 0,
    disabled: false
  }
)

defineEmits(['selected'])

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)
const showQuantitySelector = ref<boolean>(false)

const shortName = computed(() => {
  if (props.product.shortName) {
    return props.product.shortName
  }
  if (!props.product.name) return ''
  let initials = props.product.name
    .split(/[.,/ -]/)
    .map(n => n[0])
    .join('')
    .slice(0, 2)
  if (initials.length < 2) {
    return props.product.name.slice(0, 2)
  } else {
    return initials
  }
})

const background = computed(() => {
  if (config.value.showProductImages && !!props.product.imageId) {
    let image = images.getImageUrl(props.product.imageId)
    return `url(${image})`
  } else {
    return props.product.color || '#3E3E61'
  }
})

const hasBackgroundImage = computed(() => {
  return background.value.includes('url(', 0)
})

const borderColor = computed(() => {
  return utils.darken(props.product.color)
})

function getTextColor(hexcolor: string | null) {
  if (!hexcolor) return 'white'
  let color = hexcolor.substr(1, 6)
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 200 ? '#302F66' : 'white'
}

function showContextMenu(): void {
  showQuantitySelector.value = true
}

function hideContextMenu(): void {
  showQuantitySelector.value = false
}
</script>
