<template>
  <div></div>
</template>

<script setup lang="ts">
import api from '@/api'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const { supportLogin } = useAuthStore()
const route = useRoute()
const router = useRouter()

onMounted(async () => {
  let isSupport = await supportLogin({
    accessToken: route.query.supportToken,
    selectedOrganizationId: route.query.organizationId,
    selectedLocationId: route.query.locationId
  })
  if (isSupport) {
    let locations = (await api.get('/locations'))?.data
    let currentLocation = locations.find(
      (l: any) => l.id === route.query.locationId
    )
    if (currentLocation) {
      let auth = useAuthStore()
      auth.selectLocation(currentLocation)
      router.push({ name: 'employees' })
    } else {
      router.push({ name: 'locations' })
    }
  } else {
    router.push({ name: 'login' })
  }
})
</script>
