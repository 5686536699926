<template>
  <swiper-container
    ref="swiper"
    :initial-slide="index"
    :allow-touch-move="allowTouchMove"
    @swiperslidechange="onSlideChange"
  >
    <slot />
  </swiper-container>
</template>

<script setup lang="ts">
import Swiper from 'swiper'
import { watch } from 'vue'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    index: number
    allowTouchMove?: boolean
  }>(),
  {
    allowTouchMove: true
  }
)
const emit = defineEmits<{
  (e: 'slideChange', index: number): void
}>()

const swiper = ref<HTMLElement & { swiper: Swiper }>()

function onSlideChange(event: CustomEvent) {
  emit('slideChange', event.detail[0].activeIndex)
}

watch(
  () => props.index,
  index => {
    swiper.value?.swiper.slideTo(index)
  }
)
</script>
