<template>
  <l-modal
    :title="$t('tabs.cancel-shipment-question')"
    :description="
      courier
        ? $t('tabs.shipment-with-courier')
        : $t('tabs.shipment-without-courier')
    "
    :button-text="$t('tabs.cancel-shipment')"
    :button-enabled="!loading"
    @action="cancelDelivery"
    @close="$emit('close')"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import api from '@/api'
import { useNotifications } from '@/composables/useNotifications'
import { LModal } from '@last/core-ui/paprika'
import type { Courier } from '@/types'
import { useI18n } from 'vue-i18n'

type Props = {
  tabId: string
  courier: Courier
}
const props = defineProps<Props>()

const { t } = useI18n()

const { notifySuccess, notifyError } = useNotifications()
const loading = ref<boolean>(false)

const emit = defineEmits(['close'])

async function cancelDelivery() {
  if (loading.value) return
  loading.value = true
  try {
    await api.post(`/tabs/${props.tabId}/shipment/cancel`, {})
    notifySuccess({
      title: t('tabs.shipment-cancel-success')
    })
    emit('close')
  } catch (error: any) {
    notifyError({
      title: `${t('tabs.shipment-cancel-failed')}: ${error.message}`
    })
  }
  loading.value = false
  emit('close')
}
</script>
