import { useTabsStore } from '@/store/tabs'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { computed, MaybeRefOrGetter, toRef } from 'vue'
import { TabProduct } from '@/types'
import { useI18n } from 'vue-i18n'
import { managerConfirmation } from '@/plugins/managerConfirmation'
import { useDialog } from '@last/core-ui/paprika'

export default function useProducts(
  mixedProduct: MaybeRefOrGetter<TabProduct>
) {
  const tabsStore = useTabsStore()
  const authStore = useAuthStore()
  const { t } = useI18n()

  const dialog = useDialog()

  const { updateProductQuantity, removeProduct, getSentToKitchenProducts } =
    tabsStore
  const { tabs, products } = storeToRefs(tabsStore)
  const { askPinForPrivilege } = authStore

  const product = toRef(mixedProduct)

  const storeProduct = computed(() => products.value[product.value.id])

  const deleteProductWithoutConfirmation = (deleteSingleUnit = false) => {
    if (product.value.quantity > 1 && deleteSingleUnit) {
      updateProductQuantity({
        productId: product.value.id,
        quantity: product.value.quantity - 1
      })
    } else {
      removeProduct(product.value.id)
    }
  }

  const deleteProductWithConfirmation = (deleteSingleUnit?: boolean) => {
    const privilege = requiredPrivilege.value
    if (privilege && askPinForPrivilege(privilege.privilege)) {
      dialog({
        title: t('delete-product.title'),
        content: requiredPrivilege.value.message,
        mainLabel: t('delete-product.enter-pin'),
        icon: 'trash',
        onConfirm: async () => {
          const hasPermission = await managerConfirmation(privilege.privilege)
          if (hasPermission) {
            deleteProductWithoutConfirmation(deleteSingleUnit)
            return true
          }
        }
      })
    } else {
      deleteProductWithoutConfirmation(deleteSingleUnit)
      return true
    }
  }

  const hasBeenSentToKitchen = computed(() => {
    return !!getSentToKitchenProducts(product.value.tab).find(
      p => p.productId === product.value.id
    )
  })

  const requiredPrivilege = computed(() => {
    if (tabs.value[product.value.tab].billingStartedTime) {
      return {
        privilege: 'BILLED_PRODUCT_REMOVAL',
        message: t('delete-product.billed-message')
      }
    } else if (hasBeenSentToKitchen.value) {
      return {
        privilege: 'KITCHEN_PRODUCT_REMOVAL',
        message: t('delete-product.ordered-message')
      }
    }
    return false
  })

  const description = computed(() => {
    return [...(product.value.comboProducts || product.value.modifiers || [])]
      .sort((a, b) => a.position - b.position)
      .map(modifier => {
        const quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
        return modifier.name + quantity
      })
      .join(', ')
  })

  const productIsPaid = computed(() => {
    const productsPendingToBePayed =
      product.value.discountType === '2x1'
        ? product.value.notPaidQuantity - product.value.discountAmount!
        : product.value.notPaidQuantity
    const isA2x1Product =
      product.value.discountType === '2x1' &&
      product.value.quantity === product.value.discountAmount
    return productsPendingToBePayed === 0 && !isA2x1Product
  })

  const productIsBilled = computed(() => {
    const tab = getProductTab()
    const isDelivery =
      tab.pickupType && ['delivery', 'ownDelivery'].includes(tab.pickupType)
    const productsPendingToBeBilled =
      product.value.discountType === '2x1'
        ? product.value.notBilledQuantity - product.value.discountAmount!
        : product.value.notBilledQuantity
    const isA2x1Product =
      product.value.discountType === '2x1' &&
      product.value.quantity === product.value.discountAmount
    return productsPendingToBeBilled === 0 && !isA2x1Product && !isDelivery
  })

  const getProductTab = () => {
    const tabId = storeProduct.value.tab
    return tabs.value[tabId]
  }

  return {
    deleteProductWithConfirmation,
    description,
    getProductTab,
    productIsPaid,
    productIsBilled
  }
}
