import sync from '@/sync/service'
import { normalize, schema } from 'normalizr'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { useConfigStore } from './config'
import { defineStore } from 'pinia'
import { Reservation, Reservations, Table } from '@/types'
import { computed, ref } from 'vue'

const moment = extendMoment(Moment as any)

const reservationSchema = new schema.Entity('reservations')

export const useReservationsStore = defineStore(
  'reservations',
  () => {
    const reservations = ref<Reservations>({})

    const getNearReservations = computed(() => {
      const range = moment.range(
        moment().subtract(240, 'minutes'),
        moment().add(240, 'minutes')
      )

      return Object.entries(reservations.value).reduce(
        (res: Record<string, Reservation>, [reservationId, reservation]) => {
          if (range.contains(moment(reservation.dateTime))) {
            res[reservationId] = reservation
          }
          return res
        },
        {}
      )
    })

    const sortedReservations = computed(() => {
      return Object.values(reservations.value)
        .sort(
          (a, b) =>
            new Date(a.dateTime!).valueOf() - new Date(b.dateTime!).valueOf()
        )
        .filter(
          reservation =>
            moment(reservation.dateTime) > moment().subtract(1, 'hour') &&
            !reservation.cancelled
        )
    })

    const nearReservations = computed(() => {
      const range = moment.range(
        moment().subtract(240, 'minutes'),
        moment().add(240, 'minutes')
      )

      return Object.values(reservations.value).filter(reservation =>
        range.contains(moment(reservation.dateTime))
      )
    })

    const getReservation = computed(() => (tableId: string, date?: Date) => {
      const config = useConfigStore()
      const range = moment.range(
        moment(date ?? new Date()).subtract(15, 'minutes'),
        moment(date ?? new Date()).add(config.reservations.duration, 'minutes')
      )

      return Object.values(reservations.value).find(reservation => {
        const inRange = range.contains(moment(reservation.dateTime))
        const hasTab = reservation.tabId
        const rightTable = reservation.tables?.includes(tableId)

        return !hasTab && rightTable && inRange
      })
    })

    function createReservation(reservation: Reservation): void {
      sync.record('reservationCreated', reservation)
    }

    function editReservation(reservation: Reservation): void {
      sync.record('reservationChanged', reservation)
    }

    function changeReservationTable({
      reservationId,
      tables
    }: {
      reservationId: string
      tables: Table[]
    }): void {
      sync.record('reservationTableChanged', { reservationId, tables })
    }

    function cancelReservation(reservationId: string): void {
      sync.record('reservationCancelled', { reservationId })
    }

    function refreshReservations(inputReservations: Reservations): void {
      const entities = normalize(inputReservations, [
        reservationSchema
      ]).entities
      reservations.value = entities.reservations || {}
    }

    return {
      reservations,
      getNearReservations,
      nearReservations,
      getReservation,
      sortedReservations,
      createReservation,
      editReservation,
      changeReservationTable,
      cancelReservation,
      refreshReservations
    }
  },
  {
    persist: true
  }
)
