<template>
  <div v-if="model">
    <div>
      <l-field :label="$t('generate-invoice.tax-id')" mandatory>
        <l-input v-model="model.taxId" />
      </l-field>
      <l-field :label="$t('generate-invoice.name')" mandatory>
        <l-input v-model="model.name" />
      </l-field>
    </div>
    <div>
      <l-field :label="$t('generate-invoice.address')" mandatory>
        <l-input v-model="model.address" />
      </l-field>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NewCompany } from './types'
import { onMounted, defineModel } from 'vue'
import { v4 as uuid } from 'uuid'
import { LField, LInput } from '@last/core-ui/paprika'

const cleanCompany: NewCompany = {
  id: uuid()
}

const model = defineModel<NewCompany>()

onMounted(() => {
  model.value = {
    ...cleanCompany,
    ...model.value
  }
})
</script>
