<template>
  <l-modal
    :title="$t('new-tab.new-tab-delivery')"
    :button-text="$t('new-delivery.cta-create')"
    :button-enabled="isDeliveryComplete"
    @close="$emit('close')"
    @action="next"
  >
    <l-field :label="$t('new-delivery.client-phone-label')">
      <l-phone-input
        v-model="internalCustomer.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
        :wrong-value="!validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>

    <l-field :label="$t('new-delivery.select-virtual-brand')">
      <l-select
        v-model="internalTab.virtualBrandId"
        :options="enabledBrands"
        option-label="name"
        option-value="id"
      />
    </l-field>

    <l-field :label="$t('new-delivery.select-enterprise-label')">
      <l-select v-model="internalTab.source" :options="deliveryCompanies" />
    </l-field>

    <l-option-selector
      v-if="internalTab.source !== 'OwnDelivery'"
      v-model="internalTab.pickupType"
      class="mb-4"
      :options="pickupTypeOptions"
    />

    <l-field :label="$t('new-delivery.client-name-label')">
      <l-input
        v-model="internalCustomer.name"
        :placeholder="$t('new-delivery.client-name-placeholder')"
      />
    </l-field>

    <div class="flex gap-4">
      <l-field class="flex-1" :label="$t('new-delivery.order-id-label')">
        <l-input
          v-model="internalTab.name"
          :placeholder="$t('new-delivery.order-id-placeholder')"
        />
      </l-field>

      <l-field class="flex-1" :label="$t('new-delivery.pickup-label')">
        <day-time-picker
          v-model="internalTab.schedulingTime"
          :placeholder="$t('new-delivery.schedule-now')"
          :timezone="config.workingTime.timezone"
        />
      </l-field>
    </div>

    <l-field :label="$t('new-delivery.courier-name-label')">
      <l-input
        v-model="internalTab.courierName"
        :placeholder="$t('new-delivery.courier-name-placeholder')"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import {
  LModal,
  LField,
  LInput,
  LSelect,
  LOptionSelector,
  DayTimePicker,
  LPhoneInput
} from '@last/core-ui/paprika'
import { ref } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const props = defineProps({
  customer: {
    type: Object,
    required: true
  },
  tab: {
    type: Object,
    required: true
  },
  deliveryCompanies: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['close', 'nextStep', 'update:tab'])

const configStore = useConfigStore()
const tabsStore = useTabsStore()
const { t } = useI18n()
const router = useRouter()

const { config } = storeToRefs(configStore)
const { openTab } = tabsStore
const { virtualBrands } = storeToRefs(configStore)

const internalTab = ref<any>({})
const internalCustomer = ref<any>({})
const pickupTypeOptions = computed(() => [
  {
    label: t('new-delivery.pickup-type-own-delivery'),
    value: 'ownDelivery'
  },
  {
    label: t('new-delivery.pickup-type-delivery'),
    value: 'delivery'
  }
])

const isDeliveryComplete = computed(() => {
  return !!internalTab.value.source && !!internalTab.value.name
})

const enabledBrands = computed(() => {
  return virtualBrands.value
    .filter(virtualBrand => virtualBrand.enabled)
    .map(virtualBrand => ({
      label: virtualBrand.name,
      value: virtualBrand.id
    }))
})

const validPhoneNumber = computed(() => {
  let parsed = parsePhoneNumberFromString(
    internalCustomer.value.phoneNumber || ''
  )
  return parsed?.isValid()
})

onMounted(() => {
  internalTab.value = props.tab
  internalCustomer.value = props.customer
  internalTab.value.id = uuid()
  internalTab.value.pickupType = 'delivery'
})

function next() {
  if (!isDeliveryComplete.value) return
  if (internalTab.value.pickupType === 'ownDelivery') {
    emit('nextStep', {
      customer: internalCustomer.value,
      pickupType: 'ownDelivery'
    })
  } else {
    let { courierName, pickupTime, ...tab } = internalTab.value
    tab.deliveryOrder = {
      courierName,
      pickupTime,
      deliveryOrderStatuses: [
        {
          status: 'CREATED',
          creationTime: new Date()
        }
      ]
    }
    tab.customerInfo = internalCustomer.value
    tab.pickupType = internalTab.value.pickupType || 'delivery'
    openTab({ tableId: null, tab })
    emit('close')
    router.push({
      name: 'orderManagement',
      params: { tabId: internalTab.value.id }
    })
  }
}

watch(internalTab.value.pickupType, () => {
  emit('update:tab', internalTab)
})
</script>
