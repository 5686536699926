<template>
  <div class="w-full" @click="focusInput">
    <div
      :class="[
        ...errorClasses,
        ...focusClasses,
        ...disabledClasses,
        ...backgroundColors,
        ...textColors,
        ...sizeClasses
      ]"
      class="w-full flex items-center rounded-xl overflow-hidden box-border border border-transparent"
    >
      <slot name="left"></slot>
      <textarea
        v-if="type === 'textarea'"
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none px-5 py-3 w-full leading-tight bg-transparent"
        :class="[...placeholderClasses, ...sizeClasses]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
        @keyup.enter="$emit('enter')"
      />
      <input
        v-else
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none px-5 h-full w-full leading-tight bg-transparent"
        :class="[...placeholderClasses]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
        :type="type"
        @keyup.enter="$emit('enter')"
        @input="$emit('input', $event)"
      />
      <div
        v-if="helperText"
        class="flex items-center justify-center h-9 text-sm capitalize text-nowrap text-primary flex-row pr-5 pointer-events-none"
      >
        {{ helperText }}
      </div>
      <slot name="right"></slot>
    </div>
    <div v-if="errorMessage && wrongValue" class="text-sm text-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, watch, ref, defineModel } from 'vue'
import { type InputProps } from './inputTypes'
import type { Size } from '../types'
import {
  useErrorClasses,
  usePlaceholderClasses,
  useSizeClasses,
  useFocusClasses,
  useDisabledClasses,
  useBackgroundColorsClasses,
  useTextColorsClasses
} from '../classes'

const props = withDefaults(defineProps<InputProps>(), {
  placeholder: '',
  icon: '',
  errorMessage: '',
  size: 'small',
  iconPosition: 'right',
  maxLength: 255,
  autocomplete: 'off',
  wrongValue: false,
  charset: 'utf-8',
  readOnly: false
})

defineEmits(['enter', 'input'])
const input = ref<HTMLInputElement>()

const errorClasses = computed(() => useErrorClasses(props.wrongValue))
const placeholderClasses = computed(() => usePlaceholderClasses(props.size))
const focusClasses = computed(() => useFocusClasses())
const disabledClasses = computed(() => useDisabledClasses(props.disabled))
const backgroundColors = computed(() => useBackgroundColorsClasses())
const textColors = computed(() => useTextColorsClasses())
const sizeClasses = computed(() => {
  return props.type === 'textarea'
    ? useTextAreaSizeClasses(props.size)
    : useSizeClasses(props.size)
})

const modelValue = defineModel<string | number | null>({ default: null })

watch(modelValue, value => {
  if (
    typeof value === 'string' &&
    props.charset === 'ascii' &&
    new RegExp(/[^\x00-\x7F]/g).test(value)
  ) {
    modelValue.value = value.replace(/[^\x00-\x7F]/g, '')
  }
})

function useTextAreaSizeClasses(size: Size) {
  switch (size) {
    case 'small':
      return ['text-xs', 'h-16']
    case 'medium':
      return ['text-sm', 'h-20']
    case 'large':
      return ['text-base', 'h-24']
    default:
      return []
  }
}

function focusInput() {
  input.value?.focus()
}

defineExpose({ focusInput, inputRef: input })
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
