<template>
  <div>
    <teleport v-if="visible" to="#appRoot">
      <div
        class="screen-w screen-h fixed inset-0 bg-n-900/80 backdrop-blur-sm flex justify-center items-center p-4"
        @click.stop="$emit('close')"
        @contextmenu.stop.prevent="() => undefined"
      >
        <div
          class="p-4 rounded-xl min-w-full sm:min-w-80 bg-n-800 sm:h-96 flex flex-col"
          @click.stop="() => undefined"
          @contextmenu.stop.prevent="() => undefined"
        >
          <calculator
            class="h-full"
            display-mode="quantity"
            keypad-mode="quantity"
            :label="label"
            @change="calculatorValue = $event.toPay"
          />
          <l-button
            class="mt-4 w-full"
            @click="$emit('execute', calculatorValue)"
          >
            {{ buttonText }}
          </l-button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import Calculator from '@/components/Calculator/Calculator.vue'
import { LButton } from '@last/core-ui/paprika'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'

type Props = {
  visible?: boolean
  label?: string
}

const props = withDefaults(defineProps<Props>(), {
  visible: false,
  label: ''
})

const { t } = useI18n()
const calculatorValue = ref<number>(0)
defineEmits(['close', 'execute'])

const buttonText = computed(() => {
  return `${t('ordering.add')} ${calculatorValue.value} ${props.label}`
})
</script>
