<template>
  <div>
    <div class="flex justify-between items-center h-9 mb-4">
      <div class="font-heading font-medium text-base">
        {{ $t('new-delivery.delivery-address-title') }}
      </div>
      <l-button
        v-if="!formAddress && !hideNewAddressCta"
        size="small"
        icon="plus"
        @click="formAddress = {}"
      />
    </div>

    <div v-if="formAddress">
      <l-field
        class="flex-1"
        size="small"
        :label="$t('new-delivery.client-address-label')"
        mandatory
      >
        <l-input
          ref="addressInputRef"
          v-model="formAddress.address"
          :wrong-value="addressMissing"
          :placeholder="$t('new-delivery.client-address-label-placeholder')"
        />
      </l-field>

      <l-field
        class="flex-1 l-field"
        :label="$t('new-delivery.client-address-details-label')"
      >
        <l-input
          v-model="formAddress.details"
          size="small"
          :placeholder="$t('new-delivery.client-address-details-placeholder')"
        />
      </l-field>
      <div class="flex justify-end gap-2">
        <l-button
          v-if="internalAddresses.length > 1 && formAddress.id"
          size="small"
          class="w-32"
          type="text"
          :disabled="!isComplete"
          @click="deleteAddress"
        >
          {{ $t('ctas.delete') }}
        </l-button>
        <l-button
          v-if="internalAddresses.length > 0"
          size="small"
          class="w-32"
          type="secondary"
          @click="formAddress = undefined"
        >
          {{ $t('ctas.cancel') }}
        </l-button>
        <l-button
          size="small"
          class="w-32"
          :disabled="!isComplete"
          @click="saveFormAddress"
        >
          {{ $t('ctas.apply') }}
        </l-button>
      </div>
    </div>

    <div v-else class="flex flex-col gap-4">
      <div
        v-for="address in internalAddresses"
        :key="address.id"
        class="flex justify-between p-4 bg-n-700 border border-n-600 rounded-xl"
        :class="{
          'border-v-300': address.selected
        }"
        @click="setSelectedAddress(address)"
      >
        <div class="mr-1">
          <icon name="map-pin" class="text-n-0" />
        </div>
        <div class="flex-1 flex flex-col">
          <div class="font-body text-n-0 font-normal text-sm">
            {{ address.address }}
          </div>
          <div class="font-body text-sm text-n-300">
            {{ address.details }}
          </div>
        </div>
        <div class="ml-2">
          <icon
            class="text-v-300 cursor-pointer"
            name="pen"
            @click.stop="formAddress = address"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { LInput, LField, Icon, LButton } from '@last/core-ui/paprika'
import { onMounted } from 'vue'
import { computed } from 'vue'
import { ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import { ComponentInstance } from 'vue'
import { watch } from 'vue'
import { Address } from '@/types'

type Props = {
  modelValue?: Address[]
  hideNewAddressCta: boolean
  addressMissing: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  hideNewAddressCta: false,
  addressMissing: false
})

const emit = defineEmits(['update:modelValue', 'deliveryAddressSelected'])

const addressInputRef = ref<ComponentInstance<typeof LInput>>()
const selectedAddress = ref<Address>()
const formAddress = ref<Partial<Address>>()
const internalAddresses = ref<Address[]>([])
const customerId = ref<string>()

watch(formAddress, () => {
  if (formAddress.value) {
    initSearchAutocomplete()
  }
})

onMounted(() => {
  if (props.modelValue.length === 0) {
    initSearchAutocomplete()
    formAddress.value = {}
    return
  }
  customerId.value = props.modelValue[0].customerId
  internalAddresses.value = props.modelValue
  setSelectedAddress(
    props.modelValue.find(address => address.selected) || props.modelValue[0]
  )
})

async function initSearchAutocomplete() {
  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_KEY
  })
  const { Autocomplete } = await loader.importLibrary('places')
  const searchbox = new Autocomplete(addressInputRef.value!.inputRef!, {
    componentRestrictions: {
      country: ['es', 'ad', 'de']
    },
    fields: ['formatted_address', 'address_component', 'geometry']
  })
  searchbox.addListener('place_changed', () => {
    setPlace(searchbox.getPlace())
  })
}

const isComplete = computed(() => {
  return !!formAddress.value?.postalCode && !!formAddress.value?.address
})

function setSelectedAddress(newSelectedAddress: Address) {
  selectedAddress.value = newSelectedAddress
  internalAddresses.value = internalAddresses.value.map(address => ({
    ...address,
    selected: address.id === newSelectedAddress.id
  }))

  emit('update:modelValue', internalAddresses.value)
  emit('deliveryAddressSelected', newSelectedAddress)
}

function saveFormAddress() {
  if (!isComplete.value) return
  if (!formAddress.value) return
  if (formAddress.value.id) {
    internalAddresses.value = internalAddresses.value.map(address => {
      if (address.id === formAddress.value?.id) {
        return formAddress.value as Address
      } else {
        return address
      }
    })
  } else {
    formAddress.value.id = uuid()
    formAddress.value.customerId = customerId.value
    internalAddresses.value.push(formAddress.value as Address)
  }
  setSelectedAddress(formAddress.value as Address)
  formAddress.value = undefined
}

function deleteAddress() {
  internalAddresses.value = internalAddresses.value.filter(
    address => address.id !== formAddress.value!.id
  )

  if (formAddress.value!.id === selectedAddress.value!.id) {
    setSelectedAddress(internalAddresses.value[0])
  }
  emit('update:modelValue', internalAddresses.value)
  formAddress.value = undefined
}

function setPlace(place: google.maps.places.PlaceResult) {
  if (!(place && place.address_components)) return
  let postalCodeAddressComponent = place.address_components.find(component =>
    component.types.includes('postal_code')
  )

  const postalCode = postalCodeAddressComponent
    ? postalCodeAddressComponent.long_name
    : undefined

  formAddress.value!['address'] = place.formatted_address
  formAddress.value!['postalCode'] = postalCode
}
</script>
