<template>
  <div
    v-if="svg"
    class="flex items-center justify-center flex-shrink-0"
    :class="{
      'current-color-fill': !originalColor,
      'w-4 h-4': props.size === 'small',
      'w-6 h-6': props.size === 'medium',
      'w-8 h-8': props.size === 'large'
    }"
    v-html="svg"
  />
</template>

<script lang="ts">
const modules = import.meta.glob('../../components/assets/svg/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  name: string
  size?: 'small' | 'medium' | 'large'
  originalColor?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  originalColor: false,
  size: 'small'
})

const svg = computed(() => {
  return modules['../../components/assets/svg/' + props.name + '.svg']
})
</script>

<style>
.current-color-fill path {
  fill: currentColor;
}
</style>
