<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <div class="flex flex-col h-full w-full">
        <top-bar
          class="flex-none"
          show-back
          :show-close="false"
          @back="$emit('close')"
        >
          <template #center>
            <div class="flex items-center text-xl font-heading font-bold">
              <div v-if="tab.code" class="flex items-center gap-1">
                {{ tab.code }}
                <span v-if="tab.tableName || tab.name">/</span>
                <span v-if="tab.tableName">{{ tab.tableName }}</span>
                <span v-else-if="tab.name">{{ tab.name }}</span>
              </div>
              <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
              <div v-else>{{ tab.name }}</div>
              <div v-if="tab.activationTime" class="ml-4 text-n-400">
                {{ $filters.time(tab.activationTime) }}
              </div>
            </div>
          </template>
        </top-bar>
        <div class="flex-1 overflow-y-auto scrolling-touch">
          <div class="p-4">
            <bills-list
              :bills="bills"
              :selected-bill-id="selectedBillId"
              :current-bill-id="currentBillId"
              @bill-selected="$emit('billSelected', $event)"
              @invalidate="$emit('invalidate', $event)"
              @generate-invoice="$emit('generateInvoice', $event)"
              @cancel-invoice="$emit('cancelInvoice', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import TopBar from '@/mobile/components/TopBar.vue'
import { useTabs } from '@/composables/useTabs'
import { Bill } from '@/types'
import BillsList from '@/components/checkout/BillsList.vue'

type Props = {
  tabId: string
  bills: Bill[]
  selectedBillId?: string
  currentBillId?: string
}

const props = withDefaults(defineProps<Props>(), {
  bills: () => [],
  selectedBillId: '',
  currentBillId: ''
})

const { tab } = useTabs(props.tabId)

defineEmits([
  'close',
  'billSelected',
  'invalidate',
  'generateInvoice',
  'cancelInvoice'
])
</script>
