<template>
  <l-modal
    v-if="isActive"
    size="small"
    :title="$t('manager-confirmation.title')"
    @close="deactivate(true)"
  >
    <div class="flex flex-col justify-center items-center h-full flex-1 mb-8">
      <div class="text-center text-n-0">
        {{ $t('manager-confirmation.subtitle') }}
      </div>
      <pin-pad :wrong-value="isInvalid" @complete="checkPIN" />
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { LModal } from '@last/core-ui/paprika'
import { useNotifications } from '@/composables/useNotifications'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { defineProps, defineEmits, ref, computed } from 'vue'
import PinPad from '@/components/core/PinPad.vue'
import { useI18n } from 'vue-i18n'

const authStore = useAuthStore()
const { askPinForPrivilege } = authStore
const { currentEmployee } = storeToRefs(authStore)
const { validateEmployeePinWithPrivilege } = authStore
const { notifySuccess, notifyError } = useNotifications()

const props = defineProps<{
  privilege: string
}>()

const emit = defineEmits(['confirmed', 'cancel'])

const { t } = useI18n()

const isActive = ref(true)
const isInvalid = ref(false)

const pinNeeded = computed(() => {
  return askPinForPrivilege(props.privilege)
})

if (!pinNeeded.value) {
  emit('confirmed')
  deactivate()
  isInvalid.value = false
}

function deactivate(cancel = false) {
  if (cancel) {
    emit('cancel')
  }
  isActive.value = false
}

function checkPIN(pin: string) {
  const status = validateEmployeePinWithPrivilege({
    pin,
    privilege: props.privilege
  })
  if (status.acces) {
    isInvalid.value = false
    if (!status.pin)
      notifySuccess({
        title:
          t('employees.employee-changed') + ' ' + currentEmployee.value!.name
      })
    emit('confirmed')
    deactivate()
  } else {
    isInvalid.value = true
    notifyError({
      title: status.pin ? t('employees.not-allowed') : t('employees.wrong-pin')
    })
  }
}
</script>
