<template>
  <div
    v-for="product in tabProducts"
    :key="product.uniqueId"
    class="flex w-full justify-between items-center"
  >
    <delivery-checkout-product
      :product="product"
      @products-modified="$emit('productsModified')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import DeliveryCheckoutProduct from './DeliveryCheckoutProduct.vue'
import { useTabs } from '@/composables/useTabs'

const props = defineProps<{
  tabId: string
}>()

defineEmits(['productsModified'])

const { sharedProducts } = useTabs(props.tabId)

const tabProducts = computed(() => {
  return sharedProducts.value.flatMap(product => {
    return Array(product.quantity)
      .fill('')
      .map((_, index) => {
        let discount2x1 =
          product.discountType === '2x1' &&
          index + 1 > product.quantity - product.discountAmount
        return {
          ...product,
          uniqueId: product.id + index,
          discount2x1,
          disabled:
            product.quantity - product.notBilledQuantity > index || discount2x1
        }
      })
  })
})
</script>
