import { registerPlugin } from '@capacitor/core'

const SerialPlugin = registerPlugin<any>('SerialPlugin')

export async function getSerial() {
  return new Promise((resolve, reject) => {
    SerialPlugin.addListener('serialUpdatedEvent', (data: any) => {
      try {
        const serial = data['serial']
        resolve(serial)
      } catch (error) {
        reject(error)
      }
    })
    SerialPlugin.updateSerial()
  })
}
