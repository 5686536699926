type Size = { width: number; height?: number }

const DEFAULT_SIZE: Size = { width: 150, height: 75 }

function getImageUrl(
  imageId: string,
  size: Size = DEFAULT_SIZE,
  isGrayscale = false
) {
  if (size) {
    return `https://res.cloudinary.com/lastpos/image/upload/c_thumb,g_center,w_${size.width}${size.height ? `,h_${size.height}` : ''},f_auto,q_auto${isGrayscale ? ',e_grayscale' : ''}/${imageId}`
  } else {
    return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/${imageId}`
  }
}

async function preloadLogo(imageId: string) {
  if (!imageId) return
  return new Promise((resolve, reject) => {
    const url = getImageUrl(imageId, { width: 300 }, true)
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = url
  })
}

function removeOldCache() {
  // Deprecated logo
  localStorage.removeItem('ticket-logo')
  // Deprecated image cache
  Object.keys(localStorage).forEach(key => {
    if (
      key.startsWith('image-cache-') ||
      key.startsWith('logo-image-') ||
      key.startsWith('brand-logo-')
    ) {
      const imageKey = localStorage.getItem(key)
      if (imageKey) {
        localStorage.removeItem(key)
        localStorage.removeItem(imageKey)
      }
    }
  })
}

export default {
  preloadLogo,
  getImageUrl,
  removeOldCache
}
