import { hasInjectionContext, inject, type Plugin } from 'vue'

export type UpdateVersionProps = {
  onConfirm: () => void
}

export type UpdateVersionPlugin = (props: UpdateVersionProps) => void

let showUpdateVersion: (props: UpdateVersionProps) => void

export function init(showFunction: (props: UpdateVersionProps) => void) {
  showUpdateVersion = showFunction
}

export function updateVersion(props: UpdateVersionProps): void {
  if (!showUpdateVersion) {
    // eslint-disable-next-line no-console
    console.warn('ManagerConfirmationWrapper not added in the app')
    return
  }
  return showUpdateVersion(props)
}

export function useUpdateVersion() {
  return hasInjectionContext()
    ? inject<UpdateVersionPlugin>('update', updateVersion)
    : updateVersion
}

export const UpdateModalPlugin: Plugin = {
  install(app) {
    app.config.globalProperties.$update = updateVersion
    app.provide('update', updateVersion)
  }
}
