import type { InputType } from './Inputs/inputTypes'
import type { Size } from './types'

export function usePlaceholderClasses(size: Size) {
  const styles = ['placeholder:text-n-200']
  if (size === 'small') {
    styles.push('placeholder:text-xs')
  } else if (size === 'medium') {
    styles.push('placeholder:text-sm')
  } else {
    styles.push('placeholder:text-base')
  }
  return styles
}

export function useSizeClasses(size: Size, type?: InputType) {
  if (type === 'textarea') {
    switch (size) {
      case 'small':
        return ['text-xs', 'h-16']
      case 'medium':
        return ['text-sm', 'h-24']
      case 'large':
        return ['text-base', 'h-28']
      default:
        return []
    }
  } else {
    switch (size) {
      case 'small':
        return ['text-xs', 'h-9']
      case 'medium':
        return ['text-sm', 'h-12']
      case 'large':
        return ['text-base', 'h-14']
      default:
        return []
    }
  }
}

export function usePaddingClasses(size: Size) {
  switch (size) {
    case 'small':
      return ['px-5', 'py-3']
    case 'medium':
      return ['px-5', 'py-4']
    case 'large':
      return ['px-5', 'py-5']
    default:
      return []
  }
}

export function useErrorClasses(error: boolean) {
  return error
    ? ['!border-r-500', 'hover:border-r-500', 'focus-within:border-r-500']
    : []
}

export function useFocusClasses() {
  return ['focus-within:border-n-100', 'dark:focus-within:border-n-400']
}

export function useDisabledClasses(disabled: boolean) {
  return disabled
    ? ['opacity-50', 'hover:border-transparent', 'pointer-events-none']
    : []
}

export function useTextColorsClasses() {
  return ['text-n-800', 'dark:text-n-0']
}

export function useBackgroundColorsClasses() {
  return ['bg-n-50', 'dark:bg-n-700']
}
