import i18n from '@/i18n'
import {
  InfoItem,
  Section,
  Label,
  Separator,
  EmptySeparator,
  Total,
  Tab
} from './components.js'

function courierInfo(report) {
  return [
    new Section(`${report.courierName}`),
    new InfoItem(i18n.global.t('couriers.from'), report.from, {
      valueType: 'date',
      style: 'normal 16px'
    }),
    new InfoItem(i18n.global.t('couriers.to'), new Date(), {
      valueType: 'date',
      style: 'normal 16px'
    })
  ]
}

function paymentMethods(report) {
  return [
    new Section(i18n.global.t('couriers.payment-methods')),
    ...Object.keys(report.payments).map(
      paymentMethod =>
        new InfoItem(paymentMethod, report.payments[paymentMethod], {
          valueType: 'currency',
          style: 'normal 16px'
        })
    )
  ]
}

function orders(report) {
  return [
    new Section(i18n.global.t('couriers.orders')),
    ...report.tabs
      .filter(tab => !tab.pending)
      .map(tab => new Tab(tab.code, tab.creationTime, tab.total)),
    new EmptySeparator(),
    new Label(i18n.global.t('couriers.pending-orders'), {
      style: 'bold 20px',
      lineHeight: 50
    }),
    ...report.tabs
      .filter(tab => tab.pending)
      .map(tab => new Tab(tab.code, tab.creationTime, tab.total))
  ]
}

function generateCourierReport(report) {
  return [
    new Label(i18n.global.t('couriers.title'), {
      style: 'bold 28px',
      lineHeight: 50
    }),
    ...courierInfo(report),
    ...paymentMethods(report),
    ...orders(report),
    new EmptySeparator(),
    new Separator(),
    new Total(report.total)
  ]
}

export default generateCourierReport
