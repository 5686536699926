<template>
  <external-delivery-tab
    v-if="!isOwnDelivery && !seeClientDetails"
    v-model:tab="newTab"
    :customer="customer"
    :delivery-companies="deliveryCompanies"
    @next-step="nextStep"
    @close="$emit('close')"
  />
  <tab-details
    v-else-if="!isClientTabs && !seeClientDetails"
    v-model:tab="newTab"
    v-model:phone-number="customer.phoneNumber"
    v-model:source="newTab.source"
    :title="$t('new-tab.new-tab-delivery')"
    :customer="customer"
    :delivery-companies="deliveryCompanies"
    @next-step="nextStep"
    @close="$emit('close')"
  />
  <client-details
    v-else
    :customer="customer"
    :delivery="delivery"
    :new-tab="newTab"
    :last-customer-tabs="lastCustomerTabs"
    :is-own-delivery="isOwnDelivery"
    @back="backStep"
    @tab-created="tabId => $emit('tabCreated', tabId)"
    @close="$emit('close')"
  />
</template>

<script setup lang="ts">
import { deliveryCompanies } from '@last/core/src/deliverySources.js'
import ClientDetails from '@/components/tabs/ClientDetails.vue'
import ExternalDeliveryTab from '@/components/tabs/ExternalDeliveryTab.vue'
import TabDetails from '@/components/tabs/TabDetails.vue'
import { ref } from 'vue'
import { computed } from 'vue'

defineEmits(['tabCreated', 'close'])

const newTab = ref({
  source: deliveryCompanies[0].value,
  pickupType: 'ownDelivery'
})
const customer = ref<any>({})
const lastCustomerTabs = ref<any>([])
const isClientTabs = ref(false)
const seeClientDetails = ref(false)

const isOwnDelivery = computed(() => {
  return newTab.value.source === 'OwnDelivery'
})

const delivery = computed(() => {
  return customer.value.addresses ? customer.value.addresses[0] : {}
})

function nextStep({ customer: inputCustomer, pickupType, tabs }: any) {
  seeClientDetails.value = true
  isClientTabs.value = true
  customer.value = { ...inputCustomer, ...customer.value }
  lastCustomerTabs.value = tabs
  if (pickupType) {
    newTab.value.pickupType = pickupType
  }
}

function backStep({ customer: inputCustomer, tab, delivery }: any) {
  seeClientDetails.value = false
  isClientTabs.value = false
  customer.value = { ...inputCustomer, ...delivery }
  newTab.value = tab
}
</script>
