<template>
  <div v-if="!!tab">
    <l-option-selector
      v-if="!app.isMobile"
      v-model="tab.orderingMode"
      :options="listOptions"
      class="w-48"
      @update:model-value="setListSelected"
    />
    <l-option-selector
      v-else
      v-model="tab.orderingMode"
      :options="mobileListOptions"
      class="w-32"
      @update:model-value="setListSelected"
    />
  </div>
</template>
<script lang="ts" setup>
import { LOptionSelector } from '@last/core-ui/paprika'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTabsStore } from '@/store/tabs'
import { useTabs } from '@/composables/useTabs'
import type { OptionValue } from '@last/core-ui/paprika/components/LOptionSelector.vue'
import app from '@/app'

type Props = {
  tabId: string
}

const props = defineProps<Props>()

const { t } = useI18n()

const tabsStore = useTabsStore()

const { updateTabOrdering } = tabsStore
const { tab } = useTabs(props.tabId)

const listOptions = ref([
  { label: t('tabs.seats'), value: 'seats' },
  { label: t('tabs.courses'), value: 'courses' }
])

const mobileListOptions = ref([
  { icon: 'chair', value: 'seats' },
  { icon: 'line-menu', value: 'courses' }
])

function setListSelected(option: OptionValue) {
  updateTabOrdering({ tabId: props.tabId, orderingMode: option })
}
</script>
