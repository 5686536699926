import Cashdro from '@/integrations/cashmachine/cashdro.js'
import Cashlogy from '@/integrations/cashmachine/cashlogy.js'
import CashGuard from '@/integrations/cashmachine/cashguard.js'
import CashKeeper from '@/integrations/cashmachine/cashkeeper.js'
import Glory from '@/integrations/cashmachine/glory.js'
import DevCashMachine from '@/integrations/cashmachine/devcashmachine.js'
import { cashMachineStatus } from '@/plugins/cashMachineStatus'
import { Capacitor } from '@capacitor/core'
import { ref } from 'vue'

class CashMachine {
  constructor() {
    this.methods = ['cashdro', 'cashlogy', 'cashguard', 'glory', 'cashkeeper']
    this.inputAmount = ref(0)
    this.totalAmount = ref(0)
  }

  async init(config, preferredConfigId) {
    const platform = Capacitor.getPlatform()

    let inputAmountListener = this._inputAmountListener.bind(this)
    let totalAmountListener = this._totalAmountListener.bind(this)
    let cashMachines = {
      Cashdro,
      Cashlogy,
      CashGuard,
      Glory,
      CashKeeper
    }
    let isDev = false
    if (!import.meta.env.PROD || platform === 'web') {
      this.hasStatus = true
      isDev = true
      DevCashMachine.init(inputAmountListener, totalAmountListener)
      this.machine = DevCashMachine
    }
    for (let method of config.paymentMethodsConfig) {
      if (!method.configs) continue
      for (let methodConfig of method.configs) {
        if (
          methodConfig.id === preferredConfigId ||
          (!preferredConfigId && (methodConfig.ip || methodConfig.port))
        ) {
          //config preferredCashMachine or first cashmachine
          if (!isDev) {
            await cashMachines[method.name].init({
              ip: methodConfig.ip,
              port: methodConfig.port,
              name: methodConfig.name,
              password: methodConfig.password,
              username: methodConfig.username,
              type: methodConfig.type,
              inputAmountListener,
              totalAmountListener
            })
            this.machine = cashMachines[method.name]
            this.hasStatus = true
          }
          this.method = method.name.toLowerCase()
          return
        }
      }
    }
  }

  _inputAmountListener(amount) {
    this.inputAmount.value = amount
  }

  _totalAmountListener(amount) {
    this.totalAmount.value = amount
  }

  _onCancel() {
    return this.machine.cancel()
  }

  async charge(amount) {
    let component = null
    if (this.hasStatus) {
      component = cashMachineStatus({
        inputAmount: this.inputAmount,
        amount,
        onCancel: this._onCancel.bind(this)
      })
    }
    let res = await this.machine.charge(amount)
    component?.close()
    return res
  }

  async payIn(amount) {
    let component = null
    if (this.hasStatus) {
      component = cashMachineStatus({
        inputAmount: this.inputAmount,
        amount,
        onCancel: this._onCancel.bind(this)
      })
    }
    let res = await this.machine.payIn(amount)
    component?.close()
    return res
  }

  async payOut(amount) {
    return this.machine.payOut(amount)
  }

  async close() {
    if (!this.machine.close) return
    return this.machine.close()
  }

  async refreshTotalAmount() {
    if (!this.machine.refreshTotalAmount) return 0
    return this.machine.refreshTotalAmount()
  }

  isEnabled() {
    return !!this.machine
  }

  async openBackoffice() {
    if (!this.machine.openBackoffice) return
    await this.machine.openBackoffice()
  }
}

export default new CashMachine()
