import type { DirectiveBinding, ObjectDirective } from 'vue'

interface HTMLElementWithClickOutsideEvent extends HTMLElement {
  // eslint-disable-next-line no-unused-vars
  clickOutsideEvent?: (event: Event) => void
}

const clickOutside: ObjectDirective<HTMLElementWithClickOutsideEvent> = {
  beforeMount: (el, binding: DirectiveBinding) => {
    el.clickOutsideEvent = (event: Event) => {
      const isClickInside =
        el == event.target || el.contains(event.target as Node)

      const tippyPoppers = document.querySelectorAll('[data-tippy-root]')
      const isClickInsideTippy = Array.from(tippyPoppers).some(popper =>
        popper.contains(event.target as Node)
      )

      if (!isClickInside && !isClickInsideTippy) {
        binding.value()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: el => {
    document.body.removeEventListener('click', el.clickOutsideEvent!)
  }
}

export default clickOutside
